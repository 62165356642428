import { nanoid } from "nanoid";
import { Vector2D } from "../math/vector2D";
import type {
  AABB,
  BodyState,
  BodyType,
  Circle,
  CollisionInfo,
  CollisionTrigger,
  FullBodyState,
  LightSource,
  Material,
  PerimeterPoint,
  Segment,
  SerializedBody,
  ShouldResolveCollisionCheck,
} from "../models";
import { Shape } from "../shapes/shape";
import type { Composite } from "./composite";
import { BodyOffset } from "./plane";
import { Transform } from "../math/transform";
import { SurfacePoint } from "./surfacePoint";
import { createTextureInfo } from "../rendering/texture";
import { Color } from "../rendering/color";

export type BodyRenderData = {
  area: number;
  path: Path2D;
  perimeterPoints: Vector2D[];
  color: string;
};

export class Body {
  id: string;
  index: number = -1;
  strideIndex: number = -1;
  stateArray: Float32Array | null = null;

  type: BodyType;

  inSubplane: boolean;

  collisions: CollisionInfo[] = [];
  positionCorrections: Vector2D[] = [];
  angleCorrections: number[] = [];

  shape: Shape;
  material: Material;
  positionLocked: boolean;
  rotationLocked: boolean;

  deferRotationLock: boolean = false;

  mass: number;
  momentOfInertia: number;

  composite: Composite | null = null;
  shouldResolveCollisionChecks: Map<
    string,
    ShouldResolveCollisionCheck
  > | null = null;
  collisionTriggers: Map<string, CollisionTrigger> | null = null;

  pointsOnSurface: Set<SurfacePoint> | null = null;

  light: LightSource | null = null;

  interiorShadowConfig = {
    baseRadius: 500, // Base radius of each gradient circle
    stops: [
      { position: 0, opacity: 1 }, // Center of gradient
      { position: 0.5, opacity: 0.3 }, // Middle of gradient
      { position: 1, opacity: 0 }, // Edge of gradient
    ],
  };
  subplaneInteriorShadowConfig = {
    baseRadius: 1000,
    stops: [
      { position: 0, opacity: 1 },
      { position: 0.25, opacity: 0.5 },
      { position: 0.5, opacity: 0.2 },
      // { position: 0.75, opacity: 0.1 },
      { position: 1, opacity: 0 },
    ],
  };
  shadowPointDistance: number = 200;

  textureInfo: {
    texture: ImageBitmap;
    textureScale: number;
    originalBounds: AABB;
  } | null = null;

  constructor({
    id,
    shape,
    material,
    type,
    positionLocked,
    rotationLocked,
    inSubplane,
    light,
  }: {
    id: string;
    shape: Shape;
    material: Material;
    type: BodyType;
    positionLocked: boolean;
    rotationLocked: boolean;
    inSubplane: boolean;
    light?: LightSource;
  }) {
    this.id = id;
    this.shape = shape;
    this.material = material;
    this.type = type;
    this.positionLocked = positionLocked;
    this.rotationLocked = rotationLocked;
    this.inSubplane = inSubplane;
    this.mass = shape.area * material.density;
    this.momentOfInertia = shape.secondMomentOfArea * material.density;
    this.light = light ?? null;
  }

  isLoaded(): boolean {
    return this.stateArray !== null;
  }

  getTransform(): Transform {
    if (!this.stateArray) {
      return new Transform(0, 0, 0, 0, 0);
    }

    return new Transform(
      this.stateArray[this.strideIndex + BodyOffset.TransformOffsetX],
      this.stateArray[this.strideIndex + BodyOffset.TransformOffsetY],
      this.stateArray[this.strideIndex + BodyOffset.Rotation],
      this.stateArray[this.strideIndex + BodyOffset.SinRotation],
      this.stateArray[this.strideIndex + BodyOffset.CosRotation]
    );
  }

  getPosition(): [number, number, number] {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.strideIndex + BodyOffset.PositionX],
      this.stateArray[this.strideIndex + BodyOffset.PositionY],
      this.stateArray[this.strideIndex + BodyOffset.Rotation],
    ];
  }

  getVelocity(): [number, number, number] {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.strideIndex + BodyOffset.VelocityX],
      this.stateArray[this.strideIndex + BodyOffset.VelocityY],
      this.stateArray[this.strideIndex + BodyOffset.AngularVelocity],
    ];
  }

  getVelocityVector(): Vector2D {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return new Vector2D(0, 0);
    }
    return new Vector2D(
      this.stateArray[this.strideIndex + BodyOffset.VelocityX],
      this.stateArray[this.strideIndex + BodyOffset.VelocityY]
    );
  }

  getAcceleration(): [number, number, number] {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.strideIndex + BodyOffset.AccelerationX],
      this.stateArray[this.strideIndex + BodyOffset.AccelerationY],
      this.stateArray[this.strideIndex + BodyOffset.AngularAcceleration],
    ];
  }

  getMec(): Circle {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return {
        x: 0,
        y: 0,
        radius: 0,
      };
    }
    return {
      x: this.stateArray[this.strideIndex + BodyOffset.MecPositionX],
      y: this.stateArray[this.strideIndex + BodyOffset.MecPositionY],
      radius: this.stateArray[this.strideIndex + BodyOffset.MecRadius],
    };
  }

  getAABB(): AABB {
    return this.shape.getSegmentTree().bounds;
  }

  getMass(considerComposite: boolean = false): number {
    if (considerComposite && this.composite) {
      return this.composite.positionLocked ? Infinity : this.composite.mass;
    }
    return this.mass;
  }

  getMomentOfInertia(considerComposite: boolean = false): number {
    if (considerComposite && this.composite) {
      return this.composite.momentOfInertia;
    }
    return this.momentOfInertia;
  }

  getFullState(considerComposite: boolean = false): FullBodyState {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return {
        x: 0,
        y: 0,
        rotation: 0,
        sinRotation: 0,
        cosRotation: 1,
        velocityX: 0,
        velocityY: 0,
        angularVelocity: 0,
      };
    }

    if (considerComposite && this.composite) {
      return this.composite.getFullState(this);
    }

    return {
      x: this.stateArray[this.strideIndex + BodyOffset.PositionX],
      y: this.stateArray[this.strideIndex + BodyOffset.PositionY],
      rotation: this.stateArray[this.strideIndex + BodyOffset.Rotation],
      sinRotation: this.stateArray[this.strideIndex + BodyOffset.SinRotation],
      cosRotation: this.stateArray[this.strideIndex + BodyOffset.CosRotation],
      velocityX: this.stateArray[this.strideIndex + BodyOffset.VelocityX],
      velocityY: this.stateArray[this.strideIndex + BodyOffset.VelocityY],
      angularVelocity:
        this.stateArray[this.strideIndex + BodyOffset.AngularVelocity],
    };
  }

  getCenter(considerComposite: boolean = false): Vector2D {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return new Vector2D(0, 0);
    }

    if (considerComposite && this.composite) {
      const pos = this.composite.centroidPosition;
      return new Vector2D(pos[0], pos[1]);
    }
    return new Vector2D(
      this.stateArray[this.strideIndex + BodyOffset.PositionX],
      this.stateArray[this.strideIndex + BodyOffset.PositionY]
    );
  }

  getRotation(): {
    angle: number;
    sin: number;
    cos: number;
  } {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return { angle: 0, sin: 0, cos: 1 };
    }
    return {
      angle: this.stateArray[this.strideIndex + BodyOffset.Rotation],
      sin: this.stateArray[this.strideIndex + BodyOffset.SinRotation],
      cos: this.stateArray[this.strideIndex + BodyOffset.CosRotation],
    };
  }

  getInverseInertia(): number {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return 0;
    }
    return 1 / this.momentOfInertia;
  }

  getClosestPerimeterPoint(
    queryCenter: Vector2D,
    queryRadius: number
  ): {
    distance: number;
    globalPosition: Vector2D;
    perimeterPoint: PerimeterPoint;
  } | null {
    const localQueryCenter = this.getTransform().applyInverse(queryCenter);
    const closest = this.shape.getClosestPerimeterPoint(
      localQueryCenter,
      queryRadius
    );

    if (!closest) {
      return null;
    }

    const globalPosition = this.getTransform().apply(
      closest.perimeterPoint.position
    );

    return { ...closest, globalPosition };
  }

  setPosition(x: number, y: number, rotation: number): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }
    this.stateArray[this.strideIndex + BodyOffset.PositionX] = x;
    this.stateArray[this.strideIndex + BodyOffset.PositionY] = y;
    this.stateArray[this.strideIndex + BodyOffset.Rotation] = rotation;

    const cos = Math.cos(rotation);
    const sin = Math.sin(rotation);

    this.stateArray[this.strideIndex + BodyOffset.CosRotation] = cos;
    this.stateArray[this.strideIndex + BodyOffset.SinRotation] = sin;

    const localCentroidX =
      this.stateArray[this.strideIndex + BodyOffset.LocalCentroidX];
    const localCentroidY =
      this.stateArray[this.strideIndex + BodyOffset.LocalCentroidY];

    const localMecX =
      this.stateArray[this.strideIndex + BodyOffset.LocalMecPositionX];
    const localMecY =
      this.stateArray[this.strideIndex + BodyOffset.LocalMecPositionY];

    const transformOffsetX = -localCentroidX * cos - -localCentroidY * sin + x;
    const transformOffsetY = -localCentroidX * sin + -localCentroidY * cos + y;

    this.stateArray[this.strideIndex + BodyOffset.TransformOffsetX] =
      transformOffsetX;
    this.stateArray[this.strideIndex + BodyOffset.TransformOffsetY] =
      transformOffsetY;

    this.stateArray[this.strideIndex + BodyOffset.MecPositionX] =
      localMecX * cos - localMecY * sin + transformOffsetX;
    this.stateArray[this.strideIndex + BodyOffset.MecPositionY] =
      localMecX * sin + localMecY * cos + transformOffsetY;
  }

  setVelocity(x: number, y: number, angularVelocity: number): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }
    if (!this.positionLocked) {
      this.stateArray[this.strideIndex + BodyOffset.VelocityX] = x;
      this.stateArray[this.strideIndex + BodyOffset.VelocityY] = y;
    }
    if (!this.rotationLocked) {
      this.stateArray[this.strideIndex + BodyOffset.AngularVelocity] =
        angularVelocity;
    }
  }

  applyImpulse(impulse: Vector2D, r?: Vector2D): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!impulse.isValid()) {
      console.error(`impulse is invalid: ${impulse.x}, ${impulse.y}`);
      return;
    }

    if (this.composite) {
      const composite = this.composite;
      // Apply linear impulse
      if (!composite.positionLocked) {
        composite.velocity[0] += impulse.x / composite.mass;
        composite.velocity[1] += impulse.y / composite.mass;
      }
      // Apply angular impulse if r is provided
      if (r && !composite.rotationLocked) {
        const rCrossImpulse = r.x * impulse.y - r.y * impulse.x;
        composite.angularVelocity += rCrossImpulse / composite.momentOfInertia;
      }
      return;
    }

    // Apply linear impulse
    if (!this.positionLocked) {
      this.stateArray[this.strideIndex + BodyOffset.VelocityX] +=
        impulse.x / this.mass;
      this.stateArray[this.strideIndex + BodyOffset.VelocityY] +=
        impulse.y / this.mass;
    }

    // Apply angular impulse if r is provided
    if (r && !this.rotationLocked) {
      if (r.length() <= this.shape.mec.radius * 10) {
        const rCrossImpulse = r.x * impulse.y - r.y * impulse.x;
        this.stateArray[this.strideIndex + BodyOffset.AngularVelocity] +=
          rCrossImpulse / this.momentOfInertia;
      }
    }
  }

  applyLinearImpulse(impulse: Vector2D): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!impulse.isValid()) {
      console.error(`impulse is invalid: ${impulse.x}, ${impulse.y}`);
      return;
    }

    if (this.composite) {
      // Skip if position locked or mass is invalid
      if (!this.composite.positionLocked) {
        this.composite.velocity[0] += impulse.x / this.composite.mass;
        this.composite.velocity[1] += impulse.y / this.composite.mass;
      }
      return;
    }

    // Skip if position locked or mass is invalid
    if (!this.positionLocked) {
      this.stateArray[this.strideIndex + BodyOffset.VelocityX] +=
        impulse.x / this.mass;
      this.stateArray[this.strideIndex + BodyOffset.VelocityY] +=
        impulse.y / this.mass;
    }
  }

  applyAngularImpulse(r: Vector2D, impulse: Vector2D): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    let angularImpulse: number;

    if (!impulse.isValid()) {
      console.error(
        `applyAngularImpulse: impulse is invalid: ${impulse.x}, ${impulse.y}`
      );
      return;
    }
    if (r.length() > this.shape.mec.radius * 10) {
      return;
    }
    angularImpulse = r.x * impulse.y - r.y * impulse.x;

    if (this.composite) {
      if (!this.composite.rotationLocked) {
        this.composite.angularVelocity +=
          angularImpulse / this.composite.momentOfInertia;
      }
      return;
    }

    if (!this.rotationLocked) {
      this.stateArray[this.strideIndex + BodyOffset.AngularVelocity] +=
        angularImpulse / this.momentOfInertia;
    }
  }

  addPositionCorrection(correction: Vector2D): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!correction.isValid()) {
      console.error(`correction is invalid: ${correction.x}, ${correction.y}`);
      return;
    }

    if (this.composite) {
      this.composite.addPositionCorrection(correction);
      return;
    }

    this.positionCorrections.push(correction);
  }

  applyPositionCorrections(): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (this.positionLocked) {
      return;
    }

    if (this.composite) {
      this.composite.applyPositionCorrections();
      return;
    }

    // Calculate average correction
    if (this.positionCorrections.length > 0) {
      const avgCorrection = this.positionCorrections
        .reduce((sum, correction) => sum.add(correction), new Vector2D(0, 0))
        .scale(1 / this.positionCorrections.length);

      const scaledCorrection = avgCorrection.scale(1 / this.mass);

      // Apply the average correction
      this.stateArray[this.strideIndex + BodyOffset.PositionX] +=
        scaledCorrection.x;
      this.stateArray[this.strideIndex + BodyOffset.PositionY] +=
        scaledCorrection.y;

      // Update transform offset
      this.stateArray[this.strideIndex + BodyOffset.TransformOffsetX] +=
        scaledCorrection.x;
      this.stateArray[this.strideIndex + BodyOffset.TransformOffsetY] +=
        scaledCorrection.y;

      // Update mec position
      this.stateArray[this.strideIndex + BodyOffset.MecPositionX] +=
        scaledCorrection.x;
      this.stateArray[this.strideIndex + BodyOffset.MecPositionY] +=
        scaledCorrection.y;
    }

    this.positionCorrections = [];
  }

  applyAngleCorrections(): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (this.composite) {
      this.composite.applyAngleCorrections();
      return;
    }

    if (this.angleCorrections.length > 0) {
      const avgCorrection =
        this.angleCorrections.reduce((sum, correction) => sum + correction, 0) /
        this.angleCorrections.length;

      this.stateArray[this.strideIndex + BodyOffset.Rotation] += avgCorrection;
    }

    this.angleCorrections = [];
  }

  applyForce(force: Vector2D, globalPosition?: Vector2D): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!force.isValid()) {
      console.error(`force is invalid: ${force.x}, ${force.y}`);
      return;
    }

    if (this.composite) {
      // Skip if position locked or mass is infinite/zero
      if (!this.composite.positionLocked) {
        const linearAcc = force.scale(1 / this.composite.mass);
        this.composite.acceleration[0] += linearAcc.x;
        this.composite.acceleration[1] += linearAcc.y;
      }

      // Skip if rotation locked or moment of inertia is invalid
      if (!this.composite.rotationLocked) {
        const forcePosition =
          globalPosition ??
          new Vector2D(
            this.stateArray[this.strideIndex + BodyOffset.PositionX],
            this.stateArray[this.strideIndex + BodyOffset.PositionY]
          );

        const centroidPos = new Vector2D(
          this.composite.centroidPosition[0],
          this.composite.centroidPosition[1]
        );
        const r = forcePosition.subtract(centroidPos);
        const torque = r.x * force.y - r.y * force.x;
        this.composite.angularAcceleration +=
          torque / this.composite.momentOfInertia;
      }
      return;
    }

    // Skip if position locked or mass is invalid
    if (!this.positionLocked) {
      const linearAcc = force.scale(1 / this.mass);
      this.stateArray[this.strideIndex + BodyOffset.AccelerationX] +=
        linearAcc.x;
      this.stateArray[this.strideIndex + BodyOffset.AccelerationY] +=
        linearAcc.y;
    }

    // Skip if rotation locked or inertia is invalid
    if (globalPosition && !this.rotationLocked) {
      const center = this.getCenter();
      const r = globalPosition.subtract(center);
      const torque = r.x * force.y - r.y * force.x;
      this.stateArray[this.strideIndex + BodyOffset.AngularAcceleration] +=
        torque / this.momentOfInertia;
    }
  }

  applyTorque(torque: number): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!isFinite(torque)) {
      console.error(`applyTorque: torque is invalid: ${torque}`);
      return;
    }

    if (this.composite) {
      // Skip if rotation locked or moment of inertia is invalid
      if (!this.composite.rotationLocked) {
        this.composite.angularAcceleration +=
          torque / this.composite.momentOfInertia;
      }
      return;
    }

    // Skip if rotation locked or inertia is invalid
    if (!this.rotationLocked) {
      this.stateArray[this.strideIndex + BodyOffset.AngularAcceleration] +=
        torque / this.momentOfInertia;
    }
  }

  getLinearVelocity(considerComposite: boolean = false): Vector2D {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return new Vector2D(0, 0);
    }

    if (considerComposite && this.composite) {
      const velocity = this.composite.velocity;
      return new Vector2D(velocity[0], velocity[1]);
    }
    return new Vector2D(
      this.stateArray[this.strideIndex + BodyOffset.VelocityX],
      this.stateArray[this.strideIndex + BodyOffset.VelocityY]
    );
  }

  getAngularVelocity(considerComposite: boolean = false): number {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return 0;
    }

    if (considerComposite && this.composite) {
      return this.composite.angularVelocity;
    }

    return this.stateArray[this.strideIndex + BodyOffset.AngularVelocity];
  }

  getPointVelocity(r: Vector2D, considerComposite: boolean = false): Vector2D {
    const linearVel = this.getLinearVelocity(considerComposite);
    const angularVel = this.getAngularVelocity(considerComposite);

    const pointVel = linearVel.add(
      new Vector2D(-r.y * angularVel, r.x * angularVel)
    );

    return pointVel;
  }

  shouldResolveCollision(collision: CollisionInfo): boolean {
    if (!this.shouldResolveCollisionChecks) {
      return true;
    }

    let shouldResolve = true;

    for (const shouldResolveCheck of this.shouldResolveCollisionChecks.values()) {
      if (!shouldResolveCheck(collision)) {
        shouldResolve = false;
      }
    }
    return shouldResolve;
  }

  addShouldResolveCollisionCheck(check: ShouldResolveCollisionCheck): string {
    if (!this.shouldResolveCollisionChecks) {
      this.shouldResolveCollisionChecks = new Map();
    }

    const id = nanoid();
    this.shouldResolveCollisionChecks.set(id, check);
    return id;
  }

  removeShouldResolveCollisionCheck(id: string) {
    if (!this.shouldResolveCollisionChecks) {
      return;
    }

    this.shouldResolveCollisionChecks.delete(id);
  }

  callCollisionTriggers = () => {
    if (!this.collisionTriggers) {
      return;
    }

    for (const collision of this.collisions) {
      const otherBody =
        collision.body1 === this ? collision.body2 : collision.body1;

      for (const trigger of this.collisionTriggers.values()) {
        trigger(otherBody, collision);
      }
    }

    this.collisions = [];
  };

  addCollision(collision: CollisionInfo): boolean {
    if (!this.collisionTriggers || this.collisionTriggers.size === 0) {
      return false;
    }

    this.collisions.push(collision);
    return true;
  }

  addCollisionTrigger(trigger: CollisionTrigger): string {
    if (!this.collisionTriggers) {
      this.collisionTriggers = new Map();
    }

    const id = nanoid();
    this.collisionTriggers.set(id, trigger);
    return id;
  }

  removeCollisionTrigger(id: string) {
    if (!this.collisionTriggers) {
      return;
    }

    this.collisionTriggers.delete(id);
  }

  storeSurfacePoint({
    surfacePoint,
    segment,
    tOrAngle,
  }: {
    surfacePoint: SurfacePoint;
    segment: Segment;
    tOrAngle: number;
  }): void {
    this.shape.storePoint({
      surfacePoint,
      segment,
      tOrAngle,
    });

    if (!this.pointsOnSurface) {
      this.pointsOnSurface = new Set();
    }

    this.pointsOnSurface.add(surfacePoint);
  }

  addSurfacePoint(surfacePoint: SurfacePoint): void {
    if (!this.pointsOnSurface) {
      this.pointsOnSurface = new Set();
    }

    this.pointsOnSurface.add(surfacePoint);
  }

  // Helper function to attempt a single cut
  private attemptCut(
    cutter: Shape,
    cutterPosition: Vector2D,
    offset: Vector2D,
    transform: Transform
  ):
    | {
        shape: Shape;
        surfacePoints: SurfacePoint[];
      }[]
    | null {
    const offsetPosition = cutterPosition.add(offset);
    const cutterTransform = new Transform(
      offsetPosition.x,
      offsetPosition.y,
      0,
      0,
      1
    );

    const transformInfo = Transform.getRelativeTransformInfo({
      guest: cutterTransform,
      host: transform,
    });

    return this.shape.subtract(cutter, transformInfo);
  }

  cut(
    cutter: Shape,
    cutterPosition: Vector2D
  ):
    | {
        state: BodyState;
        body: Body;
        surfacePoints: SurfacePoint[];
      }[]
    | null {
    const MAX_ATTEMPTS = 10;
    const INITIAL_STEP_SIZE = 0.1; // Start with small perturbations

    // Try cutting with increasingly large perturbations
    const offsetGenerator = spiralOffsets(INITIAL_STEP_SIZE);

    let subtractionResult:
      | {
          shape: Shape;
          surfacePoints: SurfacePoint[];
        }[]
      | null = null;
    let attempts = 0;

    const transform = this.getTransform();

    // First try without offset
    subtractionResult = this.attemptCut(
      cutter,
      cutterPosition,
      new Vector2D(0, 0),
      transform
    );

    // If initial attempt fails, try with offsets
    while (subtractionResult === null && attempts < MAX_ATTEMPTS) {
      const offset = offsetGenerator.next().value;
      subtractionResult = this.attemptCut(
        cutter,
        cutterPosition,
        offset,
        transform
      );

      attempts++;
    }

    if (subtractionResult === null) {
      console.warn(`Cut failed after ${attempts} attempts`);
      return null;
    }

    if (subtractionResult.length === 0) {
      return null;
    }

    // Get the original body's velocities and transform
    const targetVelocity = this.getLinearVelocity(true);
    const targetAngularVel = this.getAngularVelocity(true);
    const originalCenter = this.getCenter(true);

    const bodies: {
      state: BodyState;
      body: Body;
      surfacePoints: SurfacePoint[];
    }[] = [];

    for (const shape of subtractionResult) {
      if (shape.shape.area < 200) {
        continue;
      }

      // Create the body first so we have access to its shape properties
      const body = new Body({
        id: nanoid(),
        shape: shape.shape,
        material: this.material,
        type: this.type,
        positionLocked: this.positionLocked,
        rotationLocked: this.rotationLocked,
        inSubplane: this.inSubplane,
      });

      // The new shape's centroid is in the original shape's local space
      // Transform it to world space
      const globalCentroid = transform.apply(shape.shape.centroid);

      // Calculate the vector from original center to new centroid
      const r = globalCentroid.subtract(originalCenter);

      // Calculate rotational velocity component (v = ω × r)
      const rotationalVel = new Vector2D(
        -targetAngularVel * r.y,
        targetAngularVel * r.x
      );

      bodies.push({
        body,
        state: {
          position: [globalCentroid.x, globalCentroid.y, transform.angle],
          velocity: [
            targetVelocity.x + rotationalVel.x,
            targetVelocity.y + rotationalVel.y,
            targetAngularVel,
          ],
        },
        surfacePoints: shape.surfacePoints,
      });
    }

    return bodies;
  }

  removeFromComposite(): Composite | null {
    const composite = this.composite;
    if (composite) {
      composite.removeBody(this);
      return composite;
    }

    return null;
  }

  static fromSerialized(serialized: SerializedBody): Body {
    return new Body({
      id: serialized.id,
      shape: Shape.fromSerialized(serialized.shape),
      material: serialized.material,
      type: serialized.type,
      positionLocked: serialized.positionLocked,
      rotationLocked: serialized.rotationLocked,
      inSubplane: serialized.inSubplane,
    });
  }

  serialize(): SerializedBody {
    return {
      id: this.id,
      shape: this.shape.serialize(),
      material: this.material,
      type: this.type,
      positionLocked: this.positionLocked,
      rotationLocked: this.rotationLocked,
      inSubplane: this.inSubplane,
    };
  }

  getTextureInfo(): {
    texture: ImageBitmap;
    textureScale: number;
    originalBounds: AABB;
  } {
    if (!this.textureInfo) {
      this.textureInfo = createTextureInfo({
        aabb: this.getAABB(),
        color: this.material.color,
        inSubplane: this.inSubplane,
      });
    }

    return this.textureInfo!;
  }

  addAngleCorrection(correction: number): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!isFinite(correction)) {
      console.error(`addAngleCorrection: correction is invalid: ${correction}`);
      return;
    }

    if (this.composite) {
      if (!this.composite.rotationLocked) {
        this.composite.addAngleCorrection(correction);
      }
      return;
    }

    if (!this.rotationLocked) {
      this.angleCorrections.push(correction);
    }
  }
}

// Helper function to generate points in a spiral pattern
function* spiralOffsets(stepSize: number): Generator<Vector2D> {
  let angle = 0;
  let radius = stepSize;
  while (true) {
    // Convert polar coordinates to Cartesian
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    yield new Vector2D(x, y);

    // Increment angle by golden ratio for better distribution
    angle += 2.4; // Approximately golden angle in radians
    radius += stepSize / 6; // Gradually increase radius
  }
}
