import type { IntersectionShapeBuilder } from "../../geometry/buildIntersectionShapes";
import type { WelzlPoint } from "../../geometry/welzl";
import { Vector2D } from "../../math/vector2D";
import {
  type RelativeTransformInfo,
  type SegmentContributions,
  type Segment,
  type OpenSegment,
  SegmentType,
  type IntersectionPoint,
} from "../../models";
import type { SurfacePoint } from "../../plane/surfacePoint";
import { Shape } from "../shape";

export class CutShapeBuilder implements IntersectionShapeBuilder {
  targetShape: Shape;
  transformInfo: RelativeTransformInfo;
  resultShapes: { shape: Shape; surfacePoints: SurfacePoint[] }[] = [];
  shoelaceAreaSum = 0;
  shoelaceCentroidSum = new Vector2D(0, 0);
  shoelaceSecondMomentOfAreaSum = 0;
  compositeAreaSum = 0;
  compositeCentroidSum = new Vector2D(0, 0);
  compositeSecondMomentOfAreaSum = 0;
  contributions: SegmentContributions[] = [];
  forwardsSegments: Segment[] = [];
  backwardsSegments: Segment[] = [];
  lastPoint: Vector2D | null = null;
  firstPoint: Vector2D | null = null;
  mecPoints: WelzlPoint[] = [];
  surfacePoints: SurfacePoint[] = [];

  constructor(transformInfo: RelativeTransformInfo, targetShape: Shape) {
    this.transformInfo = transformInfo;
    this.targetShape = targetShape;
  }

  updateSums(contributions: SegmentContributions): void {
    const {
      shoelaceFactor,
      shoelaceCentroid,
      shoelaceSecondMomentOfArea,
      area,
      centroid,
      compositeSecondMomentOfArea,
    } = contributions;

    // Handle polygonal (shoelace) contributions
    if (shoelaceFactor && shoelaceCentroid && shoelaceSecondMomentOfArea) {
      this.shoelaceAreaSum += shoelaceFactor;
      this.shoelaceCentroidSum = this.shoelaceCentroidSum.add(shoelaceCentroid);
      this.shoelaceSecondMomentOfAreaSum += shoelaceSecondMomentOfArea;
    }

    // Handle composite (circle) contributions
    if (area && centroid) {
      this.compositeAreaSum += area;
      this.compositeCentroidSum = this.compositeCentroidSum.add(
        centroid.multiply(area)
      );
      if (compositeSecondMomentOfArea) {
        this.compositeSecondMomentOfAreaSum += compositeSecondMomentOfArea;
      }
    }

    this.contributions.push(contributions);
  }

  getProperties(): {
    centroid: Vector2D;
    area: number;
    secondMomentOfArea: number;
  } {
    let secondMomentOfArea = 0;

    if (Math.abs(this.shoelaceAreaSum) > 0.0001) {
      const shoelaceArea = this.shoelaceAreaSum / 2;
      const shoelaceCentroid = this.shoelaceCentroidSum.divide(
        6 * shoelaceArea
      );

      // Add polygonal contribution to composite sums
      this.compositeAreaSum += shoelaceArea;
      this.compositeCentroidSum = this.compositeCentroidSum.add(
        shoelaceCentroid.multiply(shoelaceArea)
      );

      // Calculate polygonal second moment of area
      const polygonalIzz = this.shoelaceSecondMomentOfAreaSum / 12;
      const polygonalParallelAxisCorrection =
        shoelaceArea *
        (shoelaceCentroid.x * shoelaceCentroid.x +
          shoelaceCentroid.y * shoelaceCentroid.y);
      secondMomentOfArea = polygonalIzz - polygonalParallelAxisCorrection;
    }

    const finalCentroid = this.compositeCentroidSum.divide(
      this.compositeAreaSum
    );

    for (const contribution of this.contributions) {
      const { area, centroid, compositeSecondMomentOfArea } = contribution;

      if (area && centroid && compositeSecondMomentOfArea) {
        const r = centroid.subtract(finalCentroid);
        const distanceSquared = r.lengthSquared();
        secondMomentOfArea +=
          compositeSecondMomentOfArea + area * distanceSquared;
      }
    }

    return {
      centroid: finalCentroid,
      area: this.compositeAreaSum,
      secondMomentOfArea,
    };
  }

  addPartialSegment(
    segment: Segment,
    toEnd: boolean,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    fromHost: boolean
  ) {
    let seg: OpenSegment;
    if (fromHost) {
      seg = segment.partialClone(tValues, false);
      this.surfacePoints.push(...seg.getStoredPoints());
    } else {
      seg = segment.globalPartialClone(
        this.transformInfo.guestToHost,
        tValues,
        false
      );
    }

    this.validate(seg.start, seg.end, toEnd);

    if (toEnd) {
      this.forwardsSegments.push(seg);
    } else {
      this.backwardsSegments.push(seg);
    }

    this.updateSums(seg.getAllContributions());
  }

  addWholeSegments(segments: Segment[], toEnd: boolean, fromHost: boolean) {
    for (let i = 0; i < segments.length; i++) {
      let seg: Segment;
      if (fromHost) {
        seg = segments[i];
        this.surfacePoints.push(...seg.getStoredPoints());
      } else {
        seg = segments[i].globalClone(this.transformInfo.guestToHost, false);
      }

      if (seg.type !== SegmentType.CIRCLE) {
        if (i === 0) {
          this.validate(
            (seg as OpenSegment).start,
            (seg as OpenSegment).end,
            toEnd
          );
        } else if (i === segments.length - 1) {
          if (toEnd) {
            this.lastPoint = (seg as OpenSegment).end;
          } else {
            this.firstPoint = (seg as OpenSegment).start;
          }
        }
      }

      this.updateSums(seg.getAllContributions());
    }

    if (toEnd) {
      this.forwardsSegments.push(...segments);
    } else {
      this.backwardsSegments.push(...segments);
    }
  }

  validate(start: Vector2D, end: Vector2D, toEnd: boolean): void {
    let distance: number;
    if (toEnd && this.lastPoint !== null) {
      distance = this.lastPoint.subtract(start).length();
      if (distance > 0.0001) {
        console.error(
          `Segment endpoint mismatch: distance ${distance}, start ${start}, end ${end}`
        );
        throw new Error(`Segment endpoint mismatch: distance ${distance}`);
      }
      this.lastPoint = end;
    } else if (!toEnd && this.firstPoint !== null) {
      distance = this.firstPoint.subtract(end).length();
      if (distance > 0.0001) {
        console.error(
          `Segment endpoint mismatch: distance ${distance}, start ${start}, end ${end}`
        );
        throw new Error(`Segment endpoint mismatch: distance ${distance}`);
      }
      this.firstPoint = start;
    } else {
      return;
    }
  }

  finishShape(): void {
    this.resultShapes.push({
      shape: new Shape(
        this.forwardsSegments.concat(this.backwardsSegments.reverse()),
        this.getProperties()
      ),
      surfacePoints: this.surfacePoints,
    });
  }

  startSpan(
    onHost: boolean,
    forwards: boolean,
    currentIntersection: IntersectionPoint,
    nextIntersection: IntersectionPoint
  ): void {}

  getFinalResult(): { shape: Shape; surfacePoints: SurfacePoint[] }[] {
    return this.resultShapes;
  }

  newShape(root: IntersectionPoint): void {
    this.shoelaceAreaSum = 0;
    this.shoelaceCentroidSum = new Vector2D(0, 0);
    this.shoelaceSecondMomentOfAreaSum = 0;
    this.compositeAreaSum = 0;
    this.compositeCentroidSum = new Vector2D(0, 0);
    this.compositeSecondMomentOfAreaSum = 0;
    this.contributions = [];
    this.forwardsSegments = [];
    this.backwardsSegments = [];
    this.lastPoint = null;
    this.firstPoint = null;
    this.mecPoints = [];
    this.surfacePoints = [];
  }
}
