import type { IntersectionShapeBuilder } from "../../geometry/buildIntersectionShapes";
import type { WelzlPoint } from "../../geometry/welzl";
import { Vector2D } from "../../math/vector2D";
import {
  type RelativeTransformInfo,
  type SegmentContributions,
  type Segment,
  type OpenSegment,
  SegmentType,
  type IntersectionPoint,
} from "../../models";
import type { SurfacePoint } from "../../plane/surfacePoint";
import { Shape } from "../shape";

export class CutShapeBuilder implements IntersectionShapeBuilder {
  targetShape: Shape;
  transformInfo: RelativeTransformInfo;
  resultShapes: { shape: Shape; surfacePoints: SurfacePoint[] }[] = [];
  totalArea = 0;
  weightedCentroidSum = new Vector2D(0, 0);
  totalSecondMomentOfArea = 0;
  forwardsSegments: Segment[] = [];
  backwardsSegments: Segment[] = [];
  lastPoint: Vector2D | null = null;
  firstPoint: Vector2D | null = null;
  mecPoints: WelzlPoint[] = [];
  surfacePoints: SurfacePoint[] = [];

  constructor(transformInfo: RelativeTransformInfo, targetShape: Shape) {
    this.transformInfo = transformInfo;
    this.targetShape = targetShape;
  }

  updateSums(contributions: SegmentContributions): void {
    const { area, centroid, secondMomentOfArea } = contributions;

    this.totalArea += area;
    this.weightedCentroidSum = this.weightedCentroidSum.add(
      centroid.multiply(area)
    );

    this.totalSecondMomentOfArea +=
      secondMomentOfArea + area * centroid.lengthSquared();
  }

  getProperties(): {
    centroid: Vector2D;
    area: number;
    secondMomentOfArea: number;
  } {
    const finalCentroid = this.weightedCentroidSum.divide(this.totalArea);

    this.totalSecondMomentOfArea -=
      this.totalArea * finalCentroid.lengthSquared();

    return {
      centroid: finalCentroid,
      area: this.totalArea,
      secondMomentOfArea: this.totalSecondMomentOfArea,
    };
  }

  addPartialSegment(
    segment: Segment,
    toEnd: boolean,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    fromHost: boolean
  ) {
    let seg: OpenSegment;
    if (fromHost) {
      seg = segment.partialClone(tValues, false);
      this.surfacePoints.push(...seg.getStoredPoints());
    } else {
      seg = segment.globalPartialClone(
        this.transformInfo.guestToHost,
        tValues,
        false
      );
    }

    this.validate(seg.start, seg.end, toEnd);

    if (toEnd) {
      this.forwardsSegments.push(seg);
    } else {
      this.backwardsSegments.push(seg);
    }

    this.updateSums(seg.getAllContributions());
  }

  addWholeSegments(segments: Segment[], toEnd: boolean, fromHost: boolean) {
    for (let i = 0; i < segments.length; i++) {
      let seg: Segment;
      if (fromHost) {
        seg = segments[i];
        this.surfacePoints.push(...seg.getStoredPoints());
      } else {
        seg = segments[i].globalClone(this.transformInfo.guestToHost, false);
      }

      if (seg.type !== SegmentType.CIRCLE) {
        if (i === 0) {
          this.validate(
            (seg as OpenSegment).start,
            (seg as OpenSegment).end,
            toEnd
          );
        } else if (i === segments.length - 1) {
          if (toEnd) {
            this.lastPoint = (seg as OpenSegment).end;
          } else {
            this.firstPoint = (seg as OpenSegment).start;
          }
        }
      }

      this.updateSums(seg.getAllContributions());
    }

    if (toEnd) {
      this.forwardsSegments.push(...segments);
    } else {
      this.backwardsSegments.push(...segments);
    }
  }

  validate(start: Vector2D, end: Vector2D, toEnd: boolean): void {
    let distance: number;
    if (toEnd && this.lastPoint !== null) {
      distance = this.lastPoint.subtract(start).length();
      if (distance > 0.0001) {
        console.error(
          `Segment endpoint mismatch: distance ${distance}, start ${start}, end ${end}`
        );
        throw new Error(`Segment endpoint mismatch: distance ${distance}`);
      }
      this.lastPoint = end;
    } else if (!toEnd && this.firstPoint !== null) {
      distance = this.firstPoint.subtract(end).length();
      if (distance > 0.0001) {
        console.error(
          `Segment endpoint mismatch: distance ${distance}, start ${start}, end ${end}`
        );
        throw new Error(`Segment endpoint mismatch: distance ${distance}`);
      }
      this.firstPoint = start;
    } else {
      return;
    }
  }

  finishShape(): void {
    this.resultShapes.push({
      shape: new Shape(
        this.forwardsSegments.concat(this.backwardsSegments.reverse()),
        this.getProperties()
      ),
      surfacePoints: this.surfacePoints,
    });
  }

  startSpan(
    onHost: boolean,
    forwards: boolean,
    currentIntersection: IntersectionPoint,
    nextIntersection: IntersectionPoint
  ): void {}

  getFinalResult(): { shape: Shape; surfacePoints: SurfacePoint[] }[] {
    return this.resultShapes;
  }

  newShape(root: IntersectionPoint): void {
    this.totalArea = 0;
    this.weightedCentroidSum = new Vector2D(0, 0);
    this.totalSecondMomentOfArea = 0;
    this.forwardsSegments = [];
    this.backwardsSegments = [];
    this.lastPoint = null;
    this.firstPoint = null;
    this.mecPoints = [];
    this.surfacePoints = [];
  }
}
