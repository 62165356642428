import type { AABB, Segment, SegmentInfo } from "../models.ts";
import type { Vector2D } from "../math/vector2D.ts";
import type { Circle } from "../models.ts";

const BRANCH_FACTOR = 2;

export function buildSegmentTree(segments: Segment[]): SegmentTree {
  const leaves: SegmentTree[] = [];
  for (const [index, segment] of segments.entries()) {
    leaves.push(new SegmentTree({ segment, index }));
  }

  let currentLevel = leaves;
  let depth = 0;
  while (currentLevel.length > 1) {
    currentLevel = buildSegmentTreeLevel(currentLevel);
    depth++;
  }

  return currentLevel[0];
}

export function buildSegmentTreeLevel(
  currentLevel: SegmentTree[]
): SegmentTree[] {
  const nextLevel: SegmentTree[] = [];

  // Process nodes from start to end in pairs
  for (let i = 0; i < currentLevel.length; i += BRANCH_FACTOR) {
    const siblings = currentLevel.slice(i, i + BRANCH_FACTOR);
    nextLevel.push(new SegmentTree(siblings));
  }

  return nextLevel;
}

export class SegmentTree {
  bounds: AABB;
  children: SegmentTree[];
  segment: SegmentInfo | null = null;

  constructor(children: SegmentTree[] | SegmentInfo) {
    this.children = [];
    if (Array.isArray(children)) {
      this.children = children;
      this.bounds = getSharedBounds(children);
    } else {
      this.segment = children;
      this.bounds = children.segment.getAABB();
    }
  }

  queryHorizontalRay(point: Vector2D): SegmentInfo[] {
    const results: SegmentInfo[] = [];
    this.queryHorizontalRayRecursive(point, results);
    return results;
  }

  private queryHorizontalRayRecursive(
    point: Vector2D,
    results: SegmentInfo[]
  ): void {
    // Skip this branch if the ray doesn't intersect the bounds
    if (
      point.y < this.bounds.top ||
      point.y > this.bounds.bottom ||
      point.x > this.bounds.right
    ) {
      return;
    }

    // If this is a leaf node with a segment, add it to results
    if (this.segment !== null) {
      results.push(this.segment);
      return;
    }

    // Recursively check children
    for (const child of this.children) {
      child.queryHorizontalRayRecursive(point, results);
    }
  }

  queryCircle(circle: Circle): SegmentInfo[] {
    const results: SegmentInfo[] = [];
    this.queryCircleRecursive(circle, results);
    return results;
  }

  private queryCircleRecursive(circle: Circle, results: SegmentInfo[]): void {
    // Skip this branch if the circle doesn't intersect the bounds
    if (!boundsIntersectsCircle(this.bounds, circle)) {
      return;
    }

    // If this is a leaf node with a segment, add it to results
    if (this.segment !== null) {
      results.push(this.segment);
      return;
    }

    // Recursively check children
    for (const child of this.children) {
      child.queryCircleRecursive(circle, results);
    }
  }
}

function getSharedBounds(siblings: SegmentTree[]): AABB {
  let left = Infinity;
  let right = -Infinity;
  let top = Infinity;
  let bottom = -Infinity;

  for (const sibling of siblings) {
    left = Math.min(left, sibling.bounds.left);
    right = Math.max(right, sibling.bounds.right);
    top = Math.min(top, sibling.bounds.top);
    bottom = Math.max(bottom, sibling.bounds.bottom);
  }

  return { left, right, top, bottom };
}

export function boundsIntersectsCircle(bounds: AABB, circle: Circle): boolean {
  // Quick rejection: if the circle is too far from the bounds
  if (
    circle.x - circle.radius > bounds.right ||
    circle.x + circle.radius < bounds.left ||
    circle.y - circle.radius > bounds.bottom ||
    circle.y + circle.radius < bounds.top
  ) {
    return false;
  }

  // Quick acceptance: if circle center is inside bounds
  if (
    circle.x >= bounds.left &&
    circle.x <= bounds.right &&
    circle.y >= bounds.top &&
    circle.y <= bounds.bottom
  ) {
    return true;
  }

  // Check if circle intersects with any edge by finding closest point on bounds
  // to circle center and checking if it's within radius
  const closestX = Math.max(bounds.left, Math.min(circle.x, bounds.right));
  const closestY = Math.max(bounds.top, Math.min(circle.y, bounds.bottom));

  const distanceX = circle.x - closestX;
  const distanceY = circle.y - closestY;

  return (
    distanceX * distanceX + distanceY * distanceY <=
    circle.radius * circle.radius
  );
}
