import { PlanarClient } from "./planarClient";
import { Body } from "../plane/body";

export class PlanarLocal extends PlanarClient {
  resolveCollisions: boolean;

  constructor(canvas: HTMLCanvasElement, resolveCollisions: boolean) {
    super({ canvas, networked: false, sendToServer: () => {} });

    this.resolveCollisions = resolveCollisions;

    this.localPlayer = this.addNewPlayer("local");
    this.neverUnloadBodyIds.add(this.localPlayer.actuator.base.id);
    this.neverUnloadBodyIds.add(this.localPlayer.actuator.endEffector.id);
  }

  updatePlane(dt: number, now: number): void {
    this.plane.update(dt, now, this.resolveCollisions);
  }

  afterAddBody(body: Body): void {
    // Do nothing
  }
}
