import type { Vector2D } from "./math/vector2D.ts";
import type { Transform } from "./math/transform.ts";
import type { Shape } from "./shapes/shape.ts";
import type { Body } from "./plane/body.ts";
import type {
  GuestMessageType,
  HostMessageType,
  ServerMessageType,
} from "./server.ts";
import type { Color } from "./rendering/color.ts";
import type { SurfacePoint } from "./plane/surfacePoint.ts";

export interface Circle {
  x: number;
  y: number;
  radius: number;
}

export type SegmentInfo = {
  segment: Segment;
  index: number;
};

export type AABB = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export type RelativeTransformInfo = {
  guestToHost: Transform;
  hostToGuest: Transform;
  hostToGlobal: Transform;
};

export interface BodyState {
  position: [number, number, number];
  velocity: [number, number, number];
}

export interface FullBodyState {
  x: number;
  y: number;
  rotation: number;
  sinRotation: number;
  cosRotation: number;
  velocityX: number;
  velocityY: number;
  angularVelocity: number;
  compositeR?: {
    x: number;
    y: number;
  };
}

export enum BodyType {
  PlayerBody,
  PlayerArm,
  Standard,
  Item,
  Enemy,
}

export type WelcomeMessage = {
  type: ServerMessageType.Welcome;
  playerId: string;
  isHost: boolean;
};

export type NewPlayerRequest = {
  type: GuestMessageType.NewPlayerRequest;
  playerId: string;
  cameraWidth: number;
  cameraHeight: number;
};

export type NewPlayerResponse = {
  type: HostMessageType.NewPlayerResponse;
  playerId: string;
  planeData: {
    bodies: SerializedBody[];
    actuators: SerializedActuator[];
    players: SerializedPlayer[];
  };
};

export type PlaneData = {
  type: HostMessageType.PlaneData;
  excludePlayer?: string;
  bodies?: SerializedBody[];
  actuators?: SerializedActuator[];
  players?: SerializedPlayer[];
  composites?: SerializedComposite[];
};

export type SerializedBody = {
  id: string;
  shape: SerializedShape;
  material: Material;
  type: BodyType;
  positionLocked: boolean;
  rotationLocked: boolean;
  inSubplane: boolean;
};

export type SerializedActuator = {
  id: string;
  baseId: string;
  endEffectorId: string;
  pidParams: PIDParams;
};

export type SerializedPlayer = {
  id: string;
  actuatorId: string;
};

export enum SegmentType {
  LINE,
  ARC,
  CIRCLE,
}

export type SegmentContributions = {
  area: number;
  centroid: Vector2D;
  secondMomentOfArea: number;
};

export type CollisionContributions = {
  area: number;
  centroid: Vector2D;
  points?: Vector2D[];
};

export type SubtractionResult = {
  shape: Shape;
  position: [number, number, number];
};

export interface LineIntersection {
  point: Vector2D;
  t: number; // Parameter along the line (0-1)
  segment: Segment;
}

export type SerializedLineSegment = {
  type: SegmentType.LINE;
  start: [number, number];
  end: [number, number];
};

export type SerializedArcSegment = {
  type: SegmentType.ARC;
  start: [number, number];
  end: [number, number];
  sagitta: number;
};

export type SerializedCircleSegment = {
  type: SegmentType.CIRCLE;
  center: [number, number];
  radius: number;
};

export type SerializedSegment =
  | SerializedLineSegment
  | SerializedArcSegment
  | SerializedCircleSegment;

export type StoredPoint = {
  surfacePoint: SurfacePoint;
  t: number;
};

export type PerimeterPoint = {
  position: Vector2D;
  segment: Segment;
  segmentIndex: number;
  tOrAngle: number;
};

export type PointOnBodySurface = {
  body: Body;
  globalPosition: Vector2D;
  perimeterPoint: PerimeterPoint;
};

export interface Segment {
  type: SegmentType;
  storedPoints: StoredPoint[];
  getData(transform?: Transform): LineData | ArcData | CircleData;
  getAABB(): AABB;
  rayIntersectionCount(point: Vector2D): number;
  globalClone(transform: Transform, invert: boolean): Segment;
  globalPartialClone(
    transform: Transform,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): OpenSegment;
  clone(invert: boolean): Segment;
  partialClone(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): OpenSegment;
  translate(dx: number, dy: number): void;
  getAllContributions(): SegmentContributions;
  getCollisionContributions(transform?: Transform): CollisionContributions;
  getPartialCollisionContributions(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    transform?: Transform
  ): CollisionContributions;
  serialize(): SerializedSegment;
  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    transform?: Transform
  ): LineIntersection[];
  intersectsAABB(bounds: AABB): boolean;
  intersectsCircle(circle: Circle): boolean;
  getPointAtDistance(distance: number): Vector2D | number;
  getClosestPoint(point: Vector2D): {
    point: Vector2D;
    tOrAngle: number;
  };
  storePoint(surfacePoint: SurfacePoint, tOrAngle: number): void;
  getStoredPoints(): SurfacePoint[];
  getPointAtT(t: number): Vector2D | null;
}

export type SerializedShape = {
  segments: SerializedSegment[];
};

export type SerializedComposite = {
  id: string;
  bodies: {
    id: string;
    offset: [number, number];
    offsetRotation: number;
  }[];
};

export interface OpenSegment extends Segment {
  type: SegmentType.LINE | SegmentType.ARC;
  next: OpenSegment | null;
  previous: OpenSegment | null;
  start: Vector2D;
  end: Vector2D;
}

export type IntersectionShape = {
  shapes: {
    shape: Shape;
    centroid: Vector2D;
  }[];
  totalCentroid: Vector2D;
  normal: Vector2D;
  penetrationDistance: number;
};

export interface TetherConfig {
  maxLength: number; // Maximum allowed length between actuator objects
  restitution: number;
  baumgarteScale: number;
  slop: number;
}

export interface PIDParams {
  maxForce: number;
  kp: number;
  ki: number;
  kd: number;
  windupLimit: number;
}

export interface NetworkStateEntry {
  id: string;
  position: [number, number, number]; // [x, y, rotation]
  velocity: [number, number, number]; // [x, y, angular velocity]
}

export interface NetworkState {
  clientTimestamp: number;
  dt: number;
  entries: NetworkStateEntry[];
  used: boolean;
}

export interface Timer {
  id: number;
  callback: () => void;
  interval: number;
  nextTick: number;
  repeating: boolean;
}

export enum EnclosureType {
  None,
  FirstBodyEncloses,
  SecondBodyEncloses,
}

export interface CollisionPair {
  body1Mec: Circle;
  body2Mec: Circle;
  body1Transform: Transform;
  body2Transform: Transform;
  relativeTransformInfo: RelativeTransformInfo;
  body1: Body;
  body2: Body;
  segmentPairs: [SegmentInfo, SegmentInfo][];
  intersectionPoints: [IntersectionPoint[], IntersectionPoint[]] | null;
  enclosureType: EnclosureType;
}

export interface CircleData {
  type: SegmentType.CIRCLE;
  center: Vector2D;
  radius: number;
  clockwise: boolean;
}

export interface ArcData {
  type: SegmentType.ARC;
  center: Vector2D;
  radius: number;
  startAngle: number;
  endAngle: number;
  centralAngle: number;
  clockwise: boolean;
}

export interface LineData {
  type: SegmentType.LINE;
  start: Vector2D;
  end: Vector2D;
}

export type IntersectionPoint = {
  t: number;
  segmentIndex: number;
  entering: boolean;
  visited: boolean;
  point: Vector2D;
  onCircle: boolean;
  counterpart?: IntersectionPoint;
  previousIndex?: number;
  nextIndex?: number;
};

export interface ShapeMotionInfo {
  shape1: {
    vx: number;
    vy: number;
    angularVelocity: number; // in radians/second
  };
  shape2: {
    vx: number;
    vy: number;
    angularVelocity: number; // in radians/second
  };
}

export interface Material {
  density: number;
  restitution: number;
  staticFriction: number;
  dynamicFriction: number;
  color: Color;
  destructible: boolean;
}

export class CollisionPairKey {
  constructor(private body1: number, private body2: number) {
    // Always store pairs in sorted order to ensure consistent lookup
    if (body1 > body2) {
      [this.body1, this.body2] = [body2, body1];
    }
  }

  toString(): string {
    return `${this.body1},${this.body2}`;
  }
}

export interface CollisionInfo {
  body1: Body;
  body2: Body;
  pointOfContact: Vector2D;
  normal: Vector2D;
  penetrationDistance: number;
}

export interface IntersectionCollisionInfo extends CollisionInfo {
  intersectionPoints: [IntersectionPoint[], IntersectionPoint[]];
}

export interface EnclosureCollisionInfo extends CollisionInfo {
  enclosure: EnclosureType;
}

export type CollisionTrigger = (
  otherBody: Body,
  collision: CollisionInfo
) => void;

export type ShouldResolveCollisionCheck = (collision: CollisionInfo) => boolean;

export enum ButtonEvent {
  StartGrab,
  StopGrab,
  StartAction,
  StopAction,
  PreviousAction,
  NextAction,
  StartLeftThrust,
  StopLeftThrust,
  StartRightThrust,
  StopRightThrust,
  StartUpThrust,
  StopUpThrust,
  StartDownThrust,
  StopDownThrust,
}

export type ButtonMap = Map<string, ButtonEvent>;

export const DEFAULT_BUTTON_MAP: ButtonMap = new Map([
  ["leftMouse-down", ButtonEvent.StartAction],
  ["leftMouse-up", ButtonEvent.StopAction],
  ["rightMouse-down", ButtonEvent.PreviousAction],
  ["rightMouse-up", ButtonEvent.NextAction],
  ["q-down", ButtonEvent.PreviousAction],
  ["e-down", ButtonEvent.NextAction],
  [" -down", ButtonEvent.StartAction],
  [" -up", ButtonEvent.StopAction],
  ["w-down", ButtonEvent.StartUpThrust],
  ["w-up", ButtonEvent.StopUpThrust],
  ["a-down", ButtonEvent.StartLeftThrust],
  ["a-up", ButtonEvent.StopLeftThrust],
  ["d-down", ButtonEvent.StartRightThrust],
  ["d-up", ButtonEvent.StopRightThrust],
  ["s-down", ButtonEvent.StartDownThrust],
  ["s-up", ButtonEvent.StopDownThrust],
  ["rightTrigger-down", ButtonEvent.StartAction],
  ["rightTrigger-up", ButtonEvent.StopAction],
  ["leftTrigger-down", ButtonEvent.StartAction],
  ["leftTrigger-up", ButtonEvent.StopAction],
  ["gamepadA-down", ButtonEvent.StartAction],
  ["gamepadA-up", ButtonEvent.StopAction],
  ["dPadLeft-down", ButtonEvent.PreviousAction],
  ["dPadRight-down", ButtonEvent.NextAction],
]);

export enum PacketType {
  StateUpdate = 0,
  ArmControl = 1,
  ButtonEvent = 2,
  Zoom = 3,
}

export type LightSource = {
  radius: number;
  glowRadius: number;
  brightnessStops: { position: number; opacity: number }[];
  colorStops: { position: number; opacity: number }[];
  color: Color;
};
