import type { TetherConfig } from "../models.ts";
import type { Body } from "./body.ts";

export class CentroidTether {
  private body1: Body;
  private body2: Body;
  private maxLength: number;
  private restitution: number;
  private baumgarteScale: number;
  private slop: number;

  constructor(body1: Body, body2: Body, config: TetherConfig) {
    this.body1 = body1;
    this.body2 = body2;
    this.maxLength = config.maxLength;
    this.restitution = config.restitution;
    this.baumgarteScale = config.baumgarteScale;
    this.slop = config.slop;
  }

  apply(): Body[] {
    // Get positions and masses
    const pos1 = this.body1.getCenter();
    const pos2 = this.body2.getCenter();
    const mass1 = this.body1.getMass(true);
    const mass2 = this.body2.getMass(true);

    // Calculate inverse masses (0 for infinite mass)
    const invMass1 = mass1 === Infinity ? 0 : 1 / mass1;
    const invMass2 = mass2 === Infinity ? 0 : 1 / mass2;

    // Skip if both objects have infinite mass
    if (invMass1 === 0 && invMass2 === 0) return [];

    // Calculate current length and direction
    const displacement = pos2.subtract(pos1);
    const currentLength = displacement.magnitude();

    // Only apply constraint if length exceeds maximum
    if (currentLength > this.maxLength) {
      const direction = displacement.scale(1 / currentLength); // Normalized direction

      // Get velocities and calculate relative velocity
      const vel1 = this.body1.getLinearVelocity(true);
      const vel2 = this.body2.getLinearVelocity(true);
      const relativeVelocity = vel2.subtract(vel1);

      // Project relative velocity onto constraint direction
      const normalVelocity = relativeVelocity.dot(direction);

      // Calculate moment arms (from center of mass to point of force application)
      const r1 = pos1.subtract(this.body1.getCenter(true));
      const r2 = pos2.subtract(this.body2.getCenter(true));

      // Get moments of inertia and calculate inverse inertias
      const inertia1 = this.body1.getMomentOfInertia(true);
      const inertia2 = this.body2.getMomentOfInertia(true);
      const invInertia1 = this.body1.rotationLocked ? 0 : 1 / inertia1;
      const invInertia2 = this.body2.rotationLocked ? 0 : 1 / inertia2;

      // Calculate r cross n terms for the denominator
      const r1CrossN = r1.x * direction.y - r1.y * direction.x;
      const r2CrossN = r2.x * direction.y - r2.y * direction.x;

      // Calculate denominator including angular terms
      const denominator =
        invMass1 +
        invMass2 +
        r1CrossN * r1CrossN * invInertia1 +
        r2CrossN * r2CrossN * invInertia2;

      if (denominator === 0) return [];

      // Calculate impulse magnitude
      const penetration = currentLength - this.maxLength;
      const restitutionImpulse =
        normalVelocity > 0 ? -(1 + this.restitution) * normalVelocity : 0;
      const penetrationImpulse = penetration * this.baumgarteScale;
      const totalImpulse =
        (restitutionImpulse + penetrationImpulse) / denominator;

      const impulseVector = direction.scale(-totalImpulse);

      // Apply impulses to both bodies
      if (invMass1 > 0) {
        this.body1.applyImpulse(impulseVector, r1);
      }
      if (invMass2 > 0) {
        this.body2.applyImpulse(impulseVector.scale(-1), r2);
      }

      let appliedPositionCorrectionTo: Body[] = [];

      // Position correction for large penetrations
      if (penetration > this.slop) {
        const correction = direction.scale(
          ((penetration - this.slop) * this.baumgarteScale) / denominator
        );

        if (invMass1 > 0) {
          this.body1.addPositionCorrection(correction);
          appliedPositionCorrectionTo.push(this.body1);
        }
        if (invMass2 > 0) {
          this.body2.addPositionCorrection(correction.scale(-1));
          appliedPositionCorrectionTo.push(this.body2);
        }
      }

      return appliedPositionCorrectionTo;
    }

    return [];
  }
}
