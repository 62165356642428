import type { IntersectionShapeBuilder } from "../../geometry/buildIntersectionShapes.ts";
import { Vector2D } from "../../math/vector2D.ts";
import {
  type CollisionContributions,
  type IntersectionPoint,
  type RelativeTransformInfo,
  type Segment,
} from "../../models.ts";

export class CollisionShapeBuilder implements IntersectionShapeBuilder {
  transformInfo: RelativeTransformInfo;
  shoelaceAreaSum = 0;
  shoelaceCentroidSum = new Vector2D(0, 0);
  compositeAreaSum = 0;
  compositeCentroidSum = new Vector2D(0, 0);
  firstPoint: Vector2D | null = null;
  lastPoint: Vector2D | null = null;

  totalNormal: Vector2D = new Vector2D(0, 0);
  totalCentroid: Vector2D = new Vector2D(0, 0);
  normalSum: Vector2D = new Vector2D(0, 0);
  points: Vector2D[] = [];
  numResults = 0;
  rootIntersection: Vector2D | null = null;
  direction: number | null = null;

  totalArea = 0;

  constructor(transformInfo: RelativeTransformInfo) {
    this.transformInfo = transformInfo;
  }

  updateSums(contributions: CollisionContributions): void {
    const { shoelaceFactor, shoelaceCentroid, area, centroid } = contributions;

    // Handle polygonal (shoelace) contributions
    if (shoelaceFactor && shoelaceCentroid) {
      this.shoelaceAreaSum += shoelaceFactor;
      this.shoelaceCentroidSum = this.shoelaceCentroidSum.add(shoelaceCentroid);
    }

    // Handle composite (circle) contributions
    if (area && centroid) {
      this.compositeAreaSum += area;
      this.compositeCentroidSum = this.compositeCentroidSum.add(
        centroid.multiply(area)
      );
    }
  }

  getCentroidAndArea(): {
    centroid: Vector2D;
    area: number;
  } {
    if (Math.abs(this.shoelaceAreaSum) > 0.0001) {
      const shoelaceArea = this.shoelaceAreaSum / 2;
      const shoelaceCentroid = this.shoelaceCentroidSum.divide(
        6 * shoelaceArea
      );

      // Add polygonal contribution to composite sums
      this.compositeAreaSum += shoelaceArea;
      this.compositeCentroidSum = this.compositeCentroidSum.add(
        shoelaceCentroid.multiply(shoelaceArea)
      );
    }

    const finalCentroid = this.compositeCentroidSum.divide(
      this.compositeAreaSum
    );

    return {
      centroid: finalCentroid,
      area: this.compositeAreaSum,
    };
  }

  addPartialSegment(
    segment: Segment,
    toEnd: boolean,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    fromHost: boolean
  ) {
    const contributions = segment.getPartialCollisionContributions(
      tValues,
      fromHost ? undefined : this.transformInfo.guestToHost
    );

    if (contributions.points) {
      this.validate(contributions.points[0], contributions.points[1], toEnd);
      this.points.push(...contributions.points);
    }

    this.updateSums(contributions);
  }

  addWholeSegments(segments: Segment[], toEnd: boolean, fromHost: boolean) {
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      const contributions = segment.getCollisionContributions(
        fromHost ? undefined : this.transformInfo.guestToHost
      );

      // Only validate on first and last segment
      if (contributions.points) {
        if (i === 0) {
          this.validate(
            contributions.points[0],
            contributions.points[1],
            toEnd
          );
        } else if (i === segments.length - 1) {
          if (toEnd) {
            this.lastPoint = contributions.points[1];
          } else {
            this.firstPoint = contributions.points[0];
          }
        }
        this.points.push(...contributions.points);
      }

      this.updateSums(contributions);
    }
  }

  validate(start: Vector2D, end: Vector2D, toEnd: boolean): void {
    let distance: number;
    if (toEnd && this.lastPoint !== null) {
      distance = this.lastPoint.subtract(start).length();
      if (distance > 0.0001) {
        throw new Error(`Segment endpoint mismatch: distance ${distance}`);
      }
      this.lastPoint = end;
    } else if (!toEnd && this.firstPoint !== null) {
      distance = this.firstPoint.subtract(end).length();
      if (distance > 0.0001) {
        throw new Error(`Segment endpoint mismatch: distance ${distance}`);
      }
      this.firstPoint = start;
    } else {
      return;
    }
  }

  finishShape(): void {
    if (this.rootIntersection === null || this.direction === null) {
      throw new Error("Shape not properly initialized");
    }

    let minDistance = Infinity;
    let maxDistance = -Infinity;
    // Find the penetration distance
    const normal = this.normalSum.perpendicular().normalize();

    for (const point of this.points) {
      const penetration = point.subtract(this.rootIntersection).dot(normal);
      if (penetration > maxDistance) {
        maxDistance = penetration;
      }
      if (penetration < minDistance) {
        minDistance = penetration;
      }
    }

    const penetrationDistance = maxDistance - minDistance;

    this.totalNormal = this.totalNormal.add(
      normal.multiply(penetrationDistance * this.direction)
    );

    this.normalSum = new Vector2D(0, 0);
    this.points = [];

    const { centroid, area } = this.getCentroidAndArea();
    if (this.numResults === 0) {
      this.totalCentroid = centroid.multiply(area);
      this.totalArea = area;
    } else {
      this.totalCentroid = this.totalCentroid.add(centroid.multiply(area));
      this.totalArea += area;
    }
    this.numResults++;
  }

  startSpan(
    onHost: boolean,
    forwards: boolean,
    currentIntersection: IntersectionPoint,
    nextIntersection: IntersectionPoint
  ): void {
    if (!onHost) {
      this.normalSum = this.normalSum.add(
        nextIntersection.point.subtract(currentIntersection.point)
      );
    }
  }

  getFinalResult(): {
    centroid: Vector2D;
    normal: Vector2D;
    penetrationDistance: number;
  } {
    return {
      centroid: this.transformInfo.hostToGlobal.apply(
        this.totalCentroid.divide(this.totalArea)
      ),
      normal: this.totalNormal
        .normalize()
        .rotate(this.transformInfo.hostToGlobal.angle),
      penetrationDistance: this.totalNormal.length(),
    };
  }

  newShape(root: IntersectionPoint): void {
    this.rootIntersection = root.point;
    this.direction = root.entering ? 1 : -1;

    this.shoelaceAreaSum = 0;
    this.shoelaceCentroidSum = new Vector2D(0, 0);
    this.compositeAreaSum = 0;
    this.compositeCentroidSum = new Vector2D(0, 0);
    this.firstPoint = null;
    this.lastPoint = null;
  }
}
