import { Transform } from "../math/transform";
import type { AABB, SegmentInfo } from "../models";
import { SegmentTree } from "./segmentTree";

enum TransformQuadrant {
  TopLeftIsTopBound,
  TopLeftIsRightBound,
  TopLeftIsBottomBound,
  TopLeftIsLeftBound,
}

export function intersectSegmentTrees(
  a: SegmentTree,
  b: SegmentTree,
  aToB: Transform,
  bToA: Transform
): [SegmentInfo, SegmentInfo][] {
  const aQuadrant = getAngleQuadrant(aToB.sin, aToB.cos);
  const bQuadrant = getAngleQuadrant(bToA.sin, bToA.cos);

  const aTransformed: IncompleteAABB = {
    left: undefined,
    right: undefined,
    top: undefined,
    bottom: undefined,
  };
  const bTransformed: IncompleteAABB = {
    left: undefined,
    right: undefined,
    top: undefined,
    bottom: undefined,
  };

  if (
    !boundsIntersect(
      a.bounds,
      b.bounds,
      aToB,
      bToA,
      aQuadrant,
      bQuadrant,
      aTransformed,
      bTransformed
    )
  ) {
    return [];
  }

  const results: [SegmentInfo, SegmentInfo][] = [];

  intersectSegmentTreesRecursive(
    {
      tree: a,
      transform: aToB,
      quadrant: aQuadrant,
      transformedBounds: aTransformed,
    },
    {
      tree: b,
      transform: bToA,
      quadrant: bQuadrant,
      transformedBounds: bTransformed,
    },
    results
  );

  return results;
}

export function intersectSegmentTreesRecursive(
  a: {
    tree: SegmentTree;
    transform: Transform;
    quadrant: TransformQuadrant;
    transformedBounds: IncompleteAABB;
  },
  b: {
    tree: SegmentTree;
    transform: Transform;
    quadrant: TransformQuadrant;
    transformedBounds: IncompleteAABB;
  },
  results: [SegmentInfo, SegmentInfo][]
): void {
  const aChildren = a.tree.children;
  const bChildren = b.tree.children;

  if (aChildren.length > 0 && bChildren.length > 0) {
    const aTransformed: (IncompleteAABB | undefined)[] = Array(
      aChildren.length
    );
    const bTransformed: (IncompleteAABB | undefined)[] = Array(
      bChildren.length
    );

    for (let i = 0; i < aChildren.length; i++) {
      if (aTransformed[i] === undefined) {
        aTransformed[i] = {
          left: undefined,
          right: undefined,
          top: undefined,
          bottom: undefined,
        };
      }

      for (let j = 0; j < bChildren.length; j++) {
        if (bTransformed[j] === undefined) {
          bTransformed[j] = {
            left: undefined,
            right: undefined,
            top: undefined,
            bottom: undefined,
          };
        }

        if (
          !boundsIntersect(
            aChildren[i].bounds,
            bChildren[j].bounds,
            a.transform,
            b.transform,
            a.quadrant,
            b.quadrant,
            aTransformed[i]!,
            bTransformed[j]!
          )
        ) {
          continue;
        }

        intersectSegmentTreesRecursive(
          {
            tree: aChildren[i],
            transform: a.transform,
            quadrant: a.quadrant,
            transformedBounds: aTransformed[i]!,
          },
          {
            tree: bChildren[j],
            transform: b.transform,
            quadrant: b.quadrant,
            transformedBounds: bTransformed[j]!,
          },
          results
        );
      }
    }
  } else if (bChildren.length > 0) {
    // If a is a leaf, recursively check b's children
    for (const bChild of bChildren) {
      const bTransformed: IncompleteAABB = {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
      };

      if (
        !boundsIntersect(
          a.tree.bounds,
          bChild.bounds,
          a.transform,
          b.transform,
          a.quadrant,
          b.quadrant,
          a.transformedBounds,
          bTransformed
        )
      ) {
        continue;
      }

      intersectSegmentTreesRecursive(
        a,
        {
          tree: bChild,
          transform: b.transform,
          quadrant: b.quadrant,
          transformedBounds: bTransformed,
        },
        results
      );
    }
  } else if (aChildren.length > 0) {
    // If b is a leaf, recursively check a's children
    for (const aChild of aChildren) {
      const aTransformed: IncompleteAABB = {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
      };

      if (
        !boundsIntersect(
          aChild.bounds,
          b.tree.bounds,
          a.transform,
          b.transform,
          a.quadrant,
          b.quadrant,
          aTransformed,
          b.transformedBounds
        )
      ) {
        continue;
      }

      intersectSegmentTreesRecursive(
        {
          tree: aChild,
          transform: a.transform,
          quadrant: a.quadrant,
          transformedBounds: aTransformed,
        },
        b,
        results
      );
    }
  } else if (a.tree.segment !== null && b.tree.segment !== null) {
    // If both are leaves, add the segments to results
    results.push([a.tree.segment, b.tree.segment]);
  }
}

type IncompleteAABB = {
  left: number | undefined;
  right: number | undefined;
  top: number | undefined;
  bottom: number | undefined;
};

function boundsIntersect(
  a: AABB,
  b: AABB,
  aToB: Transform,
  bToA: Transform,
  aQuadrant: TransformQuadrant,
  bQuadrant: TransformQuadrant,
  aTransformed: IncompleteAABB,
  bTransformed: IncompleteAABB
): boolean {
  let tx = bToA.x;
  let cx = bToA.cos;
  let sx = bToA.sin;

  if (bTransformed.left === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.left = tx + cx * b.left - sx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.left = tx + cx * b.right - sx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.left = tx + cx * b.right - sx * b.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.left = tx + cx * b.left - sx * b.top;
        break;
    }
  }

  if (bTransformed.left > a.right) return false;

  if (bTransformed.right === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.right = tx + cx * b.right - sx * b.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.right = tx + cx * b.left - sx * b.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.right = tx + cx * b.left - sx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.right = tx + cx * b.right - sx * b.bottom;
        break;
    }
  }

  if (bTransformed.right < a.left) return false;

  let ty = bToA.y;

  if (bTransformed.top === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.top = ty + sx * b.left + cx * b.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.top = ty + sx * b.left + cx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.top = ty + sx * b.right + cx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.top = ty + sx * b.right + cx * b.top;
        break;
    }
  }

  if (bTransformed.top > a.bottom) return false;

  if (bTransformed.bottom === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.bottom = ty + sx * b.right + cx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.bottom = ty + sx * b.right + cx * b.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.bottom = ty + sx * b.left + cx * b.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.bottom = ty + sx * b.left + cx * b.bottom;
        break;
    }
  }

  if (bTransformed.bottom < a.top) return false;

  tx = aToB.x;
  cx = aToB.cos;
  sx = aToB.sin;

  if (aTransformed.left === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.left = tx + cx * a.left - sx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.left = tx + cx * a.right - sx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.left = tx + cx * a.right - sx * a.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.left = tx + cx * a.left - sx * a.top;
        break;
    }
  }

  if (aTransformed.left > b.right) return false;

  if (aTransformed.right === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.right = tx + cx * a.right - sx * a.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.right = tx + cx * a.left - sx * a.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.right = tx + cx * a.left - sx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.right = tx + cx * a.right - sx * a.bottom;
        break;
    }
  }

  if (aTransformed.right < b.left) return false;

  ty = aToB.y;

  if (aTransformed.top === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.top = ty + sx * a.left + cx * a.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.top = ty + sx * a.left + cx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.top = ty + sx * a.right + cx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.top = ty + sx * a.right + cx * a.top;
        break;
    }
  }

  if (aTransformed.top > b.bottom) return false;

  if (aTransformed.bottom === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.bottom = ty + sx * a.right + cx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.bottom = ty + sx * a.right + cx * a.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.bottom = ty + sx * a.left + cx * a.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.bottom = ty + sx * a.left + cx * a.bottom;
        break;
    }
  }

  if (aTransformed.bottom < b.top) return false;

  return true;
}

function getAngleQuadrant(sin: number, cos: number): TransformQuadrant {
  if (sin >= 0) {
    if (cos >= 0) {
      // Quadrant 1 (0° to 90°)
      return TransformQuadrant.TopLeftIsTopBound;
    } else {
      return TransformQuadrant.TopLeftIsRightBound;

      // Quadrant 2 (90° to 180°)
    }
  } else {
    if (cos <= 0) {
      // Quadrant 3 (180° to 270°)
      return TransformQuadrant.TopLeftIsBottomBound;
    } else {
      // Quadrant 4 (270° to 360°)
      return TransformQuadrant.TopLeftIsLeftBound;
    }
  }
}
