import { PlanarClient } from "./planar/planarClient.ts";
import { PlanarLocal } from "./planar/planarLocal.ts";

const canvas = document.getElementById("limbleCanvas") as HTMLCanvasElement;

let planar: PlanarLocal | PlanarClient | null = null;

planar = new PlanarLocal(canvas, true);
planar.generateVariety({
  boundarySize: 20000,
  smallCount: 500,
  mediumCount: 5,
  largeCount: 0,
  noiseShapes: false,
  cutShapes: true,
});
planar.start();

// if (local) {
//   planar = new PlanarLocal(canvas);
//   planar.generateVariety({
//     boundaryRadius: 20000,
//     smallCount: 500,
//     mediumCount: 0,
//     largeCount: 0,
//   });
//   planar.start();
// } else {
//   connectToServer();
// }

// async function connectToServer() {
//   const host = `${window.location.hostname}:${window.location.port}`;

//   const socket = new PartySocket({
//     host,
//     room: "ayyo",
//     startClosed: true,
//   });

//   socket.binaryType = "arraybuffer";

//   const onWelcomeMessage = (event: MessageEvent) => {
//     if (typeof event.data !== "string") {
//       return;
//     }

//     const welcomeMessage = JSON.parse(event.data);

//     if (welcomeMessage.type !== ServerMessageType.Welcome) {
//       return;
//     }

//     if (welcomeMessage.isHost) {
//       socket.removeEventListener("message", onWelcomeMessage);
//       const planarHost = new PlanarHost(
//         canvas,
//         socket,
//         welcomeMessage.playerId
//       );
//       planarHost.generateVariety({
//         boundaryRadius: 20000,
//         smallCount: 100,
//         mediumCount: 10,
//         largeCount: 2,
//       });
//       planarHost.start();
//     } else {
//       setupGuestConnection(socket, welcomeMessage.playerId);
//     }
//   };

//   // Setup listener to check host/guest
//   socket.onmessage = onWelcomeMessage;

//   socket.reconnect();
// }

// async function setupGuestConnection(socket: PartySocket, playerId: string) {
//   const onNewPlayerResponse = (event: MessageEvent) => {
//     if (typeof event.data !== "string") {
//       return;
//     }

//     const newPlayerResponse = JSON.parse(event.data);
//     if (newPlayerResponse.type === HostMessageType.NewPlayerResponse) {
//       socket.removeEventListener("message", onNewPlayerResponse);
//       const planarGuest = new PlanarGuest({
//         canvas,
//         socket,
//         newPlayerInfo: newPlayerResponse,
//         playerId,
//       });
//       planarGuest.start();
//     }
//   };

//   // Listen for new player response
//   socket.onmessage = onNewPlayerResponse;

//   // Send a new player request
//   const newPlayerRequest: NewPlayerRequest = {
//     type: GuestMessageType.NewPlayerRequest,
//     playerId,
//     cameraHeight: canvas.height,
//     cameraWidth: canvas.width,
//   };

//   socket.send(JSON.stringify(newPlayerRequest));
// }
