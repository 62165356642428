import { Vector2D } from "../math/vector2D.ts";

export class RenderRope {
  private points: Vector2D[]; // Internal points
  private previousPoints: Vector2D[]; // Add this property to the class
  private length: number;
  private segments: number;
  private thickness: number;
  private color: string;

  constructor(
    initialPoint1: Vector2D,
    initialPoint2: Vector2D,
    length: number,
    segments: number = 8,
    thickness: number = 10,
    color: string = "red"
  ) {
    this.length = length;
    this.segments = segments;
    this.thickness = thickness;
    this.color = color;
    this.points = [];
    this.previousPoints = [];
    this.initializePoints(initialPoint1, initialPoint2);
  }

  private initializePoints(
    initialEndPoint1: Vector2D,
    initialEndPoint2: Vector2D
  ) {
    // Create equally spaced points between endpoints
    for (let i = 0; i <= this.segments; i++) {
      const t = i / this.segments;
      const x =
        initialEndPoint1.x + (initialEndPoint2.x - initialEndPoint1.x) * t;
      const y =
        initialEndPoint1.y + (initialEndPoint2.y - initialEndPoint1.y) * t;
      this.points[i] = new Vector2D(x, y);
      this.previousPoints[i] = new Vector2D(x, y); // Initialize previous points
    }
  }

  private updatePoints(endPoint1: Vector2D, endPoint2: Vector2D, dt: number) {
    // Store the previous end points
    const prevEnd1 = this.points[0].clone();
    const prevEnd2 = this.points[this.points.length - 1].clone();

    // Update end points
    this.points[0] = endPoint1;
    this.points[this.points.length - 1] = endPoint2;

    // Update internal points with simplified physics (no gravity for now)
    for (let i = 1; i < this.points.length - 1; i++) {
      const point = this.points[i];
      const prev = this.previousPoints[i];

      // Store current position
      this.previousPoints[i] = point.clone();

      // Simple inertia - ensure we create a new vector for the update
      const velocity = point.subtract(prev);
      point.x += velocity.x;
      point.y += velocity.y;
    }

    // Apply constraints multiple times for stability
    for (let i = 0; i < 5; i++) {
      // Increased iterations for better stability
      this.applyConstraints();
    }
  }

  private applyConstraints() {
    const segmentLength = this.length / this.segments;

    for (let i = 0; i < this.points.length - 1; i++) {
      const p1 = this.points[i];
      const p2 = this.points[i + 1];

      // Calculate the current distance between points
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      const currentDist = Math.sqrt(dx * dx + dy * dy);

      if (currentDist === 0) continue; // Avoid division by zero

      // Calculate the correction factor
      const diff = (currentDist - segmentLength) / currentDist;

      // Apply correction
      if (i > 0) {
        // Don't move the first point
        p1.x += dx * diff * 0.5;
        p1.y += dy * diff * 0.5;
      }
      if (i < this.points.length - 2) {
        // Don't move the last point
        p2.x -= dx * diff * 0.5;
        p2.y -= dy * diff * 0.5;
      }
    }
  }

  render(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    endPoint1: Vector2D,
    endPoint2: Vector2D,
    dt: number
  ) {
    // Update physics
    this.updatePoints(endPoint1, endPoint2, dt);

    // Begin drawing
    ctx.beginPath();
    ctx.lineWidth = this.thickness;
    ctx.strokeStyle = this.color;
    ctx.lineCap = "round";

    // Draw smooth curve through points
    ctx.moveTo(this.points[0].x, this.points[0].y);

    for (let i = 1; i < this.points.length - 1; i++) {
      const xc = (this.points[i].x + this.points[i + 1].x) / 2;
      const yc = (this.points[i].y + this.points[i + 1].y) / 2;
      ctx.quadraticCurveTo(this.points[i].x, this.points[i].y, xc, yc);
    }

    // Connect to final point
    ctx.quadraticCurveTo(
      this.points[this.points.length - 2].x,
      this.points[this.points.length - 2].y,
      this.points[this.points.length - 1].x,
      this.points[this.points.length - 1].y
    );

    ctx.stroke();
  }
}
