import type { Transform } from "../../math/transform.ts";
import { Vector2D } from "../../math/vector2D.ts";
import {
  SegmentType,
  type SerializedLineSegment,
  type LineIntersection,
  type SegmentContributions,
  type OpenSegment,
  type CollisionContributions,
  type LineData,
  type Circle,
  type AABB,
} from "../../models.ts";
import { SurfacePoint } from "../../plane/surfacePoint.ts";

export class LineSegment implements OpenSegment {
  type: SegmentType.LINE = SegmentType.LINE;
  next: OpenSegment | null = null;
  previous: OpenSegment | null = null;
  start: Vector2D;
  end: Vector2D;
  length: number;
  shoelaceFactor: number;
  shoelaceCentroid: Vector2D;
  shoelaceSecondMomentOfArea: number;

  storedPoints: {
    surfacePoint: SurfacePoint;
    t: number;
  }[] = [];

  constructor(start: Vector2D, end: Vector2D, cloning: boolean = false) {
    this.start = start;
    this.end = end;
    if (cloning) {
      this.length = 0;
      this.shoelaceFactor = 0;
      this.shoelaceCentroid = new Vector2D(0, 0);
      this.shoelaceSecondMomentOfArea = 0;
    } else {
      this.length = this.end.subtract(this.start).magnitude();

      this.shoelaceFactor =
        this.start.x * this.end.y - this.end.x * this.start.y;

      // Calculate moment of inertia contribution directly for Izz
      // Izz = integral of (x² + y²)dA
      this.shoelaceSecondMomentOfArea =
        (this.start.x * this.start.x +
          this.start.x * this.end.x +
          this.end.x * this.end.x + // x² terms
          this.start.y * this.start.y +
          this.start.y * this.end.y +
          this.end.y * this.end.y) * // y² terms
        this.shoelaceFactor;

      this.shoelaceCentroid = new Vector2D(
        (this.start.x + this.end.x) * this.shoelaceFactor,
        (this.start.y + this.end.y) * this.shoelaceFactor
      );
    }
  }

  getData(transform?: Transform): LineData {
    if (!transform) {
      return {
        type: SegmentType.LINE,
        start: this.start,
        end: this.end,
      };
    }

    return {
      type: SegmentType.LINE,
      start: transform.apply(this.start),
      end: transform.apply(this.end),
    };
  }

  getAABB(): AABB {
    return {
      left: Math.min(this.start.x, this.end.x),
      right: Math.max(this.start.x, this.end.x),
      top: Math.min(this.start.y, this.end.y),
      bottom: Math.max(this.start.y, this.end.y),
    };
  }

  static fromSerialized(serialized: SerializedLineSegment): LineSegment {
    return new LineSegment(
      new Vector2D(serialized.start[0], serialized.start[1]),
      new Vector2D(serialized.end[0], serialized.end[1])
    );
  }

  rayIntersectionCount(point: Vector2D): number {
    const startY = this.start.y;
    const endY = this.end.y;
    const startX = this.start.x;
    const endX = this.end.x;

    // Check if the line segment is horizontal
    if (Math.abs(startY - endY) < Number.EPSILON) {
      return 0; // Horizontal lines don't count as intersections
    }

    // Check if the point's y is within the line segment's y range
    if (
      (point.y < startY && point.y < endY) ||
      (point.y > startY && point.y > endY)
    ) {
      return 0;
    }

    // Calculate the x-coordinate of the intersection point
    const t = (point.y - startY) / (endY - startY);
    const intersectionX = startX + t * (endX - startX);

    // Check if the intersection point is to the right of the test point
    if (intersectionX > point.x) {
      // Special handling for when the ray passes through a vertex
      if (Math.abs(point.y - startY) < Number.EPSILON) {
        return endY > startY ? 1 : 0;
      }
      if (Math.abs(point.y - endY) < Number.EPSILON) {
        return endY > startY ? 1 : 0;
      }
      return 1;
    }

    return 0;
  }

  globalClone(transform: Transform, invert: boolean): LineSegment {
    const p1 = transform.apply(this.start);
    const p2 = transform.apply(this.end);

    if (!invert) {
      const segment = new LineSegment(p1, p2, true);
      segment.length = this.length;
      segment.shoelaceFactor = this.shoelaceFactor;
      segment.shoelaceCentroid = this.shoelaceCentroid.clone();
      segment.shoelaceSecondMomentOfArea = this.shoelaceSecondMomentOfArea;
      segment.storedPoints = [...this.storedPoints];

      return segment;
    } else {
      const segment = new LineSegment(p2, p1, true);
      segment.length = this.length;
      segment.shoelaceFactor = -this.shoelaceFactor;
      segment.shoelaceCentroid = this.shoelaceCentroid.negated();
      segment.shoelaceSecondMomentOfArea = -this.shoelaceSecondMomentOfArea;
      segment.storedPoints = this.storedPoints.map((p) => ({
        surfacePoint: p.surfacePoint,
        t: 1 - p.t,
      }));

      return segment;
    }
  }

  globalPartialClone(
    transform: Transform,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): LineSegment {
    const [[t1, t2], [start, end]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const tDiff = t2 - t1;

    if (!invert) {
      const segment = new LineSegment(
        start ?? transform.apply(this.start),
        end ?? transform.apply(this.end),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          surfacePoint: p.surfacePoint,
          t: (p.t - t1) / tDiff,
        }));
      return segment;
    } else {
      const segment = new LineSegment(
        end ?? transform.apply(this.end),
        start ?? transform.apply(this.start),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          surfacePoint: p.surfacePoint,
          t: 1 - (p.t - t1) / tDiff,
        }));
      return segment;
    }
  }

  partialClone(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): LineSegment {
    const [[t1, t2], [start, end]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const tDiff = t2 - t1;

    if (!invert) {
      const segment = new LineSegment(
        start ?? this.start.clone(),
        end ?? this.end.clone(),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          surfacePoint: p.surfacePoint,
          t: (p.t - t1) / tDiff,
        }));
      return segment;
    } else {
      const segment = new LineSegment(
        end ?? this.end.clone(),
        start ?? this.start.clone(),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          surfacePoint: p.surfacePoint,
          t: 1 - (p.t - t1) / tDiff,
        }));
      return segment;
    }
  }

  clone(invert: boolean): LineSegment {
    const p1 = this.start.clone();
    const p2 = this.end.clone();

    if (!invert) {
      const segment = new LineSegment(p1, p2, true);
      segment.length = this.length;
      segment.shoelaceFactor = this.shoelaceFactor;
      segment.shoelaceCentroid = this.shoelaceCentroid.clone();
      segment.shoelaceSecondMomentOfArea = this.shoelaceSecondMomentOfArea;
      segment.storedPoints = [...this.storedPoints];
      return segment;
    } else {
      const segment = new LineSegment(p2, p1, true);
      segment.length = this.length;
      segment.shoelaceFactor = -this.shoelaceFactor;
      segment.shoelaceCentroid = this.shoelaceCentroid.negated();
      segment.shoelaceSecondMomentOfArea = -this.shoelaceSecondMomentOfArea;
      segment.storedPoints = this.storedPoints.map((p) => ({
        surfacePoint: p.surfacePoint,
        t: 1 - p.t,
      }));
      return segment;
    }
  }

  translate(dx: number, dy: number) {
    this.start.x += dx;
    this.start.y += dy;
    this.end.x += dx;
    this.end.y += dy;

    this.shoelaceFactor = this.start.x * this.end.y - this.end.x * this.start.y;

    // Calculate moment of inertia contribution directly for Izz
    // Izz = integral of (x² + y²)dA
    this.shoelaceSecondMomentOfArea =
      (this.start.x * this.start.x +
        this.start.x * this.end.x +
        this.end.x * this.end.x + // x² terms
        this.start.y * this.start.y +
        this.start.y * this.end.y +
        this.end.y * this.end.y) * // y² terms
      this.shoelaceFactor;

    this.shoelaceCentroid = new Vector2D(
      (this.start.x + this.end.x) * this.shoelaceFactor,
      (this.start.y + this.end.y) * this.shoelaceFactor
    );
  }

  getAllContributions(): SegmentContributions {
    const area = (this.start.x * this.end.y - this.end.x * this.start.y) / 2;
    const centroid = new Vector2D(
      (this.start.x + this.end.x) / 3,
      (this.start.y + this.end.y) / 3
    );

    // Second moment of area about origin
    const secondMomentAboutOrigin =
      (area / 6) *
      (this.start.x * this.start.x +
        this.start.y * this.start.y + // (x₁² + y₁²)
        (this.start.x * this.end.x + this.start.y * this.end.y) + // (x₁x₂ + y₁y₂)
        (this.end.x * this.end.x + this.end.y * this.end.y)); // (x₂² + y₂²)

    // Use parallel axis theorem to get moment about centroid
    const secondMomentOfArea =
      secondMomentAboutOrigin -
      area * (centroid.x * centroid.x + centroid.y * centroid.y);

    return {
      area,
      centroid,
      secondMomentOfArea,
    };
  }

  getCollisionContributions(transform?: Transform): CollisionContributions {
    const start = transform?.apply(this.start) ?? this.start;
    const end = transform?.apply(this.end) ?? this.end;

    const area = (start.x * end.y - end.x * start.y) / 2;
    const centroid = new Vector2D((start.x + end.x) / 3, (start.y + end.y) / 3);

    return {
      area,
      centroid,
      points: [start, end],
    };
  }

  getPartialCollisionContributions(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    transform?: Transform
  ): CollisionContributions {
    const [[t1, t2], [startPoint, endPoint]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const start =
      startPoint ?? transform?.apply(this.start) ?? this.start.clone();
    const end = endPoint ?? transform?.apply(this.end) ?? this.end.clone();

    const area = (start.x * end.y - end.x * start.y) / 2;
    const centroid = new Vector2D((start.x + end.x) / 3, (start.y + end.y) / 3);

    return {
      area,
      centroid,
      points: [start, end],
    };
  }

  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    transform?: Transform
  ): LineIntersection[] {
    const start = transform?.apply(this.start) ?? this.start;
    const end = transform?.apply(this.end) ?? this.end;

    const denominator =
      (lineEnd.y - lineStart.y) * (end.x - start.x) -
      (lineEnd.x - lineStart.x) * (end.y - start.y);

    if (Math.abs(denominator) < 1e-10) {
      return []; // Lines are parallel
    }

    const ua =
      ((lineEnd.x - lineStart.x) * (start.y - lineStart.y) -
        (lineEnd.y - lineStart.y) * (start.x - lineStart.x)) /
      denominator;

    const ub =
      ((end.x - start.x) * (start.y - lineStart.y) -
        (end.y - start.y) * (start.x - lineStart.x)) /
      denominator;

    // Check if the intersection is within the line segment
    if (ua < 0 || ua > 1) {
      return [];
    }

    const point = new Vector2D(
      start.x + ua * (end.x - start.x),
      start.y + ua * (end.y - start.y)
    );

    if (!point.isValid()) {
      return [];
    }

    return [
      {
        point,
        t: ub,
        segment: this,
      },
    ];
  }

  serialize(): SerializedLineSegment {
    return {
      type: SegmentType.LINE,
      start: [this.start.x, this.start.y],
      end: [this.end.x, this.end.y],
    };
  }

  intersectsAABB(bounds: AABB): boolean {
    // Using Cohen-Sutherland algorithm for line-rectangle intersection
    const INSIDE = 0; // 0000
    const LEFT = 1; // 0001
    const RIGHT = 2; // 0010
    const BOTTOM = 4; // 0100
    const TOP = 8; // 1000

    // Calculate outcodes for both endpoints
    const computeOutCode = (x: number, y: number): number => {
      let code = INSIDE;
      if (x < bounds.left) code |= LEFT;
      else if (x > bounds.right) code |= RIGHT;
      if (y < bounds.top) code |= TOP;
      else if (y > bounds.bottom) code |= BOTTOM;
      return code;
    };

    let outcode0 = computeOutCode(this.start.x, this.start.y);
    let outcode1 = computeOutCode(this.end.x, this.end.y);

    // Early return if both points are on the same side of any boundary
    if ((outcode0 & outcode1) !== 0) {
      return false;
    }

    // If both points are inside, line must intersect
    if (outcode0 === 0 && outcode1 === 0) {
      return true;
    }

    // At this point, line might intersect. At least one endpoint is outside.
    return true;
  }

  intersectsCircle(circle: Circle): boolean {
    // Transform to make calculations relative to line segment starting at origin
    const dx = this.end.x - this.start.x;
    const dy = this.end.y - this.start.y;

    // Translate circle center to be relative to line segment start
    const cx = circle.x - this.start.x;
    const cy = circle.y - this.start.y;

    // Calculate length of line segment squared
    const lengthSquared = dx * dx + dy * dy;

    // If length is zero, just check distance to endpoint
    if (lengthSquared === 0) {
      return cx * cx + cy * cy <= circle.radius * circle.radius;
    }

    // Calculate projection of circle center onto line segment
    const t = Math.max(0, Math.min(1, (cx * dx + cy * dy) / lengthSquared));

    // Calculate closest point on line segment to circle center
    const projectionX = t * dx;
    const projectionY = t * dy;

    // Calculate distance squared from circle center to closest point
    const distanceSquared =
      (cx - projectionX) * (cx - projectionX) +
      (cy - projectionY) * (cy - projectionY);

    // Compare with circle radius squared
    return distanceSquared <= circle.radius * circle.radius;
  }

  getPointAtDistance(distance: number): Vector2D | number {
    // If the line has zero length, return the start point for distance 0
    // or the full distance as remaining for any other distance
    if (this.length === 0) {
      return distance === 0 ? this.start : distance;
    }

    // If distance is greater than line length, return remaining distance
    if (distance > this.length) {
      return distance - this.length;
    }

    // Calculate the point using linear interpolation
    const t = distance / this.length;
    return new Vector2D(
      this.start.x + t * (this.end.x - this.start.x),
      this.start.y + t * (this.end.y - this.start.y)
    );
  }

  getClosestPoint(point: Vector2D): {
    point: Vector2D;
    tOrAngle: number;
  } {
    const ab = this.end.subtract(this.start);
    const ap = point.subtract(this.start);

    const abDotAb = ab.dot(ab);
    if (abDotAb === 0) {
      // The segment is a point
      return {
        point: this.start.clone(),
        tOrAngle: 0,
      };
    }

    // Calculate the projection scalar 't'
    let t = ap.dot(ab) / abDotAb;

    // Clamp 't' to the [0, 1] range to stay within the segment
    t = Math.max(0, Math.min(1, t));

    // Calculate the closest point Q = A + t * AB
    const closestPoint = this.start.add(ab.scale(t));

    return {
      point: closestPoint,
      tOrAngle: t,
    };
  }

  storePoint(surfacePoint: SurfacePoint, tOrAngle: number): void {
    const clampedTOrAngle = Math.max(0, Math.min(1, tOrAngle));

    this.storedPoints.push({
      surfacePoint,
      t: clampedTOrAngle,
    });
  }

  getPointAtT(t: number): Vector2D | null {
    if (t < 0 || t > 1) return null;

    return new Vector2D(
      this.start.x + t * (this.end.x - this.start.x),
      this.start.y + t * (this.end.y - this.start.y)
    );
  }

  getStoredPoints(): SurfacePoint[] {
    return this.storedPoints.map(({ surfacePoint }) => surfacePoint);
  }
}
