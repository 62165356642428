import { Vector2D } from "../math/vector2D.ts";
import type { PIDParams, SerializedActuator } from "../models.ts";
import type { Color } from "../rendering/color.ts";
import type { Body } from "./body.ts";

export class Actuator {
  id: string;
  base: Body;
  endEffector: Body;

  // Maximum force that can be applied
  maxForce: number;

  // Proportional gain: Immediate response to position error
  // Higher = stronger immediate response, but can cause oscillation
  // Lower = softer response, but may be sluggish
  kp: number;

  // Integral gain: Accumulates error over time to eliminate steady-state error
  // Higher = faster elimination of steady-state error, but can cause overshoot
  // Lower = slower correction, but more stable
  ki: number;

  // Derivative gain: Provides damping based on rate of error change
  // Higher = more damping/stability, but can make response sluggish
  // Lower = faster response, but can lead to oscillation
  kd: number;

  // Maximum accumulated integral term to prevent excessive buildup
  // Higher = allows more accumulated correction, but can cause overshoot
  // Lower = limits correction, but prevents large overshoots
  windupLimit: number;

  lastError?: Vector2D;
  integral: Vector2D;
  targetPos: Vector2D;
  maxLength: number;
  color: Color;

  constructor(
    id: string,
    base: Body,
    endEffector: Body,
    pidParams: PIDParams,
    maxLength: number,
    color: Color
  ) {
    this.id = id;
    this.base = base;
    this.endEffector = endEffector;

    this.maxForce = 500000000; // Maximum force that can be applied, prevents extreme reactions
    this.kp = pidParams.kp;
    this.ki = pidParams.ki;
    this.kd = pidParams.kd;
    this.windupLimit = pidParams.windupLimit;
    this.maxLength = maxLength;
    this.integral = new Vector2D(0, 0);
    this.targetPos = new Vector2D(0, 0);
    this.color = color;
  }

  serialize(): SerializedActuator {
    return {
      id: this.id,
      baseId: this.base.id,
      endEffectorId: this.endEffector.id,
      pidParams: {
        maxForce: this.maxForce,
        kp: this.kp,
        ki: this.ki,
        kd: this.kd,
        windupLimit: this.windupLimit,
      },
    };
  }

  updateTargetPos(pos: Vector2D): void {
    this.targetPos = pos;
  }

  private calculatePIDForce(params: {
    dt: number;
    targetPos: Vector2D;
    mass: number;
  }): Vector2D | null {
    const { dt, targetPos, mass } = params;

    // Get current state for PID calculation
    const currentPos = this.endEffector.getCenter();
    const error = targetPos.subtract(currentPos);

    // Calculate PID forces
    const proportionalForce = error.scale(this.kp);
    if (!proportionalForce.isValid()) {
      console.warn("Invalid proportional force calculated");
      return null;
    }

    // Integral term
    const integralDelta = error.scale(dt * this.ki);
    if (integralDelta.isValid()) {
      this.integral = this.integral.add(integralDelta);
      const integralMagnitude = this.integral.magnitude();
      if (integralMagnitude > this.windupLimit) {
        this.integral = this.integral.normalize().scale(this.windupLimit);
      }
    }

    // Derivative term (damping)
    let derivativeTerm = new Vector2D(0, 0);
    if (this.lastError) {
      const errorDelta = error.subtract(this.lastError);
      const derivativeScale = this.kd / dt;
      if (isFinite(derivativeScale)) {
        derivativeTerm = errorDelta.scale(derivativeScale);
      }
    }
    this.lastError = error;

    // Calculate total force
    const force = proportionalForce
      .add(this.integral)
      .add(derivativeTerm)
      .scale(Math.sqrt(mass));
    if (!force.isValid()) {
      console.warn("Invalid force calculated");
      return null;
    }

    // Just apply max force limit without extension scaling
    const forceMagnitude = force.magnitude();
    if (forceMagnitude > this.maxForce) {
      return force.normalize().scale(this.maxForce);
    }

    return force;
  }

  private applyForcesToBodies(force: Vector2D): void {
    // Apply linear forces and torques
    const baseForce = force.scale(-1);
    this.base.applyForce(baseForce, undefined);
    this.endEffector.applyForce(force, undefined);
  }

  apply(dt: number): void {
    const pidForce = this.calculatePIDForce({
      dt,
      targetPos: this.targetPos,
      mass: this.endEffector.getMass(),
    });

    if (pidForce?.isValid()) {
      this.applyForcesToBodies(pidForce);
    }
  }

  render(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    const basePos = this.base.getCenter();
    const endPos = this.endEffector.getCenter();
    const segmentLength = this.maxLength / 2; // Each segment is half of maxLength

    // Calculate the elbow point
    const totalDistance = basePos.subtract(endPos).magnitude();
    let elbowPoint: Vector2D;

    if (totalDistance >= this.maxLength) {
      // If distance is too large, create a straight line configuration
      const direction = endPos.subtract(basePos).normalize();
      elbowPoint = basePos.add(direction.scale(segmentLength));
    } else {
      // Calculate elbow point using triangulation
      const midPoint = basePos.add(endPos).scale(0.5);
      const halfDistance = totalDistance / 2;

      // Height of the triangle formed by the elbow
      const triangleHeight = Math.sqrt(
        segmentLength * segmentLength - halfDistance * halfDistance
      );

      // Vector perpendicular to the base-end line
      const perpVector = new Vector2D(
        -(endPos.y - basePos.y),
        endPos.x - basePos.x
      ).normalize();

      // Calculate elbow point by moving perpendicular from midpoint
      elbowPoint = midPoint.add(perpVector.scale(triangleHeight));
    }

    // Draw the segments
    ctx.beginPath();
    ctx.strokeStyle = this.color.toRGB();
    ctx.lineWidth = 20;

    // Draw base to elbow
    ctx.moveTo(basePos.x, basePos.y);
    ctx.lineTo(elbowPoint.x, elbowPoint.y);

    // Draw elbow to end effector
    ctx.lineTo(endPos.x, endPos.y);

    ctx.stroke();
  }
}
