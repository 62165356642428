import { Vector2D } from "../math/vector2D";
import { BodyQuadtree, type BodyQuadtreeEntry } from "./bodyQuadtree";
import { Composite } from "./composite";
import type { Body } from "./body";
import {
  CollisionPairKey,
  EnclosureType,
  type BodyState,
  type Circle,
  type CollisionPair,
  type IntersectionPoint,
  type PointOnBodySurface,
} from "../models";
import { VertexShapeBuilder } from "../shapes/builders/vertexShapeBuilder";
import type { Shape } from "../shapes/shape";
import { BodyDataStride, BodyOffset } from "./plane";
import type { Camera } from "./camera";
import { findShapeIntersections } from "../geometry/intersection";
import { Transform } from "../math/transform";
import { intersectSegmentTrees } from "../shapes/intersectSegmentTrees";
import type { SurfacePoint } from "./surfacePoint";

export class BodyStore {
  count: number = 0;
  bodyStates: Float32Array;
  nextIndex: number = 0;
  bodies: (Body | undefined)[] = [];
  freeIndices: number[] = [];
  compositeBodies: Composite[] = [];
  private ignoredCollisionPairs: Set<string> = new Set();
  gravity: number = 0;
  velocityDamping: number = 1;
  angularVelocityDamping: number = 1;

  // Will be updated on first frame
  private worldBounds: { min: Vector2D; max: Vector2D };

  // Will be updated on first frame
  quadtree: BodyQuadtree;

  addBody: (body: Body) => void;
  removeBody: (id: string) => void;

  constructor({
    bodyStates,
    addBody,
    removeBody,
    initialWorldBounds,
  }: {
    bodyStates: Float32Array;
    addBody: (body: Body) => void;
    removeBody: (id: string) => void;
    initialWorldBounds: { min: Vector2D; max: Vector2D };
  }) {
    this.bodyStates = bodyStates;
    this.addBody = addBody;
    this.removeBody = removeBody;
    this.worldBounds = initialWorldBounds;
    this.quadtree = new BodyQuadtree({
      left: initialWorldBounds.min.x,
      top: initialWorldBounds.min.y,
      right: initialWorldBounds.max.x,
      bottom: initialWorldBounds.max.y,
    });
  }

  getBody(index: number): Body | undefined {
    return this.bodies[index];
  }

  loadBody({ body, state }: { body: Body; state: BodyState }): number {
    // Use a free index if available, otherwise use the current count
    const index =
      this.freeIndices.length > 0 ? this.freeIndices.pop()! : this.nextIndex++;

    body.index = index;
    body.strideIndex = index * BodyDataStride;
    body.stateArray = this.bodyStates;

    this.bodies[index] = body;
    this.bodyStates[body.strideIndex + BodyOffset.PositionX] =
      state.position[0];
    this.bodyStates[body.strideIndex + BodyOffset.PositionY] =
      state.position[1];
    this.bodyStates[body.strideIndex + BodyOffset.Rotation] = state.position[2];
    const cos = Math.cos(state.position[2]);
    const sin = Math.sin(state.position[2]);
    this.bodyStates[body.strideIndex + BodyOffset.CosRotation] = cos;
    this.bodyStates[body.strideIndex + BodyOffset.SinRotation] = sin;
    this.bodyStates[body.strideIndex + BodyOffset.VelocityX] =
      state.velocity[0];
    this.bodyStates[body.strideIndex + BodyOffset.VelocityY] =
      state.velocity[1];
    this.bodyStates[body.strideIndex + BodyOffset.AngularVelocity] =
      state.velocity[2];
    this.bodyStates[body.strideIndex + BodyOffset.AccelerationX] = 0;
    this.bodyStates[body.strideIndex + BodyOffset.AccelerationY] = 0;
    this.bodyStates[body.strideIndex + BodyOffset.AngularAcceleration] = 0;

    const centroid = body.shape.centroid;
    const mec = body.shape.mec.center;

    this.bodyStates[body.strideIndex + BodyOffset.LocalCentroidX] = centroid.x;
    this.bodyStates[body.strideIndex + BodyOffset.LocalCentroidY] = centroid.y;

    this.bodyStates[body.strideIndex + BodyOffset.LocalMecPositionX] = mec.x;
    this.bodyStates[body.strideIndex + BodyOffset.LocalMecPositionY] = mec.y;

    const transformOffsetX =
      -centroid.x * cos - -centroid.y * sin + state.position[0];
    const transformOffsetY =
      -centroid.x * sin + -centroid.y * cos + state.position[1];

    this.bodyStates[body.strideIndex + BodyOffset.TransformOffsetX] =
      transformOffsetX;
    this.bodyStates[body.strideIndex + BodyOffset.TransformOffsetY] =
      transformOffsetY;

    this.bodyStates[body.strideIndex + BodyOffset.MecPositionX] =
      mec.x * cos - mec.y * sin + transformOffsetX;
    this.bodyStates[body.strideIndex + BodyOffset.MecPositionY] =
      mec.x * sin + mec.y * cos + transformOffsetY;
    this.bodyStates[body.strideIndex + BodyOffset.MecRadius] =
      body.shape.mec.radius;

    this.count++;

    return index;
  }

  unloadBody(index: number): void {
    if (index < 0 || index >= this.nextIndex) {
      console.warn(`Invalid body index: ${index}`);
      return;
    }

    const body = this.bodies[index];

    if (!body) {
      console.error("Body not found");
      return;
    }

    body.removeFromComposite();

    // Clear the body data in the Float32Array
    const stride = index * BodyDataStride;
    for (let i = 0; i < BodyDataStride; i++) {
      this.bodyStates[stride + i] = 0;
    }

    // // Remove from quadtree
    // this.quadtree?.remove(index);

    body.index = -1;
    body.strideIndex = -1;
    body.stateArray = null;

    this.bodies[index] = undefined;

    // Add the index to the free list
    this.freeIndices.push(index);

    // Remove any ignored collision pairs involving this body
    this.cleanupIgnoredCollisionPairs(index);
    this.count--;
  }

  update(dt: number): void {
    this.updateCompositeBodies(dt);
    this.updateBodies(dt);
  }

  updateBodies(dt: number): void {
    // Update velocities and positions with dt
    for (let i = 0; i < this.nextIndex; i++) {
      const body = this.getBody(i);
      if (!body) {
        continue;
      }

      if (body.composite) {
        continue;
      }

      const stride = i * BodyDataStride;

      if (!body.positionLocked) {
        this.bodyStates[stride + BodyOffset.VelocityX] +=
          this.bodyStates[stride + BodyOffset.AccelerationX] * dt;
        this.bodyStates[stride + BodyOffset.VelocityY] +=
          this.bodyStates[stride + BodyOffset.AccelerationY] * dt;
        this.bodyStates[stride + BodyOffset.VelocityX] *= this.velocityDamping;
        this.bodyStates[stride + BodyOffset.VelocityY] *= this.velocityDamping;
        this.bodyStates[stride + BodyOffset.PositionX] +=
          this.bodyStates[stride + BodyOffset.VelocityX] * dt;
        this.bodyStates[stride + BodyOffset.PositionY] +=
          this.bodyStates[stride + BodyOffset.VelocityY] * dt;
      }
      if (!body.rotationLocked) {
        this.bodyStates[stride + BodyOffset.AngularVelocity] +=
          this.bodyStates[stride + BodyOffset.AngularAcceleration] * dt;
        this.bodyStates[stride + BodyOffset.AngularVelocity] *=
          this.angularVelocityDamping;
        this.bodyStates[stride + BodyOffset.Rotation] +=
          this.bodyStates[stride + BodyOffset.AngularVelocity] * dt;
        this.bodyStates[stride + BodyOffset.CosRotation] = Math.cos(
          this.bodyStates[stride + BodyOffset.Rotation]
        );
        this.bodyStates[stride + BodyOffset.SinRotation] = Math.sin(
          this.bodyStates[stride + BodyOffset.Rotation]
        );
      }
      const cos = this.bodyStates[stride + BodyOffset.CosRotation];
      const sin = this.bodyStates[stride + BodyOffset.SinRotation];

      const localCentroidX =
        this.bodyStates[stride + BodyOffset.LocalCentroidX];
      const localCentroidY =
        this.bodyStates[stride + BodyOffset.LocalCentroidY];

      const localMecX = this.bodyStates[stride + BodyOffset.LocalMecPositionX];
      const localMecY = this.bodyStates[stride + BodyOffset.LocalMecPositionY];

      const transformOffsetX =
        -localCentroidX * cos -
        -localCentroidY * sin +
        this.bodyStates[stride + BodyOffset.PositionX];
      const transformOffsetY =
        -localCentroidX * sin +
        -localCentroidY * cos +
        this.bodyStates[stride + BodyOffset.PositionY];

      this.bodyStates[body.strideIndex + BodyOffset.TransformOffsetX] =
        transformOffsetX;
      this.bodyStates[body.strideIndex + BodyOffset.TransformOffsetY] =
        transformOffsetY;

      // Update MEC position with proper rotation
      this.bodyStates[body.strideIndex + BodyOffset.MecPositionX] =
        localMecX * cos - localMecY * sin + transformOffsetX;
      this.bodyStates[body.strideIndex + BodyOffset.MecPositionY] =
        localMecX * sin + localMecY * cos + transformOffsetY;

      this.bodyStates[stride + BodyOffset.AccelerationX] = 0;
      this.bodyStates[stride + BodyOffset.AccelerationY] = this.gravity;
      this.bodyStates[stride + BodyOffset.AngularAcceleration] = 0;
    }
  }

  updateQuadtree(): void {
    // First, update world bounds if needed
    this.updateWorldBounds(Array.from({ length: this.nextIndex }, (_, i) => i));

    this.quadtree = new BodyQuadtree({
      left: this.worldBounds.min.x,
      right: this.worldBounds.max.x,
      top: this.worldBounds.min.y,
      bottom: this.worldBounds.max.y,
    });

    // Insert all MECs into quadtree
    for (let i = 0; i < this.nextIndex; i++) {
      const mecX =
        this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionX];
      const mecY =
        this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionY];

      const body = this.bodies[i];
      if (!body) {
        continue;
      }

      this.quadtree.insert({
        body,
        circle: {
          x: mecX,
          y: mecY,
          radius: this.bodyStates[i * BodyDataStride + BodyOffset.MecRadius],
        },
        index: i,
      });
    }
  }

  updateWorldBounds(bodyStates: number[]): void {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const i of bodyStates) {
      const x = this.bodyStates[i * BodyDataStride + BodyOffset.PositionX];
      const y = this.bodyStates[i * BodyDataStride + BodyOffset.PositionY];
      const radius = this.bodyStates[i * BodyDataStride + BodyOffset.MecRadius];

      minX = Math.min(minX, x - radius);
      minY = Math.min(minY, y - radius);
      maxX = Math.max(maxX, x + radius);
      maxY = Math.max(maxY, y + radius);
    }

    // Add padding to bounds
    const padding = 100; // Adjust as needed
    this.worldBounds = {
      min: new Vector2D(minX - padding, minY - padding),
      max: new Vector2D(maxX + padding, maxY + padding),
    };
  }

  // Add this helper method to clean up collision pairs
  private cleanupIgnoredCollisionPairs(bodyIndex: number): void {
    // Create a new Set to store pairs we want to keep
    const updatedPairs = new Set<string>();

    // Only keep pairs that don't involve the removed body
    for (const pair of this.ignoredCollisionPairs) {
      const [body1, body2] = pair.split(",").map(Number);
      if (body1 !== bodyIndex && body2 !== bodyIndex) {
        updatedPairs.add(pair);
      }
    }

    this.ignoredCollisionPairs = updatedPairs;
  }

  updateCompositeBodies(dt: number): void {
    for (const composite of this.compositeBodies) {
      composite.update(dt, this.gravity);
    }
  }

  composeBodies(body1: Body, body2: Body): void {
    if (!body1.isLoaded() || !body2.isLoaded()) {
      return;
    }

    const composite1 = body1.composite;
    const composite2 = body2.composite;

    // Case 1 & 2: Handle cases where at least one shape isn't in a composite
    if (!composite1 || !composite2 || composite1 === composite2) {
      if (!composite1 && !composite2) {
        const destroy = () => {
          this.compositeBodies = this.compositeBodies.filter(
            (c) => c !== newComposite
          );
        };

        const newComposite = new Composite(body1, destroy);
        this.compositeBodies.push(newComposite);
        body1.composite = newComposite;

        newComposite.addBody(body2);
        return;
      } else if (composite1 && !composite2) {
        composite1.addBody(body2);
        return;
      } else if (!composite1 && composite2) {
        composite2.addBody(body1);
        return;
      } else {
        throw new Error("Should not happen");
      }
    }

    // Case 3: Handle merging two composites
    // Put all the indices of composite2 into an array for later
    const composite2Indices = Array.from(composite2.bodies.values()).map(
      (b) => b.body.index
    );

    composite1.mergeComposite(composite2);
    this.compositeBodies = this.compositeBodies.filter((c) => c !== composite2);
    for (const index of composite2Indices) {
      const body = this.getBody(index);
      if (body) {
        body.composite = composite1;
      }
    }
  }

  cutCircle(position: Vector2D, radius: number, target: Body): Body[] {
    const cutShape = VertexShapeBuilder.circle(radius);

    if (!target.material.destructible) {
      return [];
    }

    return this.cut(target, cutShape, position);
  }

  cutSquare(position: Vector2D, radius: number, targetIndex: number): Body[] {
    const cutShape = VertexShapeBuilder.square(radius);

    const target = this.getBody(targetIndex);
    if (!target) {
      return [];
    }

    if (!target.material.destructible) {
      return [];
    }

    return this.cut(target, cutShape, position);
  }

  cut(target: Body, cutter: Shape, cutterPosition: Vector2D): Body[] {
    const newBodies = target.cut(cutter, cutterPosition);

    if (newBodies === null) {
      return [];
    }

    const surfacePointsBeforeCut = target.pointsOnSurface;

    const composite = target.removeFromComposite();
    this.unloadBody(target.index);
    this.removeBody(target.id);

    const addBackToComposite = composite && composite.bodyInSubplane;

    const allRemainingSurfacePoints = new Set<SurfacePoint>();

    for (const body of newBodies) {
      this.addBody(body.body);
      this.loadBody(body);

      for (const surfacePoint of body.surfacePoints) {
        surfacePoint.transfer(body.body);

        body.body.addSurfacePoint(surfacePoint);
        allRemainingSurfacePoints.add(surfacePoint);
      }

      if (addBackToComposite && body.body.shape.area > 100000) {
        composite.addBody(body.body);
      }

      body.body.textureInfo = target.getTextureInfo();
    }

    if (surfacePointsBeforeCut) {
      for (const surfacePoint of surfacePointsBeforeCut) {
        if (!allRemainingSurfacePoints.has(surfacePoint)) {
          surfacePoint.destroy();
        }
      }
    }

    return newBodies.map((b) => b.body);
  }

  addIgnoredCollisionPair(body1: number, body2: number): void {
    const key = new CollisionPairKey(body1, body2).toString();
    this.ignoredCollisionPairs.add(key);
  }

  removeIgnoredCollisionPair(body1: number, body2: number): void {
    const key = new CollisionPairKey(body1, body2).toString();
    this.ignoredCollisionPairs.delete(key);
  }

  shouldCheckCollision = (body1: number, body2: number): boolean => {
    const key = new CollisionPairKey(body1, body2).toString();
    return !this.ignoredCollisionPairs.has(key);
  };

  bodiesIntersect(body1: Body, body2: Body) {
    // Step 1, mecs
    const quadtreeQueryResult = this.quadtree.queryCircle(body1.getMec());

    if (quadtreeQueryResult === undefined) {
      return false;
    }

    let found = false;
    quadtreeQueryResult.forEach((entry: BodyQuadtreeEntry) => {
      if (entry.body.index === body2.index) {
        found = true;
      }
    });

    if (!found) {
      return false;
    }

    const body1Transform = body1.getTransform();
    const body2Transform = body2.getTransform();

    const segmentPairs = intersectSegmentTrees(
      body1.shape.getSegmentTree(),
      body2.shape.getSegmentTree(),
      Transform.getRelativeTransformInfo({
        guest: body1Transform,
        host: body2Transform,
      }).guestToHost,
      Transform.getRelativeTransformInfo({
        guest: body2Transform,
        host: body1Transform,
      }).guestToHost
    );

    let result: {
      intersections: [IntersectionPoint[], IntersectionPoint[]] | null;
      segmentCheckCount: number;
      segmentIntersectionCount: number;
    } | null = null;

    try {
      // Get intersection points
      result = findShapeIntersections(
        segmentPairs,
        Transform.getRelativeTransformInfo({
          guest: body1Transform,
          host: body2Transform,
        }).guestToHost
      );
    } catch (e) {
      return false;
    }

    if (result.intersections === null) {
      return false;
    }

    return true;
  }

  getBodiesVisibleToCamera(
    camera: Camera,
    margin: number = 0
  ): {
    mainPlaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
    subplaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
  } {
    const viewBounds = camera.getWorldBounds(margin);
    const screenViewSize = Math.min(camera.width, camera.height);
    const minFullDetailRadius = screenViewSize * camera.fullDetailThreshold;
    const minLODRadius = screenViewSize * camera.lodThreshold;

    const mainPlaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[] = [];

    const subplaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[] = [];

    // Check every body
    for (let i = 0; i < this.nextIndex; i++) {
      const body = this.getBody(i);
      if (!body) continue;

      const mec = {
        x: this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionX],
        y: this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionY],
        radius: this.bodyStates[i * BodyDataStride + BodyOffset.MecRadius],
        index: i,
      };

      // Check if circle intersects with view bounds
      const circleInView =
        mec.x + mec.radius >= viewBounds.left &&
        mec.x - mec.radius <= viewBounds.right &&
        mec.y + mec.radius >= viewBounds.top &&
        mec.y - mec.radius <= viewBounds.bottom;

      if (circleInView) {
        const screenRadius = mec.radius * camera.zoom;
        let detailed = false;

        if (screenRadius >= minFullDetailRadius) {
          detailed = true;
        } else if (screenRadius >= minLODRadius) {
          detailed = false;
        }

        if (body.inSubplane) {
          subplaneBodies.push({
            body,
            mec,
            detailed,
          });
        } else {
          mainPlaneBodies.push({
            body,
            mec,
            detailed,
          });
        }
      }
    }

    return {
      mainPlaneBodies,
      subplaneBodies,
    };
  }

  getClosestPointOnBody(
    queryCenter: Vector2D,
    queryRadius: number,
    ignoreBodies: Body[] = [],
    eligibilityCheck: (body: Body) => boolean = () => true,
    includeSubplane: boolean = false
  ): {
    distance: number;
    pointOnSurface: PointOnBodySurface;
  } | null {
    // Step 1: Define the search circle in global coordinates
    const searchCircle: Circle = {
      x: queryCenter.x,
      y: queryCenter.y,
      radius: queryRadius,
    };

    // Step 2: Query the quadtree for potential bodies within the search circle
    const results = this.quadtree.queryCircle(searchCircle);

    // If no bodies are found, return early
    if (results.length === 0) {
      return null;
    }

    let closestResult: {
      distance: number;
      pointOnSurface: PointOnBodySurface;
    } | null = null;
    let minDistance = queryRadius;

    // Step 3: Iterate through each candidate body
    for (const { body } of results) {
      if (ignoreBodies.includes(body)) {
        continue;
      }

      if (!includeSubplane && body.inSubplane) {
        continue;
      }

      if (!eligibilityCheck(body)) continue;

      // Step 4: Get the closest point on the body to the global point
      const closestOnBody = body.getClosestPerimeterPoint(
        queryCenter,
        queryRadius
      );

      // Skip if no valid closest point is found within the query radius
      if (!closestOnBody) continue;

      // If this distance is smaller than the current minimum, update closestPoint
      if (closestOnBody.distance < minDistance) {
        minDistance = closestOnBody.distance;
        closestResult = {
          distance: closestOnBody.distance,
          pointOnSurface: {
            body,
            globalPosition: closestOnBody.globalPosition,
            perimeterPoint: closestOnBody.perimeterPoint,
          },
        };
        // Early termination if the closest possible point is found
        if (closestOnBody.distance === 0) {
          break;
        }
      }
    }

    return closestResult;
  }

  getPotentialCollisions(): CollisionPair[] {
    const potentialCollisions: CollisionPair[] = [];

    const intersectingPairs = this.quadtree.getIntersectingPairs();

    for (const pair of intersectingPairs) {
      const body1 = pair.a.body;
      const body2 = pair.b.body;

      // Skip if bodyStates share a composite
      if (body1.composite && body1.composite === body2.composite) {
        continue;
      }

      if (body1.inSubplane !== body2.inSubplane) {
        continue;
      }

      // Skip if this pair is in the ignored list
      if (!this.shouldCheckCollision(pair.a.index, pair.b.index)) {
        continue;
      }

      const body1Transform = body1.getTransform();
      const body2Transform = body2.getTransform();

      potentialCollisions.push({
        body1: body1,
        body2: body2,
        body1Transform: body1Transform,
        body2Transform: body2Transform,
        relativeTransformInfo: Transform.getRelativeTransformInfo({
          guest: body1Transform,
          host: body2Transform,
        }),
        body1Mec: {
          x: pair.a.circle.x,
          y: pair.a.circle.y,
          radius: pair.a.circle.radius,
        },
        body2Mec: {
          x: pair.b.circle.x,
          y: pair.b.circle.y,
          radius: pair.b.circle.radius,
        },
        segmentPairs: [],
        intersectionPoints: null,
        enclosureType: EnclosureType.None,
      });
    }

    return potentialCollisions;
  }

  cleanupCompositeBodies(): void {
    for (const composite of this.compositeBodies) {
      composite.cleanup();
    }
  }
}
