import type { AABB, Circle } from "../models.ts";
import { Body } from "./body.ts";

export interface BodyQuadtreeEntry {
  index: number;
  body: Body;
  circle: Circle;
}

export interface BodyQuadtreePair {
  a: BodyQuadtreeEntry;
  b: BodyQuadtreeEntry;
}

export class BodyQuadtree {
  bounds: AABB;
  entries: BodyQuadtreeEntry[] = [];
  children: BodyQuadtree[] = [];
  divided: boolean = false;
  private largeObjects: BodyQuadtreeEntry[] = [];
  static MAX_CAPACITY: number = 4;
  static MAX_DEPTH: number = 10;
  static LARGE_OBJECT_RADIUS_THRESHOLD: number = 10000;
  private depth: number;

  constructor(bounds: AABB, depth: number = 0) {
    this.bounds = bounds;
    this.depth = depth;
  }

  /**
   * Inserts an entry into the quadtree.
   * Returns true if the insertion was successful.
   */
  insert(entry: BodyQuadtreeEntry): boolean {
    // If the object is large, store it separately
    if (entry.circle.radius > BodyQuadtree.LARGE_OBJECT_RADIUS_THRESHOLD) {
      this.largeObjects.push(entry);
      return true;
    }

    // If the circle does not intersect this node's bounds, do not insert
    if (!this.circleIntersectsAABB(entry.circle, this.bounds)) {
      return false;
    }

    // If this node is subdivided, insert into all children that intersect with the circle
    if (this.divided) {
      let inserted = false;
      for (let child of this.children) {
        if (child.insert(entry)) {
          inserted = true;
        }
      }
      // Even if inserted into children, also keep in this node if it spans multiple children
      if (!inserted) {
        this.entries.push(entry);
      }
      return inserted;
    }

    // If there's room in this node, add the entry here
    if (
      this.entries.length < BodyQuadtree.MAX_CAPACITY ||
      this.depth >= BodyQuadtree.MAX_DEPTH
    ) {
      this.entries.push(entry);
      return true;
    }

    // Subdivide and redistribute entries
    this.subdivide();

    // After subdivision, re-insert existing entries
    const oldEntries = this.entries.slice();
    this.entries = [];
    for (let oldEntry of oldEntries) {
      this.insert(oldEntry);
    }

    // Finally, insert the new entry
    return this.insert(entry);
  }

  /**
   * Queries the quadtree for entries intersecting a given circle.
   */
  queryCircle(circle: Circle): BodyQuadtreeEntry[] {
    let results: BodyQuadtreeEntry[] = [];
    // Query quadtree-contained objects
    this.queryCircleHelper(circle, results);
    // Query large objects
    for (let largeEntry of this.largeObjects) {
      if (!largeEntry.body.isLoaded()) {
        continue;
      }

      if (this.circlesIntersect(circle, largeEntry.circle)) {
        results.push(largeEntry);
      }
    }
    return results;
  }

  /**
   * Queries the quadtree for entries intersecting a given rectangle.
   */
  queryAABB(aabb: AABB): BodyQuadtreeEntry[] {
    let results: BodyQuadtreeEntry[] = [];
    // Query quadtree-contained objects
    this.queryAABBHelper(aabb, results);
    // Query large objects
    for (let largeEntry of this.largeObjects) {
      if (!largeEntry.body.isLoaded()) {
        continue;
      }

      if (this.circleIntersectsAABB(largeEntry.circle, aabb)) {
        results.push(largeEntry);
      }
    }
    return results;
  }

  /**
   * Returns an array of unique intersecting pairs of BodyQuadtreeEntry objects.
   */
  getIntersectingPairs(): BodyQuadtreePair[] {
    const pairs: BodyQuadtreePair[] = [];
    const pairKeys: Set<string> = new Set<string>();

    // Get pairs among quadtree-contained objects
    this.getIntersectingPairsHelper(pairs, pairKeys);

    // Get pairs between large objects and quadtree-contained objects
    for (let largeEntry of this.largeObjects) {
      const influencedEntries = this.queryCircle(largeEntry.circle);
      for (let entry of influencedEntries) {
        if (largeEntry.index === entry.index) continue; // Skip self
        if (this.circlesIntersect(largeEntry.circle, entry.circle)) {
          const key =
            largeEntry.index < entry.index
              ? `${largeEntry.index},${entry.index}`
              : `${entry.index},${largeEntry.index}`;
          if (!pairKeys.has(key)) {
            pairKeys.add(key);
            pairs.push({ a: largeEntry, b: entry });
          }
        }
      }
    }

    // Optionally, get pairs among large objects themselves
    for (let i = 0; i < this.largeObjects.length; i++) {
      for (let j = i + 1; j < this.largeObjects.length; j++) {
        const a = this.largeObjects[i];
        const b = this.largeObjects[j];
        if (this.circlesIntersect(a.circle, b.circle)) {
          const key =
            a.index < b.index
              ? `${a.index},${b.index}`
              : `${b.index},${a.index}`;
          if (!pairKeys.has(key)) {
            pairKeys.add(key);
            pairs.push({ a, b });
          }
        }
      }
    }

    return pairs;
  }

  /**
   * Subdivides the current node into four child nodes.
   */
  private subdivide() {
    const { left, right, top, bottom } = this.bounds;
    const midX = (left + right) / 2;
    const midY = (top + bottom) / 2;

    const nw: AABB = {
      left: left,
      right: midX,
      top: top,
      bottom: midY,
    };
    const ne: AABB = {
      left: midX,
      right: right,
      top: top,
      bottom: midY,
    };
    const sw: AABB = {
      left: left,
      right: midX,
      top: midY,
      bottom: bottom,
    };
    const se: AABB = {
      left: midX,
      right: right,
      top: midY,
      bottom: bottom,
    };

    this.children.push(new BodyQuadtree(nw, this.depth + 1));
    this.children.push(new BodyQuadtree(ne, this.depth + 1));
    this.children.push(new BodyQuadtree(sw, this.depth + 1));
    this.children.push(new BodyQuadtree(se, this.depth + 1));

    this.divided = true;
  }

  /**
   * Helper method for querying circles.
   */
  private queryCircleHelper(circle: Circle, results: BodyQuadtreeEntry[]) {
    // If the search circle does not intersect this node, return
    if (!this.circleIntersectsAABB(circle, this.bounds)) {
      return;
    }

    // Check entries in this node
    for (let entry of this.entries) {
      if (!entry.body.isLoaded()) {
        continue;
      }

      if (this.circlesIntersect(circle, entry.circle)) {
        results.push(entry);
      }
    }

    // Recurse into children
    if (this.divided) {
      for (let child of this.children) {
        child.queryCircleHelper(circle, results);
      }
    }
  }

  /**
   * Helper method for querying rectangles.
   */
  private queryAABBHelper(aabb: AABB, results: BodyQuadtreeEntry[]) {
    // If the search rectangle does not intersect this node, return
    if (!this.rectanglesIntersect(aabb, this.bounds)) {
      return;
    }

    // Check entries in this node
    for (let entry of this.entries) {
      if (!entry.body.isLoaded()) {
        continue;
      }

      if (this.circleIntersectsAABB(entry.circle, aabb)) {
        results.push(entry);
      }
    }

    // Recurse into children
    if (this.divided) {
      for (let child of this.children) {
        child.queryAABBHelper(aabb, results);
      }
    }
  }

  /**
   * Helper function to collect all unique intersecting pairs.
   * @param pairs - The array to store unique intersecting pairs.
   * @param pairKeys - A Set to track unique pair keys.
   */
  private getIntersectingPairsHelper(
    pairs: BodyQuadtreePair[],
    pairKeys: Set<string>
  ) {
    // Compare all entries in this node
    for (let i = 0; i < this.entries.length; i++) {
      for (let j = i + 1; j < this.entries.length; j++) {
        const a = this.entries[i];
        const b = this.entries[j];
        if (this.circlesIntersect(a.circle, b.circle)) {
          // Create a unique key for the pair to avoid duplicates
          const key =
            a.index < b.index
              ? `${a.index},${b.index}`
              : `${b.index},${a.index}`;
          if (!pairKeys.has(key)) {
            pairKeys.add(key);
            pairs.push({ a, b });
          }
        }
      }
    }

    // Recurse into children
    if (this.divided) {
      for (let child of this.children) {
        child.getIntersectingPairsHelper(pairs, pairKeys);
      }
    }
  }

  /**
   * Determines if two circles intersect.
   */
  private circlesIntersect(a: Circle, b: Circle): boolean {
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    const distanceSq = dx * dx + dy * dy;
    const radiusSum = a.radius + b.radius;
    return distanceSq <= radiusSum * radiusSum;
  }

  /**
   * Determines if two rectangles intersect.
   */
  private rectanglesIntersect(a: AABB, b: AABB): boolean {
    return !(
      a.left > b.right ||
      a.right < b.left ||
      a.top > b.bottom ||
      a.bottom < b.top
    );
  }

  /**
   * Determines if a circle intersects a rectangle.
   */
  private circleIntersectsAABB(circle: Circle, aabb: AABB): boolean {
    const closestX = clamp(circle.x, aabb.left, aabb.right);
    const closestY = clamp(circle.y, aabb.top, aabb.bottom);
    const dx = circle.x - closestX;
    const dy = circle.y - closestY;
    return dx * dx + dy * dy <= circle.radius * circle.radius;
  }
}

/**
 * Helper function to clamp a value between min and max.
 */
function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value));
}
