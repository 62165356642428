import {
  DEFAULT_BUTTON_MAP,
  PacketType,
  ButtonEvent,
  type ButtonMap,
} from "../models";
import type { Player } from "../plane/player";
import { BrowserTiming } from "../plane/timing";
import { Renderer } from "../rendering/renderer";
import { BufferReader, BufferWriter } from "./bufferUtil";
import { PlanarBase } from "./planarBase";
import { Body } from "../plane/body";
import { TouchTracker } from "./touch";
import { Enemy } from "../plane/enemy";

export abstract class PlanarClient extends PlanarBase {
  localPlayer: Player | null = null;
  canvas: HTMLCanvasElement;

  enemies: Enemy[] = [];

  renderer: Renderer;
  timing: BrowserTiming;
  lastUpdateTime: number = 0;
  animationFrameId: number | null = null;
  buttonMap: ButtonMap = DEFAULT_BUTTON_MAP;
  maxFrameTime: number = 0.25;
  networked: boolean;
  sendToServer: (message: ArrayBuffer | string) => void;
  gamepads: Map<number, Gamepad> = new Map();
  gamepadLoopId: number | null = null;
  rightTriggerPressed: boolean = false;
  leftTriggerPressed: boolean = false;
  aButtonPressed: boolean = false;
  leftBumperPressed: boolean = false;
  rightBumperPressed: boolean = false;
  dPadLeftPressed: boolean = false;
  dPadRightPressed: boolean = false;
  pointerLocked: boolean = false;
  lighting: boolean = false;
  mouseSensitivity: number = 1;
  gamepadSensitivity: number = 15;
  touchSensitivity: number = 30;

  // Add accumulators for mouse movement
  private accumulatedMovementX: number = 0;
  private accumulatedMovementY: number = 0;

  private touchTracker: TouchTracker = new TouchTracker();
  private armControllerTouchId: number | null = null;
  private actionTouchId: number | null = null;

  // Add constants for flick detection
  private readonly FLICK_VELOCITY_THRESHOLD = 0.5; // Adjust this value based on testing
  private readonly FLICK_DISTANCE_THRESHOLD = 30; // Minimum distance in pixels

  constructor({
    canvas,
    networked,
    sendToServer,
  }: {
    canvas: HTMLCanvasElement;
    networked: boolean;
    sendToServer: (message: ArrayBuffer | string) => void;
  }) {
    super({ id: "client", maxBodies: 10000 });
    this.canvas = canvas;
    this.timing = new BrowserTiming();
    this.renderer = new Renderer(canvas, this.plane);
    this.networked = networked;
    this.sendToServer = sendToServer;
    // this.setupGamepadListeners();

    // Initial resize using element dimensions
    const container = canvas.parentElement;
    if (container) {
      this.handleResize([container.clientWidth, container.clientHeight]);
    }

    // Resize listener using element dimensions
    window.addEventListener("resize", () => {
      const container = this.canvas.parentElement;
      if (container) {
        this.handleResize([container.clientWidth, container.clientHeight]);
      }
    });

    this.makeEnemies(1000);
  }

  getLocalPlayer(): Player {
    if (!this.localPlayer) {
      throw new Error("Local player not found");
    }
    return this.localPlayer;
  }

  makeEnemies(count: number): void {
    for (let i = 0; i < count; i++) {
      const enemy = new Enemy(this.plane);

      const { body, thruster } = enemy.getEntities();

      this.plane.bodyStore.loadBody({
        body,
        state: {
          position: [
            Math.random() * 50000 - 25000,
            Math.random() * 50000 - 25000,
            0,
          ],
          velocity: [0, 0, 0],
        },
      });
      this.plane.thrusters.push(thruster);
      this.enemies.push(enemy);
    }
  }

  update(): void {
    this.endMetric("actualFrameTime");
    this.startMetric("actualFrameTime");
    this.startMetric("frameTime");
    const now = this.timing.now();
    const possibleDt = (now - this.lastUpdateTime) / 1000; // Convert to seconds

    // Prevent spiral of death if frame takes too long
    const dt = Math.min(possibleDt, this.maxFrameTime);

    const numSubsteps = 2;
    const substepDt = dt / numSubsteps;
    for (let i = 0; i < numSubsteps; i++) {
      const substepNow = now + i * substepDt;

      // Scale movement by camera zoom to maintain consistent feel regardless of zoom level
      const zoomScaleFactor = this.renderer.camera.zoom;
      this.getLocalPlayer().armControlVector.x =
        this.accumulatedMovementX / substepDt / 1000 / zoomScaleFactor;
      this.getLocalPlayer().armControlVector.y =
        this.accumulatedMovementY / substepDt / 1000 / zoomScaleFactor;
      this.accumulatedMovementX = 0;
      this.accumulatedMovementY = 0;

      this.updatePlayers(substepDt, substepNow);
      this.updateEnemies();
      this.startMetric("planeUpdate");
      this.updatePlane(substepDt, substepNow);
      this.endMetric("planeUpdate");
    }

    this.startMetric("render");
    this.updateCamera(dt, now);
    this.renderer.render(
      this.getLocalPlayer(),
      dt,
      now,
      this.getMetricsReport(this.renderer.camera),
      this.lighting
    );
    this.endMetric("render");

    this.lastUpdateTime = now;
    this.endMetric("frameTime");
  }

  start(): void {
    this.setupListeners();
    this.lastUpdateTime = this.timing.now();

    const animate = () => {
      this.update();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    this.animationFrameId = requestAnimationFrame(animate);
  }

  stop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    this.stopGamepadLoop();
  }

  updatePlayers(dt: number, now: number): void {
    for (const player of this.players.values()) {
      player.update(dt, now);
    }
  }

  updateEnemies(): void {
    for (const enemy of this.enemies) {
      enemy.update(this.getLocalPlayer().getAbdomenPosition());
    }
  }

  updateCamera(dt: number, now: number): void {
    const abdomenPosition = this.getLocalPlayer().getAbdomenPosition();

    this.renderer.camera.follow(abdomenPosition, dt);
  }

  setupListeners(): void {
    this.canvas.addEventListener("mousedown", (e) => {
      if (e.button === 2) {
        this.handleButtonDown("rightMouse");
      } else {
        this.handleButtonDown("leftMouse");
      }
    });
    this.canvas.addEventListener("mouseup", (e) => {
      if (e.button === 2) {
        this.handleButtonUp("rightMouse");
      } else {
        this.handleButtonUp("leftMouse");
      }
    });
    this.canvas.addEventListener("mousemove", (e) => {
      if (this.pointerLocked) {
        this.handleMouseMove([e.movementX, e.movementY]);
      }
    });
    this.canvas.addEventListener("wheel", (e) => this.handleWheel(e.deltaY));
    window.addEventListener("keydown", (e) => this.handleButtonDown(e.key));
    window.addEventListener("keyup", (e) => this.handleButtonUp(e.key));
    window.addEventListener("resize", () => {
      const container = this.canvas.parentElement;
      if (container) {
        this.handleResize([container.clientWidth, container.clientHeight]);
      }
    });
    globalThis.addEventListener("touchstart", (e) => this.handleTouchStart(e));
    globalThis.addEventListener("touchmove", (e) => this.handleTouchMove(e));
    globalThis.addEventListener("touchend", (e) => this.handleTouchEnd(e));
    globalThis.addEventListener("touchcancel", (e) => this.handleTouchEnd(e));
    this.canvas.addEventListener("contextmenu", (e) => {
      e.preventDefault(); // Prevent the default context menu
    });
    this.canvas.addEventListener("click", () => {
      if (!this.pointerLocked) {
        this.canvas.requestPointerLock();
      }
    });

    document.addEventListener("pointerlockchange", () => {
      this.pointerLocked = document.pointerLockElement === this.canvas;
    });

    // Update touch listeners with options to prevent all default behaviors
    this.canvas.addEventListener(
      "touchstart",
      (e) => this.handleTouchStart(e),
      {
        passive: false,
        capture: true, // Capture phase to prevent other handlers
      }
    );
    this.canvas.addEventListener("touchmove", (e) => this.handleTouchMove(e), {
      passive: false,
      capture: true,
    });
    this.canvas.addEventListener("touchend", (e) => this.handleTouchEnd(e), {
      passive: false,
      capture: true,
    });
    this.canvas.addEventListener("touchcancel", (e) => this.handleTouchEnd(e), {
      passive: false,
      capture: true,
    });

    // Prevent default touch action styles
    this.canvas.style.touchAction = "none";
    // Prevent context menu (long press)
    this.canvas.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    // Prevent selection
    this.canvas.style.userSelect = "none";
  }

  handleMouseMove(point: [number, number]): void {
    if (this.networked) {
      const writer = new BufferWriter();
      writer.writeUint32(PacketType.ArmControl);
      writer.writeString(this.getLocalPlayer().id);
      writer.writeFloat32(point[0]);
      writer.writeFloat32(point[1]);
      this.sendToServer(writer.getBuffer());
    }

    this.accumulatedMovementX += point[0] * this.mouseSensitivity;
    this.accumulatedMovementY += point[1] * this.mouseSensitivity;
  }

  handleButtonDown(button: string): void {
    const buttonEvent = this.buttonMap.get(`${button}-down`);
    if (buttonEvent !== undefined) {
      if (this.networked) {
        const writer = new BufferWriter();
        writer.writeUint32(PacketType.ButtonEvent);
        writer.writeString(this.getLocalPlayer().id);
        writer.writeUint32(buttonEvent);
        this.sendToServer(writer.getBuffer());
      }
      this.getLocalPlayer().handleButtonEvent(buttonEvent);
    } else if (button === "l") {
      if (this.lighting) {
        this.lighting = false;
      } else {
        this.lighting = true;
      }
    }
  }

  handleButtonUp(button: string): void {
    const buttonEvent = this.buttonMap.get(`${button}-up`);
    if (buttonEvent !== undefined) {
      if (this.networked) {
        const writer = new BufferWriter();
        writer.writeUint32(PacketType.ButtonEvent);
        writer.writeString(this.getLocalPlayer().id);
        writer.writeUint32(buttonEvent);
        this.sendToServer(writer.getBuffer());
      }
      this.getLocalPlayer().handleButtonEvent(buttonEvent);
    }
  }

  handleResize(size: [number, number]): void {
    this.renderer.handleResize(size[0], size[1]);
  }

  handleWheel(deltaY: number): void {
    // Normalize the delta based on its magnitude
    // Trackpads typically send smaller values, mice send larger values
    const zoomSpeed = Math.abs(deltaY) < 50 ? 0.001 : 0.003;
    const zoomFactor = Math.pow(2, -deltaY * zoomSpeed);

    const newZoom = this.renderer.camera.zoomBy(zoomFactor);
    if (this.networked) {
      const writer = new BufferWriter();
      writer.writeUint32(PacketType.Zoom);
      writer.writeString(this.getLocalPlayer().id);
      writer.writeFloat32(newZoom);
      this.sendToServer(writer.getBuffer());
    }
  }

  handleTouchStart(e: TouchEvent): void {
    e.preventDefault();
    const time = this.timing.now();

    for (const touch of e.changedTouches) {
      // Calculate which third of the screen the touch is in
      const screenThird = touch.clientX / window.innerWidth;

      this.touchTracker.addTouch(
        touch.identifier,
        touch.clientX,
        touch.clientY,
        time
      );

      if (screenThird <= 0.33) {
        // Left third - action touch
        this.actionTouchId = touch.identifier;
        this.getLocalPlayer().handleButtonEvent(ButtonEvent.StartAction);
      } else {
        // Right two thirds - arm controller
        this.armControllerTouchId = touch.identifier;
      }
    }
  }

  handleTouchMove(e: TouchEvent): void {
    e.preventDefault();
    const time = this.timing.now();

    for (const touch of e.changedTouches) {
      const updatedTouch = this.touchTracker.updateTouch(
        touch.identifier,
        touch.clientX,
        touch.clientY,
        time
      );

      // Only handle movement for the arm controller touch
      if (touch.identifier === this.armControllerTouchId && updatedTouch) {
        this.accumulatedMovementX +=
          updatedTouch.velocityX * this.touchSensitivity;
        this.accumulatedMovementY +=
          updatedTouch.velocityY * this.touchSensitivity;
      }
    }
  }

  handleTouchEnd(e: TouchEvent): void {
    e.preventDefault();

    for (const touch of e.changedTouches) {
      if (touch.identifier === this.actionTouchId) {
        const touchPoint = this.touchTracker.getTouch(touch.identifier);

        if (touchPoint) {
          this.getLocalPlayer().handleButtonEvent(ButtonEvent.StopAction);

          // Check for flick
          const verticalDistance = touchPoint.currentY - touchPoint.startY;
          const verticalVelocity = touchPoint.velocityY;
          const totalDistance = Math.abs(verticalDistance);

          if (
            totalDistance > this.FLICK_DISTANCE_THRESHOLD &&
            Math.abs(verticalVelocity) > this.FLICK_VELOCITY_THRESHOLD
          ) {
            // Flick detected
            if (verticalVelocity < 0) {
              // Flick up
              this.getLocalPlayer().handleButtonEvent(ButtonEvent.NextAction);
            } else {
              // Flick down
              this.getLocalPlayer().handleButtonEvent(
                ButtonEvent.PreviousAction
              );
            }
          }
        }

        this.actionTouchId = null;
      } else if (touch.identifier === this.armControllerTouchId) {
        this.armControllerTouchId = null;
      }

      this.touchTracker.removeTouch(touch.identifier);
    }
  }

  handleInputMessage(reader: BufferReader, packetType: PacketType): void {
    const playerId = reader.readString();
    const player = this.players.get(playerId);
    if (!player) {
      throw new Error(`Player ${playerId} not found`);
    }

    switch (packetType) {
      case PacketType.ArmControl:
        player.armControlVector.x = reader.readFloat32();
        player.armControlVector.y = reader.readFloat32();
        break;
      case PacketType.ButtonEvent:
        player.handleButtonEvent(reader.readUint32());
        break;
      case PacketType.Zoom:
        this.renderer.camera.zoom = reader.readFloat32();
        break;
      default:
        console.error(`Unknown packet type: ${packetType}`);
        break;
    }
  }

  sendStateUpdateForPlayer(player: Player): void {
    const visibleBodies = this.plane.bodyStore.getBodiesVisibleToCamera(
      this.renderer.camera,
      500
    );

    const writer = new BufferWriter();

    writer.writeUint32(PacketType.StateUpdate);
    writer.writeString(player.id);
    writer.writeUint32(visibleBodies.length);

    for (let i = 0; i < visibleBodies.length; i++) {
      const bodyInfo = visibleBodies[i];
      writer.writeString(bodyInfo.body.id);

      const position = bodyInfo.body.getPosition();
      const velocity = bodyInfo.body.getVelocity();

      writer.writeFloat32(position[0]);
      writer.writeFloat32(position[1]);
      writer.writeFloat32(position[2]);
      writer.writeFloat32(velocity[0]);
      writer.writeFloat32(velocity[1]);
      writer.writeFloat32(velocity[2]);
    }

    this.sendToServer(writer.getBuffer());
  }

  // Directly apply the state update
  handleStateUpdate(reader: BufferReader): void {
    const playerId = reader.readString();

    if (playerId !== this.getLocalPlayer().id) {
      console.error(
        `Received state update for wrong player: ${playerId} !== ${
          this.getLocalPlayer().id
        }`
      );
      return;
    }

    // Apply the state update
    const numBodies = reader.readUint32();

    const loadedIds = new Set<string>();

    for (let i = 0; i < numBodies; i++) {
      const bodyId = reader.readString();
      const body = this.bodies.get(bodyId);
      if (!body) {
        console.error(`Body with id ${bodyId} not found`);
        continue;
      }

      loadedIds.add(bodyId);

      const position: [number, number, number] = [
        reader.readFloat32(),
        reader.readFloat32(),
        reader.readFloat32(),
      ];
      const velocity: [number, number, number] = [
        reader.readFloat32(),
        reader.readFloat32(),
        reader.readFloat32(),
      ];

      if (body.isLoaded()) {
        body.setPosition(position[0], position[1], position[2]);
        body.setVelocity(velocity[0], velocity[1], velocity[2]);
      } else {
        this.loadBody({
          body,
          state: {
            position,
            velocity,
          },
        });
      }
    }

    this.unloadAllBut(loadedIds);
  }

  unloadAllBut(ids: Set<string>): void {
    for (const body of this.plane.bodies) {
      if (body && !ids.has(body.id)) {
        if (!this.neverUnloadBodyIds.has(body.id)) {
          this.plane.unloadBody(body.index);
        }
      }
    }
  }

  setupGamepadListeners(): void {
    window.addEventListener("gamepadconnected", (e: GamepadEvent) => {
      console.log(`Gamepad connected: ${e.gamepad.id}`);
      this.gamepads.set(e.gamepad.index, e.gamepad);

      // Start the gamepad polling loop if it's not already running
      if (this.gamepadLoopId === null) {
        this.startGamepadLoop();
      }
    });

    window.addEventListener("gamepaddisconnected", (e: GamepadEvent) => {
      console.log(`Gamepad disconnected: ${e.gamepad.id}`);
      this.gamepads.delete(e.gamepad.index);

      // Stop the gamepad loop if no gamepads are connected
      if (this.gamepads.size === 0 && this.gamepadLoopId !== null) {
        this.stopGamepadLoop();
      }
    });
  }

  startGamepadLoop(): void {
    const pollGamepads = () => {
      // Get fresh gamepad data
      const gamepads = navigator.getGamepads();

      for (const gamepad of gamepads) {
        if (!gamepad) continue;

        // Handle left stick
        this.handleAxis01(gamepad.axes[0], gamepad.axes[1]);
        // Handle right stick
        this.handleAxis23(gamepad.axes[2], gamepad.axes[3]);

        this.handleLeftTrigger(gamepad.buttons[6].value);
        this.handleRightTrigger(gamepad.buttons[7].value);
        this.handleAButton(gamepad.buttons[0].pressed);
        this.handleLeftBumper(gamepad.buttons[4].pressed);
        this.handleRightBumper(gamepad.buttons[5].pressed);
        this.handleDPadLeft(gamepad.buttons[14].pressed);
        this.handleDPadRight(gamepad.buttons[15].pressed);
      }

      this.gamepadLoopId = requestAnimationFrame(pollGamepads);
    };

    this.gamepadLoopId = requestAnimationFrame(pollGamepads);
  }

  stopGamepadLoop(): void {
    if (this.gamepadLoopId !== null) {
      cancelAnimationFrame(this.gamepadLoopId);
      this.gamepadLoopId = null;
    }
  }

  // Stub methods for handling gamepad axes
  protected handleAxis01(x: number, y: number): void {
    // Left stick
    this.getLocalPlayer().updateGamepadThrustVector(x, y);
  }

  protected handleAxis23(x: number, y: number): void {
    // Right stick
    this.accumulatedMovementX += x * this.gamepadSensitivity;
    this.accumulatedMovementY += y * this.gamepadSensitivity;
  }

  protected handleRightTrigger(value: number): void {
    // Trigger values are -1 to 1, normalize to 0 to 1
    const normalizedValue = (value + 1) / 2;
    const isPressed = normalizedValue > 0.5;

    // Only call handleButtonDown if the state has changed
    if (isPressed !== this.rightTriggerPressed) {
      this.rightTriggerPressed = isPressed;
      if (isPressed) {
        this.handleButtonDown("rightTrigger");
      } else {
        this.handleButtonUp("rightTrigger");
      }
    }
  }

  protected handleLeftTrigger(value: number): void {
    // Trigger values are -1 to 1, normalize to 0 to 1
    const normalizedValue = (value + 1) / 2;
    const isPressed = normalizedValue > 0.5;

    // Only call handleButtonDown if the state has changed
    if (isPressed !== this.leftTriggerPressed) {
      this.leftTriggerPressed = isPressed;
      if (isPressed) {
        this.handleButtonDown("leftTrigger");
      } else {
        this.handleButtonUp("leftTrigger");
      }
    }
  }

  protected handleLeftBumper(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.leftBumperPressed) {
      this.leftBumperPressed = value;
      if (value) {
        this.handleButtonDown("leftBumper");
      } else {
        this.handleButtonUp("leftBumper");
      }
    }
  }

  protected handleRightBumper(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.rightBumperPressed) {
      this.rightBumperPressed = value;
      if (value) {
        this.handleButtonDown("rightBumper");
      } else {
        this.handleButtonUp("rightBumper");
      }
    }
  }

  protected handleDPadLeft(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.dPadLeftPressed) {
      this.dPadLeftPressed = value;
      if (value) {
        this.handleButtonDown("dPadLeft");
      } else {
        this.handleButtonUp("dPadLeft");
      }
    }
  }

  protected handleDPadRight(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.dPadRightPressed) {
      this.dPadRightPressed = value;
      if (value) {
        this.handleButtonDown("dPadRight");
      } else {
        this.handleButtonUp("dPadRight");
      }
    }
  }

  protected handleAButton(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.aButtonPressed) {
      this.aButtonPressed = value;
      if (value) {
        this.handleButtonDown("gamepadA");
      } else {
        this.handleButtonUp("gamepadA");
      }
    }
  }

  abstract updatePlane(dt: number, now: number): void;
  abstract afterAddBody(body: Body): void;
}
