import { TWO_PI } from "../math/utils.ts";
import { Camera } from "../plane/camera.ts";
import { Plane } from "../plane/plane.ts";
import type { Player } from "../plane/player.ts";
import type { Body } from "../plane/body.ts";
import { type Circle, type LightSource } from "../models.ts";
import { Vector2D } from "../math/vector2D.ts";
import type { SegmentTree } from "../shapes/segmentTree.ts";
import { Color } from "./color.ts";
import type { Actuator } from "../plane/actuator.ts";

const segmentTreeColors = [
  new Color(255, 0, 0),
  new Color(0, 255, 0),
  new Color(0, 0, 255),
  new Color(255, 255, 0),
  new Color(255, 0, 255),
  new Color(0, 255, 255),
];

export class Renderer {
  private ctx: CanvasRenderingContext2D;
  private canvas: HTMLCanvasElement;
  private bodyCanvas: OffscreenCanvas;
  private bodyCtx: OffscreenCanvasRenderingContext2D;
  private lightCanvas: OffscreenCanvas;
  private lightCtx: OffscreenCanvasRenderingContext2D;
  private subplaneLightCanvas: OffscreenCanvas;
  private subplaneLightCtx: OffscreenCanvasRenderingContext2D;
  private plane: Plane;
  public camera: Camera;

  renderMecs: boolean = false;
  bodyShadowAreaThreshold: number = 100000;

  private readonly lightCanvasScale: number = 0.3; // Configure scale factor
  private bufferWidth: number = 0;
  private bufferHeight: number = 0;

  constructor(canvas: HTMLCanvasElement, plane: Plane) {
    this.canvas = canvas;
    this.plane = plane;

    // Initialize with proper DPI handling
    const rect = canvas.getBoundingClientRect();
    setupHighDPICanvas(canvas, rect.width, rect.height);

    // Store buffer dimensions
    this.bufferWidth = canvas.width;
    this.bufferHeight = canvas.height;

    // Initialize camera with actual buffer dimensions
    this.camera = new Camera(this.bufferWidth, this.bufferHeight);

    // Get context and scale for DPR
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Failed to get canvas context");
    this.ctx = ctx;

    // Create canvases at appropriate resolutions
    this.createCanvases();
  }

  private createCanvases(): void {
    // Full resolution for body canvas
    this.bodyCanvas = new OffscreenCanvas(this.bufferWidth, this.bufferHeight);

    // Scaled resolution for light canvases
    const lightWidth = Math.ceil(this.bufferWidth * this.lightCanvasScale);
    const lightHeight = Math.ceil(this.bufferHeight * this.lightCanvasScale);

    this.lightCanvas = new OffscreenCanvas(lightWidth, lightHeight);
    this.subplaneLightCanvas = new OffscreenCanvas(lightWidth, lightHeight);

    // Get contexts
    const bodyCtx = this.bodyCanvas.getContext("2d");
    const lightCtx = this.lightCanvas.getContext("2d");
    const subplaneLightCtx = this.subplaneLightCanvas.getContext("2d");

    if (!bodyCtx || !lightCtx || !subplaneLightCtx) {
      throw new Error("Failed to get offscreen canvas context");
    }

    this.bodyCtx = bodyCtx;
    this.lightCtx = lightCtx;
    this.subplaneLightCtx = subplaneLightCtx;
  }

  handleResize(width: number, height: number): void {
    // Update main canvas with DPI handling
    setupHighDPICanvas(this.canvas, width, height);

    // Store new buffer dimensions
    this.bufferWidth = this.canvas.width;
    this.bufferHeight = this.canvas.height;

    // Recreate all canvases at new sizes
    this.createCanvases();

    // Update camera with buffer dimensions
    this.camera.updateSize(this.bufferWidth, this.bufferHeight);
  }

  render(
    player: Player,
    dt: number,
    now: number,
    metricsReport: string,
    lighting: boolean = true
  ): void {
    this.clearCanvases();
    this.ctx.fillStyle = "#1e1e1e";
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.setCanvasTransforms();

    const { mainPlaneBodies, subplaneBodies, lightSources, actuators } =
      this.plane.getRenderInfo(this.camera);

    this.bodyCtx.globalCompositeOperation = "source-over";

    this.renderBodies(subplaneBodies, this.bodyCtx);
    this.renderActuators(actuators, this.bodyCtx);
    this.renderBodies(mainPlaneBodies, this.bodyCtx);
    this.renderConstraints(this.bodyCtx, dt);

    if (lighting) {
      // Handle shadows with consistent transforms
      this.renderBodyShadows(mainPlaneBodies, this.lightCtx);
      this.renderBodyShadows(subplaneBodies, this.subplaneLightCtx);

      // Combine subplane shadows while maintaining transform
      this.lightCtx.globalCompositeOperation = "source-over";
      this.lightCtx.resetTransform(); // Reset transform for raw compositing
      this.lightCtx.drawImage(this.subplaneLightCanvas, 0, 0);
      this.setCanvasTransform(this.lightCtx, true); // Restore transform for light sources

      // Render light sources with same transform
      this.lightCtx.globalCompositeOperation = "source-over";
      this.renderLightSources(lightSources, this.lightCtx, this.bodyCtx, false);
    }

    // Final compositing
    this.ctx.globalCompositeOperation = "source-over";
    this.resetCanvasTransforms();
    this.ctx.drawImage(this.bodyCanvas, 0, 0);

    if (lighting) {
      this.ctx.save();
      this.ctx.scale(1 / this.lightCanvasScale, 1 / this.lightCanvasScale);
      this.ctx.drawImage(this.lightCanvas, 0, 0);
      this.ctx.restore();
    }

    this.setCanvasTransform(this.ctx);

    this.renderCurrentAction(player, dt, now, this.ctx);
    this.renderSurfacePoints();

    if (lighting) {
      this.renderLightGlows(this.ctx, lightSources);
    }
    // this.renderCollisionVectors();
    // this.renderTargetPosition(player);

    // UI
    this.ctx.resetTransform();

    this.renderMetrics(metricsReport);
    this.renderCurrentActionData(player);
    this.renderControls();
  }

  clearCanvas(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    // Clear using canvas's own dimensions rather than buffer dimensions
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  clearCanvases(): void {
    this.clearCanvas(this.ctx);
    this.clearCanvas(this.lightCtx);
    this.clearCanvas(this.bodyCtx);
    this.clearCanvas(this.subplaneLightCtx);
  }

  setCanvasTransform(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    isLightCanvas: boolean = false
  ): void {
    const scale = isLightCanvas ? this.lightCanvasScale : 1;
    const width = isLightCanvas ? this.lightCanvas.width : this.canvas.width;
    const height = isLightCanvas ? this.lightCanvas.height : this.canvas.height;

    ctx.setTransform(
      this.camera.zoom * scale,
      0,
      0,
      this.camera.zoom * scale,
      width / 2 - this.camera.x * this.camera.zoom * scale,
      height / 2 - this.camera.y * this.camera.zoom * scale
    );
  }

  setCanvasTransforms(): void {
    this.setCanvasTransform(this.ctx, false);
    this.setCanvasTransform(this.bodyCtx, false);
    this.setCanvasTransform(this.lightCtx, true);
    this.setCanvasTransform(this.subplaneLightCtx, true);
  }

  resetCanvasTransforms(): void {
    this.ctx.resetTransform();
    this.lightCtx.resetTransform();
    this.bodyCtx.resetTransform();
    this.subplaneLightCtx.resetTransform();
  }

  renderTargetPosition(player: Player): void {
    // Render as opaque white circle
    this.ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
    this.ctx.beginPath();
    this.ctx.arc(
      player.globalTargetPosition.x,
      player.globalTargetPosition.y,
      50,
      0,
      TWO_PI
    );
    this.ctx.fill();
  }

  renderBodies(
    bodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[],
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    ctx.save();

    ctx.strokeStyle = "rgb(255, 255, 255)";
    ctx.lineWidth = 4;

    // Render all bodies first
    for (const { body, mec, detailed } of bodies) {
      if (detailed) {
        // Render full detail shape (accumulating shadows in shadowCtx)
        this.renderBody(body, ctx);

        if (this.renderMecs) {
          this.renderCircle(mec.x, mec.y, mec.radius, "white", false, ctx);
        }
      } else {
        // LOD circle
        this.renderCircle(
          mec.x,
          mec.y,
          mec.radius,
          body.material.color.toRGB(),
          true,
          ctx
        );
      }
    }

    ctx.restore();
  }

  renderBodyShadows(
    bodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[],
    lightCtx: OffscreenCanvasRenderingContext2D
  ): void {
    lightCtx.strokeStyle = "rgb(255, 255, 255)";
    lightCtx.lineWidth = 4;

    lightCtx.save();

    for (const { body, detailed } of bodies) {
      if (detailed && body.shape.area > this.bodyShadowAreaThreshold) {
        this.renderBodyShadow(body, lightCtx);
      }
    }

    lightCtx.restore();
  }

  renderActuators(
    actuators: Actuator[],
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    for (const actuator of actuators) {
      ctx.save();
      actuator.render(ctx);
      ctx.restore();
    }
  }

  renderConstraints(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    dt: number
  ): void {
    for (const pin of this.plane.pins) {
      pin.render(ctx);
    }
    for (const tether of this.plane.tethers) {
      tether.render(ctx, dt);
    }
    for (const spring of this.plane.springs) {
      spring.render(ctx);
    }
  }

  renderCircle(
    centerX: number,
    centerY: number,
    radius: number,
    color: string,
    fill: boolean = true,
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    ctx.beginPath();

    ctx.arc(centerX, centerY, radius, 0, TWO_PI);
    ctx.fillStyle = color;
    if (fill) {
      ctx.fill();
    }
    ctx.stroke();
  }

  private renderLightSources(
    lightSources: {
      light: LightSource;
      position: Vector2D;
    }[],
    lightCtx: OffscreenCanvasRenderingContext2D,
    bodyCtx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    withColor: boolean = true
  ): void {
    lightCtx.save();

    lightCtx.globalCompositeOperation = "destination-out";

    // Handle light sources (still clipped to shape)
    for (const { light, position } of lightSources) {
      const gradient = lightCtx.createRadialGradient(
        position.x,
        position.y,
        0,
        position.x,
        position.y,
        light.radius
      );

      // Apply gradient stops from light source
      for (const stop of light.brightnessStops) {
        gradient.addColorStop(stop.position, `rgba(0, 0, 0, ${stop.opacity})`);
      }

      lightCtx.fillStyle = gradient;
      lightCtx.beginPath();
      lightCtx.arc(position.x, position.y, light.radius, 0, TWO_PI);
      lightCtx.fill();
    }

    bodyCtx.save();
    // Use screen blend mode for additive light
    bodyCtx.globalCompositeOperation = "source-atop";

    if (withColor) {
      // Handle colored light sources
      for (const { light, position } of lightSources) {
        const gradient = bodyCtx.createRadialGradient(
          position.x,
          position.y,
          0,
          position.x,
          position.y,
          light.radius
        );

        // Apply colored gradient stops
        for (const stop of light.colorStops) {
          gradient.addColorStop(stop.position, light.color.toRGB(stop.opacity));
        }

        bodyCtx.fillStyle = gradient;
        bodyCtx.beginPath();
        bodyCtx.arc(position.x, position.y, light.radius, 0, TWO_PI);
        bodyCtx.fill();
      }
    }

    bodyCtx.restore();
    lightCtx.restore();
  }

  private renderLightGlows(
    ctx: CanvasRenderingContext2D,
    lightSources: {
      light: LightSource;
      position: Vector2D;
    }[]
  ): void {
    ctx.save();
    ctx.globalCompositeOperation = "screen";

    for (const { light, position } of lightSources) {
      const gradient = ctx.createRadialGradient(
        position.x,
        position.y,
        0,
        position.x,
        position.y,
        light.glowRadius
      );

      // Simple two-stop gradient with very low opacity
      gradient.addColorStop(0, light.color.toRGB(0.5));
      gradient.addColorStop(1, light.color.toRGB(0));

      ctx.fillStyle = gradient;
      ctx.beginPath();
      ctx.arc(position.x, position.y, light.glowRadius, 0, TWO_PI);
      ctx.fill();
    }

    ctx.restore();
  }

  renderPointClosestToLimb(
    player: Player,
    queryRadius: number = 400,
    includeAbdomen: boolean = false,
    eligibilityCheck?: (body: Body) => boolean
  ): void {
    const limbPosition = player.getLimbPosition();
    const closest = this.plane.bodyStore.getClosestPointOnBody(
      limbPosition,
      queryRadius,
      includeAbdomen ? [player.limb] : [player.limb, player.abdomen],
      eligibilityCheck
    );

    if (!closest) return;

    // Get screen coordinates for closest point and remaining radius
    const remainingRadius = queryRadius - closest.distance; // Subtract distance from query radius

    this.ctx.save();

    // Create clipping region centered on closest point
    this.ctx.beginPath();
    this.ctx.arc(
      closest.pointOnSurface.globalPosition.x,
      closest.pointOnSurface.globalPosition.y,
      remainingRadius,
      0,
      TWO_PI
    );
    this.ctx.clip();

    // Render a thicker stroke on the closest body (now clipped to circle)
    const path = closest.pointOnSurface.body.shape.getPath();
    const transform = closest.pointOnSurface.body.getTransform();

    this.ctx.save();
    this.ctx.translate(transform.x, transform.y);
    this.ctx.rotate(transform.angle);

    this.ctx.lineWidth = 10;
    this.ctx.strokeStyle = "white";
    this.ctx.lineCap = "round";
    this.ctx.lineJoin = "round";
    this.ctx.stroke(path);
    this.ctx.restore();

    // Render closest point dot (white circle)
    this.ctx.fillStyle = "white";
    this.ctx.beginPath();
    this.ctx.arc(
      closest.pointOnSurface.globalPosition.x,
      closest.pointOnSurface.globalPosition.y,
      15,
      0,
      TWO_PI
    );
    this.ctx.fill();

    this.ctx.restore(); // Removes the clipping
  }

  renderSurfacePoints(): void {
    this.ctx.save();
    // Fill with red
    this.ctx.fillStyle = "red";
    // Go through all stored points and render them
    for (const surfacePoint of this.plane.surfacePointStore.surfacePoints) {
      if (!surfacePoint) continue;
      const point = surfacePoint.getPosition();
      if (!point) continue;
      // Render as red circle
      this.ctx.beginPath();
      this.ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
      this.ctx.fillStyle = "red";
      this.ctx.fill();
    }
    this.ctx.restore();
  }

  renderCompositeCentroids(): void {
    for (const composite of this.plane.bodyStore.compositeBodies) {
      this.ctx.fillRect(
        composite.centroidPosition[0] - 5,
        composite.centroidPosition[1] - 5,
        10,
        10
      );
    }
  }

  renderMetrics(metricsReport: string): void {
    // Setup text rendering style
    this.ctx.save();
    this.ctx.fillStyle = "white";
    this.ctx.font = "14px monospace";
    this.ctx.textAlign = "right";
    this.ctx.textBaseline = "top";

    // Draw each metric line
    const lines = metricsReport.split("\n");
    lines.forEach((line, i) => {
      this.ctx.fillText(line, this.canvas.width - 10, 10 + i * 20);
    });

    this.ctx.restore();
  }

  renderControls(): void {
    // Setup text rendering style
    this.ctx.save();
    this.ctx.fillStyle = "white";
    this.ctx.font = "14px monospace";
    this.ctx.textAlign = "left";
    this.ctx.textBaseline = "bottom";

    const controlsText = [
      "Controls:",
      "Thrust - WASD",
      "Toggle action - QE",
      "Action - Space",
    ];

    // Draw each line from bottom up
    controlsText.forEach((line, i) => {
      const bottomOffset = controlsText.length - 1 - i;
      this.ctx.fillText(
        line,
        10, // 10px from left edge (instead of right)
        this.canvas.height - (10 + bottomOffset * 20)
      );
    });

    this.ctx.restore();
  }

  renderCurrentActionData(player: Player): void {
    const currentAction = player.getCurrentAction();

    const currentActionData = currentAction.getDisplayText();

    // Reset all canvas states before rendering UI
    this.ctx.save();

    this.ctx.fillStyle = "white";
    this.ctx.font = "18px monospace";
    this.ctx.textAlign = "left";
    this.ctx.textBaseline = "top";

    // Convert enum to readable text
    const actionText = `Action: ${
      currentActionData ? currentActionData : "No action"
    }`;

    // Draw text in top left corner
    this.ctx.fillText(actionText, 10, 10);

    this.ctx.restore();
  }

  renderCurrentAction(
    player: Player,
    dt: number,
    now: number,
    ctx: CanvasRenderingContext2D
  ): void {
    const currentAction = player.getCurrentAction();

    ctx.save();
    const renderDefault = currentAction.render(
      player.getActionUpdateInfo(currentAction, now, dt),
      ctx
    );
    ctx.restore();
    if (renderDefault) {
      ctx.save();
      this.renderPointClosestToLimb(
        player,
        currentAction.selectPointConfig?.reach,
        currentAction.selectPointConfig?.includeAbdomen,
        currentAction.selectPointConfig?.eligibilityCheck
      );
      ctx.restore();
    }
  }

  renderBody(
    body: Body,
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    const transform = body.getTransform();
    const path = body.shape.getPath();
    const textureInfo = body.getTextureInfo();
    const aabb = body.getAABB();
    const originalBounds = textureInfo.originalBounds;

    if (!originalBounds) return;

    ctx.save();
    ctx.translate(transform.x, transform.y);
    ctx.rotate(transform.angle);

    // Add clipping to ensure texture only shows within shape
    ctx.clip(path);

    // Fill with color
    ctx.fillStyle = body.material.color.toRGB();
    ctx.fill(path);

    // ctx.save();
    // // ctx.scale(1, 1 / textureScale);

    // // Source coordinates in texture space
    // const sx = aabb.left - originalBounds.left;
    // const sy = aabb.top - originalBounds.top;
    // const sWidth = aabb.right - aabb.left;
    // const sHeight = aabb.bottom - aabb.top;

    // // Destination coordinates in body space
    // const dx = aabb.left;
    // const dy = aabb.top;
    // const dWidth = aabb.right - aabb.left;
    // const dHeight = aabb.bottom - aabb.top;

    // ctx.drawImage(
    //   textureInfo.texture,
    //   sx,
    //   sy,
    //   sWidth,
    //   sHeight, // Source rectangle
    //   dx,
    //   dy,
    //   dWidth,
    //   dHeight // Destination rectangle
    // );

    // ctx.restore();

    ctx.lineWidth = 8;
    ctx.strokeStyle = "white";
    ctx.stroke(path);

    ctx.restore();
  }

  renderBodyShadow(
    body: Body,
    lightCtx: OffscreenCanvasRenderingContext2D
  ): void {
    const transform = body.getTransform();
    const path = body.shape.getPath();

    lightCtx.save();
    lightCtx.translate(transform.x, transform.y);
    lightCtx.rotate(transform.angle);

    // Clip to the shape's path
    lightCtx.clip(path);

    // Fill the base shape in the mask
    lightCtx.beginPath();
    lightCtx.fill(path);

    lightCtx.globalCompositeOperation = "destination-out";

    const perimeterPoints = body.shape.getPerimeterPoints(
      body.shadowPointDistance
    );

    const shadowConfig = body.inSubplane
      ? body.subplaneInteriorShadowConfig
      : body.interiorShadowConfig;

    for (const point of perimeterPoints) {
      const gradient = lightCtx.createRadialGradient(
        point.x,
        point.y,
        0,
        point.x,
        point.y,
        shadowConfig.baseRadius
      );

      // Apply gradient stops
      for (const stop of shadowConfig.stops) {
        gradient.addColorStop(stop.position, `rgba(0, 0, 0, ${stop.opacity})`);
      }

      lightCtx.fillStyle = gradient;
      lightCtx.beginPath();
      lightCtx.arc(point.x, point.y, shadowConfig.baseRadius, 0, TWO_PI);
      lightCtx.fill();
    }

    lightCtx.restore();
  }

  renderCollisionVectors(): void {
    for (const vector of this.plane.collisionVectors) {
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 15;
      this.ctx.beginPath();
      this.ctx.moveTo(vector.point.x, vector.point.y);
      this.ctx.lineTo(
        vector.point.x + vector.vector.x,
        vector.point.y + vector.vector.y
      );
      this.ctx.stroke();
    }
  }
}

function getDevicePixelRatio(): number {
  return window.devicePixelRatio || 1;
}

function setupHighDPICanvas(
  canvas: HTMLCanvasElement,
  width: number,
  height: number
): void {
  const dpr = getDevicePixelRatio();

  // Set the canvas size in CSS pixels
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;

  // Set actual buffer size accounting for DPR
  canvas.width = Math.ceil(width * dpr);
  canvas.height = Math.ceil(height * dpr);
}

function renderSegmentTreeLevels({
  ctx,
  node,
  currentLevel,
  minRenderDepth,
  maxDepth,
  colors,
}: {
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  node: SegmentTree;
  currentLevel: number;
  minRenderDepth: number;
  maxDepth: number;
  colors: Color[];
}) {
  if (currentLevel > maxDepth || !node) return;

  // Recursively draw children first
  for (const child of node.children) {
    renderSegmentTreeLevels({
      ctx,
      node: child,
      currentLevel: currentLevel + 1,
      minRenderDepth,
      maxDepth,
      colors,
    });
  }

  // Only render if we're within the render range
  if (currentLevel >= minRenderDepth) {
    const renderLevel = currentLevel - minRenderDepth;
    const color = colors[renderLevel % colors.length];

    ctx.strokeStyle = color.toRGB();
    ctx.lineWidth = (30 / minRenderDepth) * (1 / (renderLevel + 1));
    // ctx.fillStyle = color.toRGB(0.1);

    ctx.beginPath();
    ctx.rect(
      node.bounds.left,
      node.bounds.top,
      node.bounds.right - node.bounds.left,
      node.bounds.bottom - node.bounds.top
    );
    ctx.stroke();
  }
}
