import { Vector2D } from "../math/vector2D";
import type { Body } from "./body.ts";

export class Thruster {
  private body: Body;
  private force: Vector2D;

  constructor(body: Body) {
    this.body = body;
    this.force = new Vector2D(0, 0);
  }

  updateForce(force: Vector2D): void {
    this.force = force;
  }

  apply(dt: number): void {
    this.body.applyForce(this.force.scale(dt));
  }
}
