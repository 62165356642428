export class Color {
  readonly r: number;
  readonly g: number;
  readonly b: number;
  readonly rgb: string;
  private readonly rgba: string;

  constructor(r: number, g: number, b: number) {
    this.r = Math.min(255, Math.max(0, r));
    this.g = Math.min(255, Math.max(0, g));
    this.b = Math.min(255, Math.max(0, b));
    this.rgb = `rgb(${this.r}, ${this.g}, ${this.b})`;
    this.rgba = `rgba(${this.r}, ${this.g}, ${this.b}, `;
  }

  static fromHex(hex: string): Color {
    // Support both 3-digit and 6-digit formats
    const normalized = hex.replace(/^#/, "");
    let match;

    if (normalized.length === 3) {
      // Convert 3-digit to 6-digit
      match = normalized.match(/^([0-9a-f])([0-9a-f])([0-9a-f])$/i);
      if (match) {
        const [r, g, b] = match.slice(1).map((c) => parseInt(c + c, 16));
        return new Color(r, g, b);
      }
    }

    match = normalized.match(/^([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (!match) throw new Error(`Invalid hex color: ${hex}`);

    const [r, g, b] = match.slice(1).map((c) => parseInt(c, 16));
    return new Color(r, g, b);
  }

  static fromName(name: string): Color {
    const selectedColor = colorMap[name.toLowerCase()];
    if (!selectedColor) {
      console.warn(`Color not found: ${name}`);
      return new Color(0, 0, 0);
    }

    return selectedColor;
  }

  toRGB(opacity?: number): string {
    if (opacity === undefined) {
      return this.rgb;
    }

    return this.rgba + opacity.toString() + ")";
  }
}

const materialColors: string[] = ["gray"];

// Add this color mapping object
const colorMap: { [key: string]: Color } = {
  aqua: new Color(0, 255, 255),
  black: new Color(0, 0, 0),
  blue: new Color(0, 0, 255),
  fuchsia: new Color(255, 0, 255),
  gray: new Color(128, 128, 128),
  darkgray: new Color(75, 75, 75),
  lightgray: new Color(200, 200, 200),
  mediumgray: new Color(150, 150, 150),
  green: new Color(0, 128, 0),
  lime: new Color(0, 255, 0),
  maroon: new Color(128, 0, 0),
  navy: new Color(0, 0, 128),
  olive: new Color(128, 128, 0),
  purple: new Color(128, 0, 128),
  red: new Color(255, 0, 0),
  silver: new Color(192, 192, 192),
  teal: new Color(0, 128, 128),
  white: new Color(255, 255, 255),
  yellow: new Color(255, 255, 0),
  orange: new Color(255, 165, 0),
  pink: new Color(255, 192, 203),
  brown: new Color(165, 42, 42),
  cyan: new Color(0, 255, 255),
  magenta: new Color(255, 0, 255),
  indigo: new Color(75, 0, 130),
  violet: new Color(238, 130, 238),
  turquoise: new Color(64, 224, 208),
  gold: new Color(255, 215, 0),
  salmon: new Color(250, 128, 114),
  khaki: new Color(240, 230, 140),
  plum: new Color(221, 160, 221),
  coral: new Color(255, 127, 80),
  lavender: new Color(230, 230, 250),
};

const validColorNames = Object.keys(colorMap);

// Utility function to get a random color
function getRandomColor(): Color {
  return colorMap[
    validColorNames[Math.floor(Math.random() * validColorNames.length)]
  ];
}

function getRandomMaterialColor(): Color {
  return colorMap[
    materialColors[Math.floor(Math.random() * materialColors.length)]
  ];
}

// Export the functions and array for use in other modules
export { getRandomColor, getRandomMaterialColor };
