export class TouchPoint {
  id: number;
  currentX: number;
  currentY: number;
  startX: number;
  startY: number;
  startTime: number;
  lastX: number;
  lastY: number;
  lastTime: number;
  velocityX: number = 0;
  velocityY: number = 0;

  constructor(id: number, x: number, y: number, time: number) {
    this.id = id;
    this.currentX = x;
    this.currentY = y;
    this.startX = x;
    this.startY = y;
    this.startTime = time;
    this.lastX = x;
    this.lastY = y;
    this.lastTime = time;
  }

  update(x: number, y: number, time: number) {
    const dt = time - this.lastTime;
    if (dt > 0) {
      this.velocityX = (x - this.lastX) / dt;
      this.velocityY = (y - this.lastY) / dt;
    }

    this.lastX = this.currentX;
    this.lastY = this.currentY;
    this.lastTime = time;

    this.currentX = x;
    this.currentY = y;
  }

  getTotalDistance(): number {
    const dx = this.currentX - this.startX;
    const dy = this.currentY - this.startY;
    return Math.sqrt(dx * dx + dy * dy);
  }

  getDuration(currentTime: number): number {
    return currentTime - this.startTime;
  }
}

export class TouchTracker {
  private touches: Map<number, TouchPoint> = new Map();

  addTouch(id: number, x: number, y: number, time: number): TouchPoint {
    const touch = new TouchPoint(id, x, y, time);
    this.touches.set(id, touch);
    return touch;
  }

  updateTouch(
    id: number,
    x: number,
    y: number,
    time: number
  ): TouchPoint | null {
    const touch = this.touches.get(id);
    if (touch) {
      touch.update(x, y, time);
      return touch;
    }
    return null;
  }

  removeTouch(id: number): void {
    this.touches.delete(id);
  }

  getTouch(id: number): TouchPoint | undefined {
    return this.touches.get(id);
  }

  getAllTouches(): TouchPoint[] {
    return Array.from(this.touches.values());
  }

  clear(): void {
    this.touches.clear();
  }

  get count(): number {
    return this.touches.size;
  }
}
