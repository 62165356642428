import { TWO_PI } from "../math/utils";
import type { AABB } from "../models";
import { Color } from "./color";
import { createFractalNoise2D } from "./noise";

const MAX_CANVAS_SIZE = 20000;

const fractalNoise2D = createFractalNoise2D({
  params: {
    baseScale: 0.1,
    lacunarity: 2.5,
    octaves: 3,
    persistence: 0.5,
  },
});

// Use like this:
// const values = fractalNoise2D({ x: 0, y: 0 });

type ColorThreshold = {
  threshold: number; // Value between -1 and 1
  color: Color;
};

function thresholdNoise(
  ctx: OffscreenCanvasRenderingContext2D,
  aabb: AABB,
  inSubplane: boolean,
  thresholds: ColorThreshold[] = [
    { threshold: -0.5, color: new Color(20, 0, 20) },
    { threshold: 0.0, color: new Color(80, 0, 80) },
    { threshold: 0.5, color: new Color(200, 0, 200) },
  ],
  sampleRate = 1000 // Default to sampling every 4 pixels
) {
  const aabbWidth = aabb.right - aabb.left;
  const aabbHeight = aabb.bottom - aabb.top;

  // Sort thresholds from lowest to highest
  const sortedThresholds = [...thresholds].sort(
    (a, b) => a.threshold - b.threshold
  );

  // Create ImageData at the canvas size
  const imageData = ctx.createImageData(aabbWidth, aabbHeight);
  const data = imageData.data;

  // Sample at intervals
  for (let x = 0; x < aabbWidth; x += sampleRate) {
    for (let y = 0; y < aabbHeight; y += sampleRate) {
      // Convert canvas coordinates to world coordinates for noise sampling
      const worldX = (x + aabb.left) / 100;
      const worldY = (y + aabb.top) / 100;

      const noiseValue = fractalNoise2D({ x: worldX, y: worldY });

      // Find the appropriate color based on thresholds
      let color = sortedThresholds[sortedThresholds.length - 1].color;
      for (const { threshold, color: thresholdColor } of sortedThresholds) {
        if (noiseValue <= threshold) {
          color = thresholdColor;
          break;
        }
      }

      // Fill the entire sample rate block with the same color
      for (let dx = 0; dx < sampleRate && x + dx < aabbWidth; dx++) {
        for (let dy = 0; dy < sampleRate && y + dy < aabbHeight; dy++) {
          const idx = ((y + dy) * aabbWidth + (x + dx)) * 4;
          data[idx] = color.r; // R
          data[idx + 1] = color.g; // G
          data[idx + 2] = color.b; // B
          data[idx + 3] = inSubplane ? 76 : 127; // Alpha
        }
      }
    }
  }

  // Draw the entire noise texture at once
  ctx.globalCompositeOperation = "overlay";
  ctx.putImageData(imageData, aabb.left, aabb.top);
  ctx.globalCompositeOperation = "source-over";
}

function noise(
  ctx: OffscreenCanvasRenderingContext2D,
  aabb: AABB,
  inSubplane: boolean,
  sampleRate = 100 // Default to sampling every 4 pixels
) {
  const aabbWidth = aabb.right - aabb.left;
  const aabbHeight = aabb.bottom - aabb.top;

  // Create ImageData at the canvas size
  const imageData = ctx.createImageData(aabbWidth, aabbHeight);
  const data = imageData.data;

  // Sample at intervals
  for (let x = 0; x < aabbWidth; x += sampleRate) {
    for (let y = 0; y < aabbHeight; y += sampleRate) {
      // Convert canvas coordinates to world coordinates for noise sampling
      const worldX = (x + aabb.left) / 100;
      const worldY = (y + aabb.top) / 100;

      const noiseValue = fractalNoise2D({ x: worldX, y: worldY });
      const intensity = Math.floor(((noiseValue + 1) / 2) * 255);

      // Fill the entire sample rate block with the same value
      for (let dx = 0; dx < sampleRate && x + dx < aabbWidth; dx++) {
        for (let dy = 0; dy < sampleRate && y + dy < aabbHeight; dy++) {
          const idx = ((y + dy) * aabbWidth + (x + dx)) * 4;
          data[idx] = intensity; // R
          data[idx + 1] = intensity; // G
          data[idx + 2] = intensity; // B
          data[idx + 3] = inSubplane ? 76 : 127; // Alpha
        }
      }
    }
  }

  // Draw the entire noise texture at once
  ctx.globalCompositeOperation = "overlay";
  ctx.putImageData(imageData, aabb.left, aabb.top);
  ctx.globalCompositeOperation = "source-over";
}

function randomDots(
  ctx: OffscreenCanvasRenderingContext2D,
  aabb: AABB,
  inSubplane: boolean
) {
  ctx.globalCompositeOperation = "source-over";

  const aabbArea = (aabb.right - aabb.left) * (aabb.bottom - aabb.top);

  addPoints({
    ctx,
    dotColor: Color.fromName("mediumgray"),
    aabb,
    pointDensity: 0.0005,
    opacityRange: inSubplane ? [0.1, 0.2] : [0.6, 8],
    sizeRange: [5, 15],
  });

  addPoints({
    ctx,
    dotColor: Color.fromName("lightgray"),
    aabb,
    pointDensity: 0.00005,
    opacityRange: inSubplane ? [0.1, 0.2] : [0.6, 1],
    sizeRange: [5, 15],
  });

  if (aabbArea > 100000) {
    addPoints({
      ctx,
      dotColor: Color.fromName("gold"),
      aabb,
      pointDensity: 0.000001,
      opacityRange: inSubplane ? [0.2, 0.4] : [0.8, 1],
      sizeRange: [20, 30],
    });

    addPoints({
      ctx,
      dotColor: Color.fromName("brown"),
      aabb,
      pointDensity: 0.000003,
      opacityRange: inSubplane ? [0.1, 0.2] : [0.6, 1],
      sizeRange: [5, 10],
    });
  }

  function addPoints({
    ctx,
    dotColor,
    aabb,
    pointDensity,
    opacityRange,
    sizeRange,
  }: {
    ctx: OffscreenCanvasRenderingContext2D;
    dotColor: Color;
    aabb: AABB;
    pointDensity: number;
    opacityRange: [number, number];
    sizeRange: [number, number];
  }) {
    const aabbWidth = aabb.right - aabb.left;
    const aabbHeight = aabb.bottom - aabb.top;
    const aabbArea = aabbWidth * aabbHeight;

    // Calculate points based on original area
    const numPoints = pointDensity * aabbArea;

    // Generate points in world space
    for (let i = 0; i < numPoints; i++) {
      const x = aabb.left + Math.random() * aabbWidth;
      const y = aabb.top + Math.random() * aabbHeight;
      // Random dot size
      const dotSize =
        Math.random() * (sizeRange[1] - sizeRange[0]) + sizeRange[0];
      ctx.fillStyle = dotColor.toRGB(
        Math.random() * (opacityRange[1] - opacityRange[0]) + opacityRange[0]
      );
      ctx.beginPath();
      ctx.arc(x, y, dotSize, 0, TWO_PI); // Note: removed textureScale multiplication
      ctx.fill();
    }
  }
}

export function createTextureInfo({
  aabb,
  color,
  inSubplane,
}: {
  aabb: AABB;
  color: Color;
  inSubplane: boolean;
}): {
  texture: ImageBitmap;
  textureScale: number;
  originalBounds: AABB;
} {
  const aabbWidth = aabb.right - aabb.left;
  const aabbHeight = aabb.bottom - aabb.top;

  // Calculate scale based on the larger dimension
  const maxDimension = Math.max(aabbWidth, aabbHeight);
  const textureScale = Math.min(1, MAX_CANVAS_SIZE / maxDimension);

  // Calculate canvas dimensions
  const canvasWidth = Math.ceil(aabbWidth * textureScale);
  const canvasHeight = Math.ceil(aabbHeight * textureScale);

  const textureCanvas = new OffscreenCanvas(canvasWidth, canvasHeight);
  const ctx = textureCanvas.getContext("2d");
  if (!ctx) {
    throw new Error("Failed to get canvas context");
  }

  // Scale the context to match the texture scale
  ctx.scale(textureScale, textureScale);

  // Translate to offset the AABB position
  ctx.translate(-aabb.left, -aabb.top);

  ctx.fillStyle = color.toRGB();
  ctx.fillRect(aabb.left, aabb.top, aabbWidth, aabbHeight);

  randomDots(ctx, aabb, inSubplane);
  // noise(ctx, aabb, inSubplane);
  // thresholdNoise(ctx, aabb, inSubplane);
  return {
    texture: textureCanvas.transferToImageBitmap(),
    textureScale,
    originalBounds: aabb,
  };
}
