import { Vector2D } from "../math/vector2D.ts";
import { Shape } from "../shapes/shape.ts";
import { VertexShapeBuilder } from "../shapes/builders/vertexShapeBuilder.ts";
import {
  BodyType,
  type BodyState,
  type LightSource,
  type Material,
} from "../models.ts";
import { Body } from "../plane/body.ts";
import type { FractalNoiseParams } from "../rendering/noise.ts";
import { getNoisePaths } from "../shapes/marchingSquares.ts";
import type { PlanarBase } from "./planarBase.ts";
import { Transform } from "../math/transform.ts";
import { getRandomMaterialColor, Color } from "../rendering/color.ts";

export type VarietyGenerationParams = {
  boundarySize: number;
  smallCount: number;
  mediumCount: number;
  largeCount: number;
  noiseShapes: boolean;
  cutShapes: boolean;
  planeId?: string;
};

export function getMaterial(): Material {
  return {
    density: 1,
    restitution: 0.4,
    staticFriction: 0.6,
    dynamicFriction: 0.4,
    color: getRandomMaterialColor(),
    destructible: true,
  };
}

// Add this helper at the top of the file
function getSecureRandom(): number {
  // Use crypto.getRandomValues which is available in both environments
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] / (0xffffffff + 1); // Convert to [0, 1) range
}

function getRandomPointInCircle(radius: number): [number, number] {
  const angle = getSecureRandom() * Math.PI * 2;
  const distance = getSecureRandom() * radius;
  return [Math.cos(angle) * distance, Math.sin(angle) * distance];
}

function getRandomPointInRectangle(size: number): [number, number] {
  return [
    getSecureRandom() * size - size / 2,
    getSecureRandom() * size - size / 2,
  ];
}

interface RandomShapeParams {
  count: number;
  boundarySize: number;
  minSize: number;
  maxSize: number;
}

interface RandomCapsuleParams extends RandomShapeParams {
  minSagitta: number;
  maxSagitta: number;
}

interface RandomPolygonParams extends RandomShapeParams {
  minSides: number;
  maxSides: number;
  lights: boolean;
}

interface RandomVelocityParams {
  minLinearVelocity: number;
  maxLinearVelocity: number;
  minAngularVelocity: number;
  maxAngularVelocity: number;
}

function getRandomVelocity(params: RandomVelocityParams): {
  velocity: Vector2D;
  angularVelocity: number;
} {
  const angle = getSecureRandom() * Math.PI * 2;
  const speed =
    getSecureRandom() * (params.maxLinearVelocity - params.minLinearVelocity) +
    params.minLinearVelocity;
  const angularVelocity =
    getSecureRandom() *
      (params.maxAngularVelocity - params.minAngularVelocity) +
    params.minAngularVelocity;
  return {
    velocity: new Vector2D(Math.cos(angle) * speed, Math.sin(angle) * speed),
    angularVelocity,
  };
}

function generateRandomRectangles(
  params: RandomShapeParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];
  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInCircle(boundarySize);
    const width = getSecureRandom() * (maxSize - minSize) + minSize;
    const height = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.rectangle({ width, height }),
        material: getMaterial(),
        type: BodyType.Standard,
        positionLocked: false,
        rotationLocked: false,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

function generateRandomCapsules(
  params: RandomCapsuleParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize, minSagitta, maxSagitta } =
    params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInRectangle(boundarySize);
    const width = getSecureRandom() * (maxSize - minSize) + minSize;
    const height = getSecureRandom() * (maxSize - minSize) + minSize;
    const widthSagittaFactor =
      getSecureRandom() * (maxSagitta - minSagitta) + minSagitta;
    const heightSagittaFactor =
      getSecureRandom() * (maxSagitta - minSagitta) + minSagitta;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    if (
      (widthSagittaFactor < 0 &&
        Math.abs(widthSagittaFactor) * 2 * width > height) ||
      (heightSagittaFactor < 0 &&
        Math.abs(heightSagittaFactor) * 2 * height > width)
    ) {
      continue;
    }

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.capsule({
          width,
          height,
          widthSagittaFactor,
          heightSagittaFactor,
        }),
        material: getMaterial(),
        type: BodyType.Standard,
        positionLocked: false,
        rotationLocked: false,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

function generateRandomPolygons(
  params: RandomPolygonParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize, minSides, maxSides } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  let light: LightSource | undefined = undefined;

  if (params.lights) {
    light = {
      radius: 500,
      glowRadius: 150,
      brightnessStops: [
        { position: 0, opacity: 1 },
        { position: 1, opacity: 0 },
      ],
      colorStops: [
        { position: 0, opacity: 0.4 },
        { position: 1, opacity: 0 },
      ],
      color: Color.fromHex("#e3bb64"),
    };
  }

  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInRectangle(boundarySize);
    const numSides =
      Math.floor(getSecureRandom() * (maxSides - minSides + 1)) + minSides;
    const radius = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.regularPolygon(radius, numSides),
        material: getMaterial(),
        type: BodyType.Standard,
        positionLocked: false,
        rotationLocked: false,
        inSubplane: false,
        light,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

function generateRandomCircles(
  params: RandomShapeParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];
  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInRectangle(boundarySize);
    const radius = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.circle(radius),
        material: getMaterial(),
        type: BodyType.Standard,
        positionLocked: false,
        rotationLocked: false,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

// function generateItems(
//   boundaryRadius: number,
//   number: number,
//   nextId: () => string
// ): {
//   body: Body;
//   state: BodyState;
// }[] {
//   const bodies: {
//     body: Body;
//     state: BodyState;
//   }[] = [];
//   for (let i = 0; i < number; i++) {
//     const [x, y] = getRandomPointInCircle(boundaryRadius);

//     const itemBody = new Body({
//       id: nextId(),
//       shape: VertexShapeBuilder.circle(100),
//       material: {
//         density: 1,
//         restitution: 0.4,
//         staticFriction: 0.6,
//         dynamicFriction: 0.4,
//         color: Color.fromName("blue"),
//         destructible: false,
//       },
//       type: BodyType.Item,
//       positionLocked: false,
//       rotationLocked: false,
//       inSubplane: false,
//       light: {
//         radius: 200,
//         glowRadius: 200,
//         brightnessStops: [
//           { position: 0, opacity: 0.5 },
//           { position: 1, opacity: 0 },
//         ],
//         colorStops: [
//           { position: 0, opacity: 0.3 },
//           { position: 1, opacity: 0 },
//         ],
//         color: Color.fromHex("#0000ff"),
//       },
//     });

//     itemBody.item = new Shooter({
//       cooldown: getSecureRandom() * 1000,
//       shots: Math.floor(getSecureRandom() * 20),
//     });

//     bodies.push({
//       body: itemBody,
//       state: {
//         position: [x, y, 0],
//         velocity: [1, 1, 0],
//       },
//     });
//   }
//   return bodies;
// }

interface SizeRangeParams {
  count: number;
  minSize: number;
  maxSize: number;
  maxLinearVelocity: number;
  maxAngularVelocity: number;
}

function generateShapesForSizeRange(
  planar: PlanarBase,
  params: SizeRangeParams,
  boundarySize: number,
  nextId: () => string,
  lights: boolean = false
): void {
  const shapeCount = Math.floor(params.count / 3);
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  // Rectangles
  bodies.push(
    ...generateRandomRectangles(
      {
        count: shapeCount,
        boundarySize,
        minSize: params.minSize,
        maxSize: params.maxSize,
        minLinearVelocity: 0,
        maxLinearVelocity: params.maxLinearVelocity,
        minAngularVelocity: 0,
        maxAngularVelocity: params.maxAngularVelocity,
      },
      nextId
    )
  );

  // Capsules
  bodies.push(
    ...generateRandomCapsules(
      {
        count: shapeCount,
        boundarySize,
        minSize: params.minSize,
        maxSize: params.maxSize,
        minSagitta: -0.3,
        maxSagitta: 1.0,
        minLinearVelocity: 0,
        maxLinearVelocity: params.maxLinearVelocity,
        minAngularVelocity: 0,
        maxAngularVelocity: params.maxAngularVelocity,
      },
      nextId
    )
  );

  // Polygons
  bodies.push(
    ...generateRandomPolygons(
      {
        count: shapeCount,
        boundarySize,
        minSize: params.minSize,
        maxSize: params.maxSize,
        minSides: 3,
        maxSides: 20,
        minLinearVelocity: 0,
        maxLinearVelocity: params.maxLinearVelocity,
        minAngularVelocity: 0,
        maxAngularVelocity: params.maxAngularVelocity,
        lights,
      },
      nextId
    )
  );

  // Circles
  bodies.push(
    ...generateRandomCircles(
      {
        count: shapeCount,
        boundarySize,
        minSize: params.minSize,
        maxSize: params.maxSize,
        minLinearVelocity: 0,
        maxLinearVelocity: params.maxLinearVelocity,
        minAngularVelocity: 0,
        maxAngularVelocity: params.maxAngularVelocity,
      },
      nextId
    )
  );

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);
  }
}

export function generateVarietyWithBoundary({
  planar,
  boundarySize,
  small,
  medium,
  large,
  noiseShapes,
  cutShapes,
}: {
  planar: PlanarBase;
  boundarySize: number;
  small: SizeRangeParams;
  medium: SizeRangeParams;
  large: SizeRangeParams;
  noiseShapes: boolean;
  cutShapes: boolean;
}): void {
  let id = 0;
  const nextId = () => {
    return `${planar.id}.${id++}`;
  };

  // Generate shapes for each size range
  generateShapesForSizeRange(planar, small, boundarySize, nextId, true);
  generateShapesForSizeRange(planar, medium, boundarySize, nextId);
  generateShapesForSizeRange(planar, large, boundarySize, nextId);

  if (cutShapes) {
    generateCutShapes({
      planar,
      minRadius: 100000,
      maxRadius: 100000,
      minCuts: 5000,
      maxCuts: 5000,
      count: 1,
      boundarySize,
    });
  }

  if (noiseShapes) {
    generateNoiseShapes({
      planar,
      gridSize: 100,
      spaceSize: 100000,
      noiseParams: {
        baseScale: 0.00001,
        lacunarity: 2.5,
        octaves: 6,
        persistence: 0.5,
      },
      boundarySize,
      limit: 100,
      nextId,
      threshold: 50,
      velParams: {
        minLinearVelocity: 1,
        maxLinearVelocity: 10,
        minAngularVelocity: 0,
        maxAngularVelocity: 0.01,
      },
    });
  }
}

export function generateVariety(
  planar: PlanarBase,
  params: VarietyGenerationParams
): void {
  const {
    boundarySize,
    smallCount,
    mediumCount,
    largeCount,
    noiseShapes,
    cutShapes,
  } = params;

  generateVarietyWithBoundary({
    planar: planar,
    boundarySize,
    small: {
      count: smallCount,
      minSize: 50,
      maxSize: 100,
      maxLinearVelocity: 100,
      maxAngularVelocity: 1,
    },
    medium: {
      count: mediumCount,
      minSize: 1000,
      maxSize: 2000,
      maxLinearVelocity: 20,
      maxAngularVelocity: 0.01,
    },
    large: {
      count: largeCount,
      minSize: 2000,
      maxSize: 5000,
      maxLinearVelocity: 10,
      maxAngularVelocity: 0.01,
    },
    noiseShapes,
    cutShapes,
  });
}

export function generateCutShape(
  planar: PlanarBase,
  position: [number, number],
  baseRadius: number,
  numCuts: number,
  nextId: () => string
): void {
  const subplane = VertexShapeBuilder.circle(baseRadius);
  const base = VertexShapeBuilder.circle(baseRadius);

  let resultShapes: Shape[] = [base];

  const cutSizeFactorRange = [0.01, 0.1];

  for (let i = 0; i < numCuts; i++) {
    const [x, y] = getRandomPointInCircle(baseRadius);
    const cutSizeFactor =
      getSecureRandom() * (cutSizeFactorRange[1] - cutSizeFactorRange[0]) +
      cutSizeFactorRange[0];
    const cut = VertexShapeBuilder.circle(
      getSecureRandom() * baseRadius * cutSizeFactor
    );
    // const angle = getSecureRandom() * Math.PI * 2;

    const newResults: Shape[] = [];

    for (const shape of resultShapes) {
      const transformInfo = Transform.getRelativeTransformInfo({
        guest: new Transform(x, y, 0, 0, 1),
        host: new Transform(0, 0, 0, 0, 1),
      });

      const results = shape.subtract(cut, transformInfo);
      if (!results || results.length === 0) {
        // If subtraction fails or returns empty, keep the original shape
        newResults.push(shape);
      } else {
        newResults.push(...results.map((r) => r.shape));
      }
    }

    if (newResults.length === 0) {
      console.warn(
        `Cut ${i} eliminated all shapes, reverting to previous state`
      );
      continue;
    }

    resultShapes = newResults;
  }

  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  const material = getMaterial();

  for (let i = 0; i < resultShapes.length; i++) {
    if (resultShapes[i].area < 200) {
      continue;
    }

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: resultShapes[i],
        material,
        type: BodyType.Standard,
        positionLocked: false,
        rotationLocked: false,
        inSubplane: false,
      }),
      state: {
        position: [
          resultShapes[i].centroid.x + position[0],
          resultShapes[i].centroid.y + position[1],
          0,
        ],
        velocity: [10, 10, 0.001],
      },
    });
  }

  const subplaneMaterial = getMaterial();
  subplaneMaterial.color = Color.fromName("darkgray");

  const subplaneBody = new Body({
    id: nextId(),
    shape: subplane,
    material: subplaneMaterial,
    type: BodyType.Standard,
    positionLocked: false,
    rotationLocked: false,
    inSubplane: true,
  });

  planar.addBody(subplaneBody);
  planar.loadBody({
    body: subplaneBody,
    state: {
      position: [position[0], position[1], 0],
      velocity: [0, 0, 0],
    },
  });

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);

    // If the area of the body is above a threshold, add it to the composite
    if (body.body.shape.area > 100000) {
      planar.plane.bodyStore.composeBodies(subplaneBody, body.body);
    }
  }
}

export function generateCutShapes({
  planar,
  minRadius,
  maxRadius,
  minCuts,
  maxCuts,
  count,
  boundarySize,
}: {
  planar: PlanarBase;
  minRadius: number;
  maxRadius: number;
  minCuts: number;
  maxCuts: number;
  count: number;
  boundarySize: number;
}): void {
  let id = 0;

  const nextId = () => {
    return `cut-circle-piece-${id++}`;
  };

  for (let i = 0; i < count; i++) {
    const radius = getSecureRandom() * (maxRadius - minRadius) + minRadius;
    const cuts =
      Math.floor(getSecureRandom() * (maxCuts - minCuts + 1)) + minCuts;
    const position = getRandomPointInRectangle(boundarySize);
    generateCutShape(planar, position, radius, cuts, nextId);
  }
}

export function generateNoiseShapes({
  planar,
  gridSize,
  spaceSize,
  noiseParams,
  threshold,
  limit,
  nextId,
  boundarySize,
  velParams,
}: {
  planar: PlanarBase;
  gridSize: number;
  spaceSize: number;
  noiseParams: FractalNoiseParams;
  threshold: number;
  limit: number;
  nextId: () => string;
  boundarySize: number;
  velParams: RandomVelocityParams;
}): void {
  let paths = getNoisePaths({
    gridSize,
    height: spaceSize,
    width: spaceSize,
    noiseParams,
    threshold,
    limit,
  });

  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  for (const path of paths) {
    let pathShape = VertexShapeBuilder.fromPath(path);

    if (pathShape.area < 0) {
      pathShape = pathShape.inverse();
    }

    pathShape.recenter();
    const [x, y] = getRandomPointInRectangle(boundarySize);
    const { velocity, angularVelocity } = getRandomVelocity(velParams);

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: pathShape,
        material: getMaterial(),
        type: BodyType.Standard,
        positionLocked: false,
        rotationLocked: false,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);
  }
}
