import { nanoid } from "nanoid";
import type { PointOnBodySurface } from "../models";
import { SurfacePointDataStride, SurfacePointOffset } from "./plane";
import type { Body } from "./body";
import { SurfacePoint } from "./surfacePoint";

export class SurfacePointStore {
  surfacePointIds: string[] = [];
  surfacePoints: (SurfacePoint | undefined)[] = [];
  surfacePointStates: Float32Array;
  nextSurfacePointIndex: number = 0;
  numSurfacePoints: number = 0;
  bodies: (Body | undefined)[] = [];
  freeSurfacePointIndices: number[] = [];

  constructor({ surfacePointStates }: { surfacePointStates: Float32Array }) {
    this.surfacePointStates = surfacePointStates;
  }

  update(): void {
    for (let i = 0; i < this.nextSurfacePointIndex; i++) {
      const body = this.bodies[i];
      if (!body) {
        continue;
      }

      this.updateSurfacePoint(i, body);
    }
  }

  createSurfacePoint(pointOnSurface: PointOnBodySurface): SurfacePoint | null {
    const body = pointOnSurface.body;
    if (!body.isLoaded()) {
      console.error("Tried to add point on body to unloaded body");
      return null;
    }

    const index =
      this.freeSurfacePointIndices.length > 0
        ? this.freeSurfacePointIndices.pop()!
        : this.nextSurfacePointIndex++;

    const stride = index * SurfacePointDataStride;

    const localPoint = pointOnSurface.perimeterPoint.position;

    this.surfacePointStates[stride + SurfacePointOffset.LocalX] = localPoint.x;
    this.surfacePointStates[stride + SurfacePointOffset.LocalY] = localPoint.y;

    // Get the body's local centroid
    const centroidX = body.shape.centroid.x;
    const centroidY = body.shape.centroid.y;

    // Adjust the local point position relative to centroid
    const adjustedLocalX = localPoint.x - centroidX;
    const adjustedLocalY = localPoint.y - centroidY;

    this.surfacePointStates[stride + SurfacePointOffset.LocalRX] =
      adjustedLocalX;
    this.surfacePointStates[stride + SurfacePointOffset.LocalRY] =
      adjustedLocalY;

    this.bodies[index] = body;

    this.updateSurfacePoint(index, body);

    this.numSurfacePoints++;

    const id = nanoid();
    this.surfacePointIds[index] = id;

    const surfacePoint = new SurfacePoint({
      id,
      body: pointOnSurface.body,
      index,
      stateArray: this.surfacePointStates,
      onTransfer: (newBody: Body) => {
        return this.transferSurfacePoint(id, index, newBody);
      },
      onDestroy: (id: string, index: number) => {
        this.destroySurfacePoint(id, index);
      },
    });

    this.surfacePoints[index] = surfacePoint;

    body.storeSurfacePoint({
      segment: pointOnSurface.perimeterPoint.segment,
      tOrAngle: pointOnSurface.perimeterPoint.tOrAngle,
      surfacePoint,
    });

    return surfacePoint;
  }

  destroySurfacePoint(id: string, index: number): void {
    if (index < 0 || index >= this.nextSurfacePointIndex) {
      console.warn(`Invalid surface point index: ${index}`);
      return;
    }

    const existingId = this.surfacePointIds[index];
    if (existingId !== id && existingId !== "") {
      console.warn(`Surface point id mismatch: ${id} !== ${existingId}`);
      return;
    }

    const stride = index * SurfacePointDataStride;
    for (let i = 0; i < SurfacePointDataStride; i++) {
      this.surfacePointStates[stride + i] = 0;
    }
    this.bodies[index] = undefined;
    this.freeSurfacePointIndices.push(index);

    this.surfacePointIds[index] = "";
    this.surfacePoints[index] = undefined;

    this.numSurfacePoints--;
  }

  transferSurfacePoint(id: string, index: number, newBody: Body): boolean {
    if (index < 0 || index >= this.nextSurfacePointIndex) {
      console.warn(`Invalid surface point index: ${index}`);
      return false;
    }

    const existingId = this.surfacePointIds[index];
    if (existingId !== id && existingId !== "") {
      console.warn(`Surface point id mismatch: ${id} !== ${existingId}`);
      return false;
    }

    if (!newBody.isLoaded()) {
      console.warn(`Tried to transfer point on body to unloaded body`);
      this.destroySurfacePoint(id, index);
      return false;
    }

    const surfacePoint = this.surfacePoints[index];
    if (!surfacePoint) {
      console.warn(`Surface point not found: ${id}`);
      return false;
    }

    this.bodies[index] = newBody;

    const stride = index * SurfacePointDataStride;

    const centroidX = newBody.shape.centroid.x;
    const centroidY = newBody.shape.centroid.y;

    const localX = this.surfacePointStates[stride + SurfacePointOffset.LocalX];
    const localY = this.surfacePointStates[stride + SurfacePointOffset.LocalY];

    const adjustedLocalX = localX - centroidX;
    const adjustedLocalY = localY - centroidY;

    this.surfacePointStates[stride + SurfacePointOffset.LocalRX] =
      adjustedLocalX;
    this.surfacePointStates[stride + SurfacePointOffset.LocalRY] =
      adjustedLocalY;

    this.updateSurfacePoint(index, newBody);

    return true;
  }

  updateSurfacePoint(i: number, body: Body): void {
    if (!body.isLoaded()) {
      console.warn(`Tried to update surface point on unloaded body`);
      return;
    }
    const bodyState = body.getFullState(true);
    const stride = i * SurfacePointDataStride;
    const {
      x,
      y,
      sinRotation,
      cosRotation,
      velocityX,
      velocityY,
      angularVelocity,
    } = bodyState;

    // Get the point's local offset
    const localRX =
      this.surfacePointStates[stride + SurfacePointOffset.LocalRX];
    const localRY =
      this.surfacePointStates[stride + SurfacePointOffset.LocalRY];

    // Calculate and store r vector (relative position in world space)
    let rx = localRX * cosRotation - localRY * sinRotation;
    let ry = localRX * sinRotation + localRY * cosRotation;

    if (bodyState.compositeR) {
      const { x: compositeOffsetX, y: compositeOffsetY } = bodyState.compositeR;

      rx += compositeOffsetX;
      ry += compositeOffsetY;
    }

    this.surfacePointStates[stride + SurfacePointOffset.RX] = rx;
    this.surfacePointStates[stride + SurfacePointOffset.RY] = ry;

    // Update position
    this.surfacePointStates[stride + SurfacePointOffset.PositionX] = rx + x;
    this.surfacePointStates[stride + SurfacePointOffset.PositionY] = ry + y;

    // Update velocities
    this.surfacePointStates[stride + SurfacePointOffset.VelocityX] =
      velocityX - angularVelocity * ry;
    this.surfacePointStates[stride + SurfacePointOffset.VelocityY] =
      velocityY + angularVelocity * rx;
  }
}
