import type { AABB, RelativeTransformInfo } from "../models.ts";
import { Vector2D } from "./vector2D.ts";

export class Transform {
  x: number;
  y: number;
  angle: number;
  sin: number;
  cos: number;

  constructor(x: number, y: number, angle: number, sin: number, cos: number) {
    this.x = x;
    this.y = y;
    this.angle = angle;
    this.sin = sin;
    this.cos = cos;
  }

  apply(point: Vector2D): Vector2D {
    return new Vector2D(
      this.x + this.cos * point.x - this.sin * point.y,
      this.y + this.sin * point.x + this.cos * point.y
    );
  }

  applyInPlace(point: Vector2D, target: Vector2D): void {
    target.x = this.x + this.cos * point.x - this.sin * point.y;
    target.y = this.y + this.sin * point.x + this.cos * point.y;
  }

  applyToArray(point: [number, number]): [number, number] {
    return [
      this.x + this.cos * point[0] - this.sin * point[1],
      this.y + this.sin * point[0] + this.cos * point[1],
    ];
  }

  applyInverse(point: Vector2D): Vector2D {
    const subtractedX = point.x - this.x;
    const subtractedY = point.y - this.y;
    return new Vector2D(
      subtractedX * this.cos + subtractedY * this.sin,
      subtractedY * this.cos - subtractedX * this.sin
    );
  }

  // applyToAABB(aabb: AABB): AABB {
  //   const tx = this.x;
  //   const ty = this.y;
  //   const cx = this.cos;
  //   const sx = this.sin;

  //   const left = aabb.left;
  //   const right = aabb.right;
  //   const top = aabb.top;
  //   const bottom = aabb.bottom;

  //   const x1 = tx + cx * left - sx * top; // top-left
  //   const y1 = ty + sx * left + cx * top;

  //   const x2 = tx + cx * right - sx * top; // top-right
  //   const y2 = ty + sx * right + cx * top;

  //   const x3 = tx + cx * left - sx * bottom; // bottom-left
  //   const y3 = ty + sx * left + cx * bottom;

  //   const x4 = tx + cx * right - sx * bottom; // bottom-right
  //   const y4 = ty + sx * right + cx * bottom;

  //   return {
  //     left: Math.min(x1, x2, x3, x4),
  //     right: Math.max(x1, x2, x3, x4),
  //     top: Math.min(y1, y2, y3, y4),
  //     bottom: Math.max(y1, y2, y3, y4),
  //   };
  // }

  static identity(): Transform {
    return new Transform(0, 0, 0, 0, 1);
  }

  static getRelativeTransformInfo({
    guest,
    host,
  }: {
    guest: Transform;
    host: Transform;
  }): RelativeTransformInfo {
    const guestToHostX =
      -host.x * host.cos -
      host.y * host.sin +
      host.cos * guest.x +
      host.sin * guest.y;
    const guestToHostY =
      -host.y * host.cos +
      host.x * host.sin -
      host.sin * guest.x +
      host.cos * guest.y;

    const guestToHostAngle = guest.angle - host.angle;
    const guestToHostSin = Math.sin(guestToHostAngle);
    const guestToHostCos = Math.cos(guestToHostAngle);

    const hostToGuestX =
      -guest.x * guest.cos -
      guest.y * guest.sin +
      guest.cos * host.x +
      guest.sin * host.y;
    const hostToGuestY =
      -guest.y * guest.cos +
      guest.x * guest.sin -
      guest.sin * host.x +
      guest.cos * host.y;

    const hostToGuestAngle = host.angle - guest.angle;
    const hostToGuestSin = Math.sin(hostToGuestAngle);
    const hostToGuestCos = Math.cos(hostToGuestAngle);

    return {
      guestToHost: new Transform(
        guestToHostX,
        guestToHostY,
        guestToHostAngle,
        guestToHostSin,
        guestToHostCos
      ),
      hostToGuest: new Transform(
        hostToGuestX,
        hostToGuestY,
        hostToGuestAngle,
        hostToGuestSin,
        hostToGuestCos
      ),
      hostToGlobal: host,
    };
  }

  static getRelativeTransform(guest: Transform, host: Transform): Transform {
    const guestToHostX =
      -host.x * host.cos -
      host.y * host.sin +
      host.cos * guest.x +
      host.sin * guest.y;
    const guestToHostY =
      -host.y * host.cos +
      host.x * host.sin -
      host.sin * guest.x +
      host.cos * guest.y;

    const guestToHostAngle = guest.angle - host.angle;
    const guestToHostSin = Math.sin(guestToHostAngle);
    const guestToHostCos = Math.cos(guestToHostAngle);

    return new Transform(
      guestToHostX,
      guestToHostY,
      guestToHostAngle,
      guestToHostSin,
      guestToHostCos
    );
  }
}
