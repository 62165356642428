import { nanoid } from "nanoid";
import { VertexShapeBuilder } from "../shapes/builders/vertexShapeBuilder.ts";
import type { Player } from "./player.ts";
import { BodyType, type Material, type PointOnBodySurface } from "../models.ts";
import { Body } from "./body.ts";
import type { Vector2D } from "../math/vector2D.ts";
import { Color } from "../rendering/color.ts";
import type { Plane } from "./plane.ts";
import { Spring } from "./spring.ts";
import { Pin } from "./pin.ts";
import { SurfacePoint } from "./surfacePoint.ts";
import { Tether } from "./tether.ts";
export type SelectPointConfig = {
  reach: number;
  includeAbdomen: boolean;
  eligibilityCheck: (body: Body) => boolean;
};

export interface ActionUpdateInfo {
  dt: number;
  now: number;
  holdTime: number;
  selectPoint: {
    distanceFromLimb: number;
    pointOnSurface: PointOnBodySurface;
  } | null;
  abdomenPosition: Vector2D;
  abdomenVelocity: Vector2D;
  limbPosition: Vector2D;
  limbVelocity: Vector2D;
  limbDirection: Vector2D;
}

export interface ActionResult {
  success: boolean;
  ongoing: boolean;
}

export interface Action {
  player: Player;
  plane: Plane;
  cooldown?: number;
  selectPointConfig?: SelectPointConfig;
  activate(info: ActionUpdateInfo): ActionResult;
  hold(info: ActionUpdateInfo): ActionResult;
  release(info: ActionUpdateInfo): void;
  cancel(): void;
  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean;
  getDisplayText(): string;
}

export const DEFAULT_PROJECTILE_MATERIAL: Material = {
  density: 1,
  restitution: 1,
  staticFriction: 0,
  dynamicFriction: 0,
  color: Color.fromName("red"),
  destructible: true,
};

export class Shoot implements Action {
  player: Player;
  plane: Plane;
  cooldown: number;
  reach: number = 0;
  projectileMaterial: Material;
  shots: number;

  constructor({
    player,
    projectileMaterial,
    cooldown,
    shots,
  }: {
    player: Player;
    projectileMaterial?: Material;
    cooldown: number;
    shots: number;
  }) {
    this.player = player;
    this.plane = player.plane;
    this.projectileMaterial = projectileMaterial ?? DEFAULT_PROJECTILE_MATERIAL;
    this.cooldown = cooldown;
    this.shots = shots;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    if (this.shots < 1) {
      return { success: false, ongoing: false };
    }

    this.shots = this.shots - 1;

    const projectileShape = VertexShapeBuilder.circle(50);

    const projectileBody = new Body({
      id: nanoid(),
      shape: projectileShape,
      material: this.projectileMaterial,
      type: BodyType.Standard,
      positionLocked: false,
      rotationLocked: false,
      inSubplane: false,
      light: {
        radius: 1000,
        glowRadius: 150,
        brightnessStops: [
          { position: 0, opacity: 1 },
          { position: 0.75, opacity: 1 },
          { position: 1, opacity: 0 },
        ],
        colorStops: [
          { position: 0, opacity: 0.3 },
          { position: 0.75, opacity: 0.3 },
          { position: 1, opacity: 0 },
        ],
        color: Color.fromHex("#e3bb64"),
      },
    });

    const linearVelocity = info.limbDirection.scale(-10000);

    const position = info.limbPosition.add(info.limbDirection.scale(-20));

    const momentum = linearVelocity.scale(projectileBody.mass);

    const impulseOnLimbBody = momentum.scale(-0.15);

    const finalLinearVelocity = info.limbVelocity.add(linearVelocity);

    this.player.limb.applyLinearImpulse(impulseOnLimbBody);

    projectileBody.addCollisionTrigger((otherBody, collision) => {
      const bodies = this.plane.bodyStore.cutCircle(
        collision.pointOfContact,
        650,
        otherBody
      );

      const impulseMagnitude = 500000;
      const angularImpulseMagnitude = 10000;

      // Apply impulse to each body
      for (const body of bodies) {
        // The impulse vector is between the centroid of the cut and the center of the body
        const r = collision.pointOfContact.subtract(body.getCenter());
        const impulse = r.scale(-impulseMagnitude);
        body.applyLinearImpulse(impulse);
        const angularImpulse = finalLinearVelocity.scale(
          angularImpulseMagnitude
        );
        body.applyAngularImpulse(r, angularImpulse);
      }

      // Delete projectile after it collides
      this.plane.bodyStore.removeBody(projectileBody.id);
      this.plane.bodyStore.unloadBody(projectileBody.index);
    });

    this.plane.bodyStore.addBody(projectileBody);

    this.plane.bodyStore.loadBody({
      body: projectileBody,
      state: {
        position: [position.x, position.y, 0],
        velocity: [finalLinearVelocity.x, finalLinearVelocity.y, 0],
      },
    });

    setTimeout(() => {
      if (projectileBody.isLoaded()) {
        this.plane.bodyStore.removeBody(projectileBody.id);
        this.plane.bodyStore.unloadBody(projectileBody.index);
      }
    }, 10000);

    return { success: true, ongoing: false };
  }

  getDisplayText(): string {
    return `Shoot (shots: ${this.shots})`;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    return true;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return this.activate(info);
  }

  release(info: ActionUpdateInfo): void {
    return;
  }

  cancel(): void {
    return;
  }
}

export class Cut implements Action {
  plane: Plane;
  player: Player;
  cooldown: number = 0;
  cutRadius: number;
  cutCenter: SurfacePoint | null = null;
  cutDuration: number;
  selectPointConfig: SelectPointConfig = {
    reach: 250,
    includeAbdomen: false,
    eligibilityCheck: (body: Body) => body.material.destructible,
  };

  constructor({
    player,
    cutRadius,
    cutDuration,
  }: {
    player: Player;
    cutRadius: number;
    cutDuration: number;
  }) {
    this.player = player;
    this.plane = player.plane;
    this.cutRadius = cutRadius;
    this.cutDuration = cutDuration;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.cutCenter = surfacePoint;

    return { success: true, ongoing: true };
  }

  hold(info: ActionUpdateInfo): ActionResult {
    if (!this.cutCenter) return { success: false, ongoing: false };

    const cutCenterInfo = this.cutCenter.getPositionAndBody();

    if (!cutCenterInfo) {
      this.cutCenter = null;
      return { success: false, ongoing: false };
    }

    // Check if the point is still in reach
    if (
      cutCenterInfo.position.distanceTo(info.limbPosition) >
      this.selectPointConfig.reach
    ) {
      this.cutCenter.destroy();
      this.cutCenter = null;
      return { success: false, ongoing: false };
    }

    // If the cut duration has passed, perform the cut
    if (info.holdTime > this.cutDuration) {
      this.cutCenter.destroy();

      this.plane.bodyStore.cutCircle(
        cutCenterInfo.position,
        this.cutRadius,
        cutCenterInfo.body
      );

      this.cutCenter = null;
      return { success: true, ongoing: false };
    }

    return { success: true, ongoing: true };
  }

  cancel(): void {
    if (!this.cutCenter) return;
    this.cutCenter.destroy();
    this.cutCenter = null;
  }

  release(info: ActionUpdateInfo): void {
    this.cancel();
  }

  getDisplayText(): string {
    return `Cut (radius: ${this.cutRadius})`;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    let renderProgress = false;
    let cutLocation: {
      position: Vector2D;
      body: Body;
    } | null = null;

    if (this.cutCenter) {
      cutLocation = this.cutCenter.getPositionAndBody();
      renderProgress = true;
    } else if (info.selectPoint) {
      cutLocation = {
        body: info.selectPoint.pointOnSurface.body,
        position: info.selectPoint.pointOnSurface.globalPosition,
      };
    }

    if (!cutLocation) {
      return true;
    }

    const path = cutLocation.body.shape.getPath();
    const transform = cutLocation.body.getTransform();

    ctx.save();

    ctx.translate(transform.x, transform.y);
    ctx.rotate(transform.angle);
    ctx.clip(path);

    ctx.rotate(-transform.angle);
    ctx.translate(-transform.x, -transform.y);

    if (renderProgress) {
      // Draw the progress circle if we're currently cutting
      const progress = info.holdTime / this.cutDuration;
      const currentRadius = Math.min(progress, 1) * this.cutRadius;

      if (currentRadius > 0) {
        ctx.beginPath();
        ctx.arc(
          cutLocation.position.x,
          cutLocation.position.y,
          currentRadius,
          0,
          Math.PI * 2
        );
        ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
        ctx.fill();
      }
    }

    // Draw the cutting circle outline
    ctx.beginPath();
    ctx.arc(
      cutLocation.position.x,
      cutLocation.position.y,
      this.cutRadius,
      0,
      Math.PI * 2
    );
    ctx.strokeStyle = "white";
    ctx.lineWidth = 10;
    ctx.stroke();

    ctx.restore();

    return false;
  }
}

export class Mark implements Action {
  player: Player;
  plane: Plane;
  surfacePoints: Set<SurfacePoint> = new Set();
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 500,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.surfacePoints.add(surfacePoint);

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Mark (marks: ${this.surfacePoints.size})`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    for (const surfacePoint of this.surfacePoints) {
      const point = surfacePoint.getPosition();
      if (!point) {
        this.surfacePoints.delete(surfacePoint);
        continue;
      }
      // Render as red circle
      ctx.beginPath();
      ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
      ctx.fillStyle = "red";
      ctx.fill();
    }

    return true;
  }
}

export class Grab implements Action {
  player: Player;
  plane: Plane;
  cooldown: number = 0;
  reach: number = 0;
  body: Body;
  grabTriggerId: string | null = null;

  constructor(player: Player, body: Body) {
    this.player = player;
    this.plane = player.plane;
    this.body = body;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    if (this.grabTriggerId) {
      return { success: true, ongoing: true };
    }

    this.grabTriggerId = this.body.addCollisionTrigger(
      (otherBody, collision) => {
        if (this.body.composite !== null) {
          return;
        }

        // Check if the other body is in a composite with body1 and remove it if it is
        if (
          otherBody.composite !== null &&
          otherBody.composite === this.body.composite
        ) {
          otherBody.removeFromComposite();
        }

        this.plane.bodyStore.composeBodies(this.body, otherBody);
      }
    );

    return { success: true, ongoing: true };
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: true, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    this.cancel();
  }

  cancel(): void {
    if (!this.grabTriggerId) {
      return;
    }

    this.body.removeCollisionTrigger(this.grabTriggerId);

    this.grabTriggerId = null;

    this.body.removeFromComposite();
  }

  getDisplayText(): string {
    return `Grab`;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    return true;
  }
}

export class PlaceSpring implements Action {
  player: Player;
  plane: Plane;
  storedSurfacePoint: SurfacePoint | null = null;
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 100,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.storedSurfacePoint = surfacePoint;

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Place Spring`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    const surfacePoint = this.storedSurfacePoint;

    if (!surfacePoint || surfacePoint.body === null) {
      return;
    }

    const pointInfo = info.selectPoint;

    if (!pointInfo) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    const newSurfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!newSurfacePoint) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    this.plane.springs.push(new Spring(surfacePoint, newSurfacePoint));

    this.storedSurfacePoint = null;

    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    if (!this.storedSurfacePoint) {
      return true;
    }

    const point = this.storedSurfacePoint.getPosition();
    if (!point) {
      this.storedSurfacePoint = null;
      return true;
    }

    // Render as red circle
    ctx.beginPath();
    ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
    ctx.fillStyle = "red";
    ctx.fill();

    return true;
  }
}

export class PlacePin implements Action {
  player: Player;
  plane: Plane;
  storedSurfacePoint: SurfacePoint | null = null;
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 100,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.storedSurfacePoint = surfacePoint;

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Place Pin`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    const surfacePoint = this.storedSurfacePoint;

    if (!surfacePoint || surfacePoint.body === null) {
      return;
    }

    const pointInfo = info.selectPoint;

    if (!pointInfo) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    const newSurfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!newSurfacePoint || newSurfacePoint.body === null) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    this.plane.pins.push(
      new Pin(surfacePoint, newSurfacePoint, {
        desiredLength: surfacePoint
          .getPosition()!
          .distanceTo(newSurfacePoint.getPosition()!),
        restitution: 0.5,
        baumgarteScale: 1000,
        slop: 0.01,
      })
    );

    this.storedSurfacePoint = null;

    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    if (!this.storedSurfacePoint) {
      return true;
    }

    const point = this.storedSurfacePoint.getPosition();
    if (!point) {
      this.storedSurfacePoint = null;
      return true;
    }

    // Render as red circle
    ctx.beginPath();
    ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
    ctx.fillStyle = "red";
    ctx.fill();

    return true;
  }
}

export class PlaceTether implements Action {
  player: Player;
  plane: Plane;
  storedSurfacePoint: SurfacePoint | null = null;
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 100,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.storedSurfacePoint = surfacePoint;

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Place Tether`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    const surfacePoint = this.storedSurfacePoint;

    if (!surfacePoint || surfacePoint.body === null) {
      return;
    }

    const pointInfo = info.selectPoint;

    if (!pointInfo) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    const newSurfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!newSurfacePoint || newSurfacePoint.body === null) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    this.plane.tethers.push(
      new Tether(surfacePoint, newSurfacePoint, {
        maxLength: surfacePoint
          .getPosition()!
          .distanceTo(newSurfacePoint.getPosition()!),
        restitution: 0.1,
        baumgarteScale: 0.7,
        slop: 0.1,
      })
    );

    this.storedSurfacePoint = null;

    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    if (!this.storedSurfacePoint) {
      return true;
    }

    const point = this.storedSurfacePoint.getPosition();
    if (!point) {
      this.storedSurfacePoint = null;
      return true;
    }

    // Render as red circle
    ctx.beginPath();
    ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
    ctx.fillStyle = "red";
    ctx.fill();

    return true;
  }
}
