import type { Vector2D } from "../math/vector2D";

export class Camera {
  x: number;
  y: number;
  zoom: number;
  width: number;
  height: number;
  followSpeed: number = 1.5;
  followTargetMargin: number = 0.1;
  minZoom: number = 0.001;
  maxZoom: number = 10;
  zoomSpeed: number = 0.1;

  fullDetailThreshold = 0.002;
  // lodThreshold = 0.0007;
  // fullDetailThreshold = 0;
  lodThreshold = 0;

  constructor(cameraWidth: number, cameraHeight: number) {
    this.zoom = 0.25;
    this.width = cameraWidth;
    this.height = cameraHeight;
    this.x = -this.width / (2 * this.zoom);
    this.y = -this.height / (2 * this.zoom);
  }

  pan(dx: number, dy: number): void {
    this.x += dx / this.zoom;
    this.y += dy / this.zoom;
  }

  zoomTo(newZoom: number): number {
    const clampedZoom = Math.max(this.minZoom, Math.min(this.maxZoom, newZoom));
    this.zoom = clampedZoom;
    return clampedZoom;
  }

  zoomBy(factor: number): number {
    return this.zoomTo(this.zoom * factor);
  }

  screenToWorld(screenX: number, screenY: number): [number, number] {
    return [
      (screenX - this.width / 2) / this.zoom + this.x,
      (screenY - this.height / 2) / this.zoom + this.y,
    ];
  }

  updateSize(width: number, height: number): void {
    this.width = width;
    this.height = height;
  }

  follow(targetPosition: Vector2D, dt: number): void {
    // Calculate desired position with smooth follow
    let newX = this.x + (targetPosition.x - this.x) * this.followSpeed * dt;
    let newY = this.y + (targetPosition.y - this.y) * this.followSpeed * dt;

    // Calculate viewport dimensions in world space
    const viewportWidth = this.width / this.zoom;
    const viewportHeight = this.height / this.zoom;

    // Calculate margins in world space
    const marginX = viewportWidth * this.followTargetMargin;
    const marginY = viewportHeight * this.followTargetMargin;

    // Calculate bounds
    const minX = newX - viewportWidth / 2 + marginX;
    const maxX = newX + viewportWidth / 2 - marginX;
    const minY = newY - viewportHeight / 2 + marginY;
    const maxY = newY + viewportHeight / 2 - marginY;

    // If target is outside margins, snap camera to keep target at margin
    if (targetPosition.x < minX) {
      newX = targetPosition.x + viewportWidth / 2 - marginX;
    } else if (targetPosition.x > maxX) {
      newX = targetPosition.x - viewportWidth / 2 + marginX;
    }

    if (targetPosition.y < minY) {
      newY = targetPosition.y + viewportHeight / 2 - marginY;
    } else if (targetPosition.y > maxY) {
      newY = targetPosition.y - viewportHeight / 2 + marginY;
    }

    this.x = newX;
    this.y = newY;
  }

  getWorldBounds(margin: number = 0): {
    left: number;
    right: number;
    top: number;
    bottom: number;
  } {
    const topLeft = this.screenToWorld(0, 0);
    const bottomRight = this.screenToWorld(this.width, this.height);

    return {
      left: topLeft[0] - margin,
      top: topLeft[1] - margin,
      right: bottomRight[0] + margin,
      bottom: bottomRight[1] + margin,
    };
  }
}
