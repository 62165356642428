import { Vector2D } from "../math/vector2D";
import { Body } from "./body";
import { SurfacePointDataStride, SurfacePointOffset } from "./plane";

export type SurfacePointImpulseInfo = {
  position: Vector2D;
  velocity: Vector2D;
  r: Vector2D;
  inverseMass: number;
  inverseMomentOfInertia: number;
};

export class SurfacePoint {
  id: string;
  body: Body | null;
  index: number;
  strideIndex: number;
  stateArray: Float32Array;
  onTransfer: (newBody: Body) => boolean;
  onDestroy: (id: string, index: number) => void;

  constructor({
    id,
    body,
    index,
    stateArray,
    onTransfer,
    onDestroy,
  }: {
    id: string;
    body: Body;
    index: number;
    stateArray: Float32Array;
    onTransfer: (newBody: Body) => boolean;
    onDestroy: (id: string, index: number) => void;
  }) {
    this.id = id;
    this.body = body;
    this.index = index;
    this.strideIndex = index * SurfacePointDataStride;
    this.stateArray = stateArray;
    this.onTransfer = onTransfer;
    this.onDestroy = onDestroy;
  }

  getBody(): Body | null {
    return this.body;
  }

  getPosition(): Vector2D | null {
    if (this.body === null) {
      return null;
    }

    return new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionX],
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionY]
    );
  }

  getPositionAndBody(): { position: Vector2D; body: Body } | null {
    if (this.body === null) {
      return null;
    }

    return {
      position: new Vector2D(
        this.stateArray[this.strideIndex + SurfacePointOffset.PositionX],
        this.stateArray[this.strideIndex + SurfacePointOffset.PositionY]
      ),
      body: this.body,
    };
  }

  getLocalPosition(): Vector2D | null {
    if (this.body === null) {
      return null;
    }

    return new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.LocalX],
      this.stateArray[this.strideIndex + SurfacePointOffset.LocalY]
    );
  }

  getR(): Vector2D | null {
    if (this.body === null) {
      return null;
    }

    return new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.RX],
      this.stateArray[this.strideIndex + SurfacePointOffset.RY]
    );
  }

  getImpulseInfo(): SurfacePointImpulseInfo | null {
    if (this.body === null) {
      return null;
    }

    const position = new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionX],
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionY]
    );
    const velocity = new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.VelocityX],
      this.stateArray[this.strideIndex + SurfacePointOffset.VelocityY]
    );
    const r = new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.RX],
      this.stateArray[this.strideIndex + SurfacePointOffset.RY]
    );
    const inverseMass = 1 / this.body.getMass(true);
    const inverseMomentOfInertia = 1 / this.body.getMomentOfInertia(true);
    return { position, velocity, r, inverseMass, inverseMomentOfInertia };
  }

  applyImpulse(impulse: Vector2D): void {
    if (this.body === null) {
      return;
    }

    this.body.applyImpulse(impulse, this.getR()!);
  }

  addPositionCorrection(correction: Vector2D): void {
    if (this.body === null) {
      return;
    }

    this.body.addPositionCorrection(correction);
  }

  transfer(newBody: Body): void {
    if (this.body === null) {
      return;
    }

    if (this.onTransfer(newBody)) {
      this.body = newBody;
    } else {
      this.body = null;
    }
  }

  destroy(): void {
    if (this.body === null) {
      return;
    }

    this.onDestroy(this.id, this.index);
    this.body = null;
  }

  applyForce(force: Vector2D): void {
    if (this.body === null) {
      return;
    }

    this.body.applyForce(force, this.getPosition()!);
  }
}
