import { nanoid } from "nanoid";
import { BodyType } from "../models";
import { getMaterial } from "../planar/planeInitUtil";
import { VertexShapeBuilder } from "../shapes/builders/vertexShapeBuilder";
import { Thruster } from "./thruster";
import { Body } from "./body";
import type { Vector2D } from "../math/vector2D";
import type { Plane } from "./plane";
import { Color } from "../rendering/color";

export class Enemy {
  plane: Plane;
  body: Body;
  thruster: Thruster;
  cutsLeft: number = 10;

  constructor(plane: Plane) {
    this.plane = plane;
    this.body = new Body({
      id: nanoid(),
      shape: VertexShapeBuilder.regularPolygon(20, 8),
      material: {
        density: 1,
        restitution: 0.4,
        staticFriction: 0.6,
        dynamicFriction: 0.4,
        color: new Color(255, 0, 0),
        destructible: false,
      },
      type: BodyType.Enemy,
      positionLocked: false,
      rotationLocked: false,
      inSubplane: false,
    });

    this.body.addCollisionTrigger((otherBody, collision) => {
      const result = this.plane.bodyStore.cutCircle(
        collision.pointOfContact,
        50,
        otherBody
      );

      if (result.length === 0) {
        return;
      }

      this.cutsLeft--;
      if (this.cutsLeft > 0) {
        return;
      }

      if (
        otherBody.type !== BodyType.PlayerArm &&
        otherBody.type !== BodyType.PlayerBody &&
        otherBody.type !== BodyType.Enemy
      ) {
        this.plane.bodyStore.unloadBody(this.body.index);
        this.plane.thrusters = this.plane.thrusters.filter(
          (t) => t !== this.thruster
        );
      }
    });

    this.thruster = new Thruster(this.body);
  }

  update(playerPosition: Vector2D) {
    const bodyPosition = this.body.getCenter();

    const toPlayer = playerPosition.subtract(bodyPosition);

    this.thruster.updateForce(toPlayer.normalize().multiply(70000000));
  }

  getEntities(): { body: Body; thruster: Thruster } {
    return {
      body: this.body,
      thruster: this.thruster,
    };
  }
}
