import type { Vector2D } from "../math/vector2D";
import { type IntersectionPoint, type Segment } from "../models";

export interface IntersectionShapeBuilder {
  addPartialSegment(
    segment: Segment,
    toEnd: boolean,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    fromHost: boolean
  ): void;
  addWholeSegments(
    segments: Segment[],
    toEnd: boolean,
    fromHost: boolean
  ): void;
  finishShape(): void;
  startSpan(
    onHost: boolean,
    forwards: boolean,
    currentIntersection: IntersectionPoint,
    nextIntersection: IntersectionPoint
  ): void;
  getFinalResult(): unknown;
  newShape(root: IntersectionPoint): void;
}

export function buildIntersectionShapes({
  segments,
  intersections,
  shapeBuilder,
  union = false,
}: {
  segments: [Segment[], Segment[]];
  intersections: [IntersectionPoint[], IntersectionPoint[]];
  shapeBuilder: IntersectionShapeBuilder;
  union: boolean;
}): boolean {
  let currentIntersection: IntersectionPoint;
  let currentSegments = segments[0];
  let currentIntersections = intersections[0];
  let nextIntersection: IntersectionPoint;
  let count = 0;
  let forwards = true;

  shapeBuilder.newShape(intersections[0][0]);

  try {
    currentIntersection = currentIntersections[0];

    while (true) {
      count++;

      if (count > 1000) {
        throw new Error("Infinite loop");
      }

      currentIntersection.visited = true;

      forwards = union
        ? !currentIntersection.entering
        : currentIntersection.entering;

      if (forwards) {
        nextIntersection = currentIntersections[currentIntersection.nextIndex!];
      } else {
        nextIntersection =
          currentIntersections[currentIntersection.previousIndex!];
      }

      shapeBuilder.startSpan(
        currentSegments === segments[1],
        forwards,
        currentIntersection,
        nextIntersection
      );

      if (nextIntersection === currentIntersection) {
        break;
      }

      if (currentIntersection.onCircle) {
        if (forwards) {
          shapeBuilder.addPartialSegment(
            currentSegments[currentIntersection.segmentIndex],
            true,
            [
              [Math.abs(currentIntersection.t), Math.abs(nextIntersection.t)],
              [
                currentIntersection.point.clone(),
                nextIntersection.point.clone(),
              ],
            ],
            currentSegments === segments[1]
          );
        } else {
          shapeBuilder.addPartialSegment(
            currentSegments[currentIntersection.segmentIndex],
            false,
            [
              [Math.abs(nextIntersection.t), Math.abs(currentIntersection.t)],
              [
                nextIntersection.point.clone(),
                currentIntersection.point.clone(),
              ],
            ],
            currentSegments === segments[1]
          );
        }
      } else {
        if (forwards) {
          addSegmentsToShape(
            shapeBuilder,
            currentSegments,
            currentIntersection,
            nextIntersection,
            currentSegments === segments[1]
          );
        } else {
          addSegmentsToShapeBackwards(
            shapeBuilder,
            currentSegments,
            currentIntersection,
            nextIntersection,
            currentSegments === segments[1]
          );
        }
      }

      // Get the counterpart of the next intersection
      const counterpart = nextIntersection.counterpart;
      nextIntersection.visited = true;

      currentSegments =
        currentSegments === segments[0] ? segments[1] : segments[0];
      currentIntersections =
        currentSegments === segments[0] ? intersections[0] : intersections[1];

      // If the counterpart is visited, try to find another unvisited intersection
      if (counterpart?.visited) {
        shapeBuilder.finishShape();

        currentIntersection = currentIntersections.find(
          (intersection) => !intersection.visited
        )!;

        // If there are no more unvisited intersections, we're done
        if (currentIntersection === undefined) {
          break;
        }

        shapeBuilder.newShape(currentIntersection);
      } else {
        // Set the current intersection to the counterpart
        currentIntersection = counterpart!;
      }
    }

    return true;
  } catch (e) {
    // console.error("Error building shape", e);
    return false;
  }
}

function addSegmentsToShapeBackwards(
  shapeBuilder: IntersectionShapeBuilder,
  segments: Segment[],
  intersection: IntersectionPoint,
  nextIntersection: IntersectionPoint,
  local: boolean
): void {
  // If the intersections are on the same segment, and the first t value is less than the second, create a subsegment of the segment
  if (
    intersection.segmentIndex === nextIntersection.segmentIndex &&
    intersection.t > nextIntersection.t
  ) {
    shapeBuilder.addPartialSegment(
      segments[intersection.segmentIndex],
      false,
      [
        [nextIntersection.t, intersection.t],
        [nextIntersection.point.clone(), intersection.point.clone()],
      ],
      local
    );

    return;
  }

  shapeBuilder.addPartialSegment(
    segments[intersection.segmentIndex],
    false,
    [
      [0, intersection.t],
      [null, intersection.point.clone()],
    ],
    local
  );

  // Then, add the other segments up until the next intersection by slicing the segments array
  // Check for wrapping
  let segmentsToAdd: Segment[];
  if (nextIntersection.segmentIndex < intersection.segmentIndex) {
    segmentsToAdd = segments.slice(
      nextIntersection.segmentIndex + 1,
      intersection.segmentIndex
    );
  } else {
    // To end
    segmentsToAdd = segments.slice(nextIntersection.segmentIndex + 1);

    // From start
    segmentsToAdd.push(...segments.slice(0, intersection.segmentIndex));
  }

  shapeBuilder.addWholeSegments(segmentsToAdd.reverse(), false, local);

  shapeBuilder.addPartialSegment(
    segments[nextIntersection.segmentIndex],
    false,
    [
      [nextIntersection.t, 1],
      [nextIntersection.point.clone(), null],
    ],
    local
  );
}

function addSegmentsToShape(
  shapeBuilder: IntersectionShapeBuilder,
  segments: Segment[],
  intersection: IntersectionPoint,
  nextIntersection: IntersectionPoint,
  local: boolean
): void {
  // If the intersections are on the same segment, and the first t value is less than the second, create a subsegment of the segment
  if (
    intersection.segmentIndex === nextIntersection.segmentIndex &&
    intersection.t < nextIntersection.t
  ) {
    shapeBuilder.addPartialSegment(
      segments[intersection.segmentIndex],
      true,
      [
        [intersection.t, nextIntersection.t],
        [intersection.point.clone(), nextIntersection.point.clone()],
      ],
      local
    );

    return;
  }

  // If the intersections are on different segments, first create a subsegment from the t value of the intersection to the end of the segment
  shapeBuilder.addPartialSegment(
    segments[intersection.segmentIndex],
    true,
    [
      [intersection.t, 1],
      [intersection.point.clone(), null],
    ],
    local
  );

  // Then, add the other segments up until the next intersection by slicing the segments array
  // Check for wrapping
  let segmentsToAdd: Segment[];
  if (intersection.segmentIndex < nextIntersection.segmentIndex) {
    segmentsToAdd = segments.slice(
      intersection.segmentIndex + 1,
      nextIntersection.segmentIndex
    );
  } else {
    // To end
    segmentsToAdd = segments.slice(intersection.segmentIndex + 1);

    // From start
    segmentsToAdd.push(...segments.slice(0, nextIntersection.segmentIndex));
  }

  shapeBuilder.addWholeSegments(segmentsToAdd, true, local);

  // Finally, add the subsegment from the next intersection from the start t value to the end of the segment
  shapeBuilder.addPartialSegment(
    segments[nextIntersection.segmentIndex],
    true,
    [
      [0, nextIntersection.t],
      [null, nextIntersection.point.clone()],
    ],
    local
  );
}
