import { Vector2D } from "../../math/vector2D.ts";
import {
  type Segment,
  type OpenSegment,
  type SegmentContributions,
} from "../../models.ts";
import type { Point } from "../../rendering/noise.ts";
import type { Path } from "../marchingSquares.ts";
import { ArcSegment } from "../segments/arcSegment.ts";
import { CircleSegment } from "../segments/circleSegment.ts";
import { LineSegment } from "../segments/lineSegment.ts";
import { Shape } from "../shape.ts";

export type Vertex = {
  position: Vector2D;
  sagitta?: number;
};

export class VertexShapeBuilder {
  shoelaceAreaSum = 0;
  shoelaceCentroidSum = new Vector2D(0, 0);
  shoelaceSecondMomentOfAreaSum = 0;
  compositeAreaSum = 0;
  compositeCentroidSum = new Vector2D(0, 0);
  compositeSecondMomentOfAreaSum = 0;
  segments: Segment[] = [];
  contributions: SegmentContributions[] = [];
  firstVertex: Vector2D;
  isClosed: boolean = false;

  constructor(firstVertex: Vector2D) {
    this.firstVertex = firstVertex;
  }

  updateSums(contributions: SegmentContributions): void {
    const {
      shoelaceFactor,
      shoelaceCentroid,
      shoelaceSecondMomentOfArea,
      area,
      centroid,
      compositeSecondMomentOfArea,
    } = contributions;

    // Handle polygonal (shoelace) contributions
    if (shoelaceFactor && shoelaceCentroid && shoelaceSecondMomentOfArea) {
      this.shoelaceAreaSum += shoelaceFactor;
      this.shoelaceCentroidSum = this.shoelaceCentroidSum.add(shoelaceCentroid);
      this.shoelaceSecondMomentOfAreaSum += shoelaceSecondMomentOfArea;
    }

    // Handle composite (circle) contributions
    if (area && centroid) {
      this.compositeAreaSum += area;
      this.compositeCentroidSum = this.compositeCentroidSum.add(
        centroid.multiply(area)
      );
      if (compositeSecondMomentOfArea) {
        this.compositeSecondMomentOfAreaSum += compositeSecondMomentOfArea;
      }
    }

    this.contributions.push(contributions);
  }

  getProperties(): {
    centroid: Vector2D;
    area: number;
    secondMomentOfArea: number;
  } {
    let secondMomentOfArea = 0;

    if (Math.abs(this.shoelaceAreaSum) > 0.0001) {
      const shoelaceArea = this.shoelaceAreaSum / 2;
      const shoelaceCentroid = this.shoelaceCentroidSum.divide(
        6 * shoelaceArea
      );

      // Add polygonal contribution to composite sums
      this.compositeAreaSum += shoelaceArea;
      this.compositeCentroidSum = this.compositeCentroidSum.add(
        shoelaceCentroid.multiply(shoelaceArea)
      );

      // Calculate polygonal second moment of area
      const polygonalIzz = this.shoelaceSecondMomentOfAreaSum / 12;
      const polygonalParallelAxisCorrection =
        shoelaceArea *
        (shoelaceCentroid.x * shoelaceCentroid.x +
          shoelaceCentroid.y * shoelaceCentroid.y);
      secondMomentOfArea = polygonalIzz - polygonalParallelAxisCorrection;
    }

    const finalCentroid = this.compositeCentroidSum.divide(
      this.compositeAreaSum
    );

    for (const contribution of this.contributions) {
      const { area, centroid, compositeSecondMomentOfArea } = contribution;

      if (area && centroid && compositeSecondMomentOfArea) {
        const r = centroid.subtract(finalCentroid);
        const distanceSquared = r.lengthSquared();
        secondMomentOfArea +=
          compositeSecondMomentOfArea + area * distanceSquared;
      }
    }

    return {
      centroid: finalCentroid,
      area: this.compositeAreaSum,
      secondMomentOfArea,
    };
  }

  build(): {
    shape: Shape;
    centroid: Vector2D;
    area: number;
    secondMomentOfArea: number;
  } {
    const { centroid, area, secondMomentOfArea } = this.getProperties();

    if (!this.isClosed) {
      this.close();
    }

    const shape = new Shape(this.segments, {
      area,
      centroid,
      secondMomentOfArea,
    });

    return { shape, centroid, area, secondMomentOfArea };
  }

  addVertex(end: Vector2D, sagitta?: number): void {
    let start: Vector2D;
    if (this.segments.length === 0) {
      start = this.firstVertex;
    } else {
      start = (
        this.segments[this.segments.length - 1] as OpenSegment
      ).end.clone();
    }

    if (sagitta) {
      const arc = new ArcSegment(start, end, sagitta);
      this.segments.push(arc);
      this.updateSums(arc.getAllContributions());
    } else {
      const line = new LineSegment(start, end);
      this.segments.push(line);
      this.updateSums(line.getAllContributions());
    }
  }

  close(sagitta?: number): void {
    this.addVertex(this.firstVertex.clone(), sagitta);
    this.isClosed = true;
  }

  static fromArray(vertices: Vertex[]): Shape {
    const builder = new VertexShapeBuilder(vertices[0].position);
    for (let i = 1; i < vertices.length; i++) {
      builder.addVertex(vertices[i].position, vertices[i].sagitta);
    }
    builder.close();
    return builder.build().shape;
  }

  static square(size: number, clockwise: boolean = false): Shape {
    if (clockwise) {
      return VertexShapeBuilder.fromArray([
        { position: new Vector2D(-size / 2, size / 2) },
        { position: new Vector2D(size / 2, size / 2) },
        { position: new Vector2D(size / 2, -size / 2) },
        { position: new Vector2D(-size / 2, -size / 2) },
      ]);
    } else {
      return VertexShapeBuilder.fromArray([
        { position: new Vector2D(-size / 2, -size / 2) },
        { position: new Vector2D(size / 2, -size / 2) },
        { position: new Vector2D(size / 2, size / 2) },
        { position: new Vector2D(-size / 2, size / 2) },
      ]);
    }
  }

  static regularPolygon(radius: number, numVertices: number): Shape {
    const vertices = [];
    for (let i = 0; i < numVertices; i++) {
      const angle = (2 * Math.PI * i) / numVertices;
      vertices.push({
        position: new Vector2D(
          radius * Math.cos(angle),
          radius * Math.sin(angle)
        ),
      });
    }
    return VertexShapeBuilder.fromArray(vertices);
  }

  static rectangle({
    width,
    height,
  }: {
    width: number;
    height: number;
  }): Shape {
    return VertexShapeBuilder.fromArray([
      { position: new Vector2D(-width / 2, -height / 2) },
      { position: new Vector2D(width / 2, -height / 2) },
      { position: new Vector2D(width / 2, height / 2) },
      { position: new Vector2D(-width / 2, height / 2) },
    ]);
  }

  static capsule({
    width,
    height,
    widthSagittaFactor,
    heightSagittaFactor,
  }: {
    width: number;
    height: number;
    widthSagittaFactor: number;
    heightSagittaFactor: number;
  }): Shape {
    return VertexShapeBuilder.fromArray([
      {
        position: new Vector2D(-width / 2, -height / 2),
      },
      {
        position: new Vector2D(width / 2, -height / 2),
        sagitta: widthSagittaFactor * width,
      },
      {
        position: new Vector2D(width / 2, height / 2),
        sagitta: heightSagittaFactor * height,
      },
      {
        position: new Vector2D(-width / 2, height / 2),
        sagitta: widthSagittaFactor * width,
      },
    ]);
  }

  static circle(radius: number): Shape {
    return new Shape([new CircleSegment(new Vector2D(0, 0), radius, false)]);
  }

  static fromPath(path: Path) {
    if (!path.isClosed) {
      throw new Error("Tried to build shape from open path");
    }

    if (path.points.length > 10000) {
      throw new Error("Path too long to build shape");
    }

    const vertices = path.points.map((point: Point) => {
      const vertex: Vertex = {
        position: new Vector2D(point.x, point.y),
      };

      return vertex;
    });

    return VertexShapeBuilder.fromArray(vertices);
  }
}
