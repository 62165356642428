import { Vector2D } from "../math/vector2D.ts";
import { type NetworkState, type Timer } from "../models.ts";
import type { Circle, LightSource } from "../models.ts";
import { Actuator } from "./actuator.ts";
import { Body } from "./body.ts";
import type { Camera } from "./camera.ts";
import { getActualCollisions, resolveCollisions } from "./collisionPipeline.ts";
import { BodyStore } from "./bodyStore.ts";
import type { CentroidTether } from "./centroidTether.ts";
import type { Thruster } from "./thruster.ts";
import { SurfacePointStore } from "./surfacePointStore.ts";
import type { Spring } from "./spring.ts";
import type { Pin } from "./pin.ts";
import type { Tether } from "./tether.ts";

export const SurfacePointDataStride = 10;
export enum SurfacePointOffset {
  PositionX = 0,
  PositionY = 1,
  LocalX = 2,
  LocalY = 3,
  LocalRX = 4,
  LocalRY = 5,
  VelocityX = 6,
  VelocityY = 7,
  RX = 8,
  RY = 9,
}

export const BodyDataStride = 20;
export enum BodyOffset {
  PositionX = 0,
  PositionY = 1,
  Rotation = 2,
  SinRotation = 3,
  CosRotation = 4,
  VelocityX = 5,
  VelocityY = 6,
  AngularVelocity = 7,
  AccelerationX = 8,
  AccelerationY = 9,
  AngularAcceleration = 10,
  LocalMecPositionX = 11,
  LocalMecPositionY = 12,
  MecPositionX = 13,
  MecPositionY = 14,
  MecRadius = 15,
  TransformOffsetX = 16,
  TransformOffsetY = 17,
  LocalCentroidX = 18,
  LocalCentroidY = 19,
}

export class Plane {
  bodyStates: Float32Array;
  surfacePointStates: Float32Array;

  bodyStore: BodyStore;
  surfacePointStore: SurfacePointStore;

  actuators: Actuator[] = [];
  centroidTethers: CentroidTether[] = [];
  pins: Pin[] = [];
  tethers: Tether[] = [];

  thrusters: Thruster[] = [];
  springs: Spring[] = [];

  private timers: Map<number, Timer>;
  private nextTimerId: number;
  collisionVectors: {
    point: Vector2D;
    vector: Vector2D;
  }[] = [];
  startMetric: (name: string) => void;
  endMetric: (name: string) => void;
  recordNumericMetric: (name: string, value: number) => void;

  constructor({
    maxBodies,
    startMetric,
    endMetric,
    recordNumericMetric,
    addBody,
    removeBody,
  }: {
    maxBodies: number;
    startMetric: (name: string) => void;
    endMetric: (name: string) => void;
    recordNumericMetric: (name: string, value: number) => void;
    addBody: (body: Body) => void;
    removeBody: (id: string) => void;
  }) {
    this.surfacePointStates = new Float32Array(1000 * SurfacePointDataStride);
    this.timers = new Map();
    this.nextTimerId = 0;
    this.startMetric = startMetric;
    this.endMetric = endMetric;
    this.recordNumericMetric = recordNumericMetric;
    this.bodyStates = new Float32Array(maxBodies * BodyDataStride);
    this.bodyStore = new BodyStore({
      bodyStates: this.bodyStates,
      addBody,
      removeBody,
      initialWorldBounds: {
        min: new Vector2D(-10000, -10000),
        max: new Vector2D(10000, 10000),
      },
    });
    this.surfacePointStore = new SurfacePointStore({
      surfacePointStates: this.surfacePointStates,
    });
  }

  loadActuator(actuator: Actuator): number {
    this.actuators.push(actuator);
    this.bodyStore.addIgnoredCollisionPair(
      actuator.base.index,
      actuator.endEffector.index
    );
    return this.actuators.length - 1;
  }

  interpolateUpdate(
    latestState: NetworkState,
    stateAge: number,
    bodyIdToIndex: Map<string, number>
  ): void {
    const dt = latestState.dt;
    const predictionDt = Math.max(0, Math.min(dt, stateAge / 1000));
    const bodyIndices: number[] = [];

    // Network interpolation tuning parameters
    const LINEAR_SPEED_THRESHOLD = 10000;
    const ANGULAR_SPEED_THRESHOLD = 10; // radians/second

    // Linear blend components
    const LINEAR_BLEND_BASE = 0.4; // Base blend amount (higher = more responsive but potentially jittery)
    const LINEAR_BLEND_RANGE = 0.6; // Additional blend based on speed (lower = less speed-based adjustment)
    const LINEAR_BLEND_MAX = 0.5; // Maximum blend factor (lower = smoother but more lag)

    // Angular blend components
    const ANGULAR_BLEND_BASE = 0.3;
    const ANGULAR_BLEND_RANGE = 0.5;
    const ANGULAR_BLEND_MAX = 0.4;

    for (const entry of latestState.entries) {
      const bodyIndex = bodyIdToIndex.get(entry.id);
      if (bodyIndex === undefined) {
        console.warn("could not find body index", entry.id);
        continue;
      }
      bodyIndices.push(bodyIndex);

      const body = this.bodyStore.getBody(bodyIndex);

      if (!body) {
        continue;
      }

      const targetIndex = bodyIndex * BodyDataStride;

      const predictedPos = {
        x: entry.position[0] + entry.velocity[0] * predictionDt,
        y: entry.position[1] + entry.velocity[1] * predictionDt,
        rotation: entry.position[2] + entry.velocity[2] * predictionDt,
      };

      // Get current position
      const currentPos = {
        x: this.bodyStates[targetIndex + BodyOffset.PositionX],
        y: this.bodyStates[targetIndex + BodyOffset.PositionY],
        rotation: this.bodyStates[targetIndex + BodyOffset.Rotation],
      };

      // Calculate separate blend factors for linear and angular motion
      const linearSpeed = Math.sqrt(
        entry.velocity[0] * entry.velocity[0] +
          entry.velocity[1] * entry.velocity[1]
      );
      const angularSpeed = Math.abs(entry.velocity[2]);

      const LINEAR_BLEND_FACTOR = Math.min(
        LINEAR_BLEND_MAX,
        LINEAR_BLEND_BASE +
          (linearSpeed / LINEAR_SPEED_THRESHOLD) * LINEAR_BLEND_RANGE
      );

      const ANGULAR_BLEND_FACTOR = Math.min(
        ANGULAR_BLEND_MAX,
        ANGULAR_BLEND_BASE +
          (angularSpeed / ANGULAR_SPEED_THRESHOLD) * ANGULAR_BLEND_RANGE
      );

      // Blend current position toward predicted position
      const newPos = {
        x: currentPos.x + (predictedPos.x - currentPos.x) * LINEAR_BLEND_FACTOR,
        y: currentPos.y + (predictedPos.y - currentPos.y) * LINEAR_BLEND_FACTOR,
        rotation:
          currentPos.rotation +
          (predictedPos.rotation - currentPos.rotation) * ANGULAR_BLEND_FACTOR,
      };

      // Update body state
      this.bodyStates[targetIndex + BodyOffset.PositionX] = newPos.x;
      this.bodyStates[targetIndex + BodyOffset.PositionY] = newPos.y;
      this.bodyStates[targetIndex + BodyOffset.Rotation] = newPos.rotation;
      const cos = Math.cos(newPos.rotation);
      const sin = Math.sin(newPos.rotation);
      this.bodyStates[targetIndex + BodyOffset.CosRotation] = cos;
      this.bodyStates[targetIndex + BodyOffset.SinRotation] = sin;

      // Also update velocities for next frame's prediction
      this.bodyStates[targetIndex + BodyOffset.VelocityX] = entry.velocity[0];
      this.bodyStates[targetIndex + BodyOffset.VelocityY] = entry.velocity[1];
      this.bodyStates[targetIndex + BodyOffset.AngularVelocity] =
        entry.velocity[2];

      const localMecX =
        this.bodyStates[targetIndex + BodyOffset.LocalMecPositionX];
      const localMecY =
        this.bodyStates[targetIndex + BodyOffset.LocalMecPositionY];

      this.bodyStates[targetIndex + BodyOffset.MecPositionX] =
        localMecX * cos - localMecY * sin + currentPos.x;
      this.bodyStates[targetIndex + BodyOffset.MecPositionY] =
        localMecX * sin + localMecY * cos + currentPos.y;

      this.surfacePointStore.update();
    }

    return;
  }

  update(
    dt: number,
    now: number,
    shouldResolveCollisions: boolean = true
  ): void {
    this.updateTimers(now);

    // Apply forces
    this.applyForceConstraints(dt);
    // Predict positions
    this.startMetric("positionUpdate");
    this.bodyStore.update(dt);
    this.endMetric("positionUpdate");

    this.surfacePointStore.update();

    // Collision detection
    this.startMetric("quadtreeUpdate");
    this.bodyStore.updateQuadtree();
    this.endMetric("quadtreeUpdate");

    const bodiesWithPositionCorrections: Set<Body> = new Set();
    const triggers: (() => void)[] = [];

    this.startMetric("broadPhase");
    const potentialCollisions = this.bodyStore.getPotentialCollisions();
    this.endMetric("broadPhase");

    this.startMetric("narrowPhase");
    const actualCollisions = getActualCollisions(potentialCollisions);
    this.endMetric("narrowPhase");

    // Resolve collisions
    if (shouldResolveCollisions) {
      this.startMetric("resolveCollisions");
      resolveCollisions(
        actualCollisions,
        bodiesWithPositionCorrections,
        triggers
      );
      this.endMetric("resolveCollisions");
    }

    // Apply impulse constraints
    this.applyImpulseConstraints(dt, bodiesWithPositionCorrections);

    // Correct positions
    for (const body of bodiesWithPositionCorrections) {
      body.applyPositionCorrections();
      body.applyAngleCorrections();
    }

    // Trigger callbacks
    for (const trigger of triggers) {
      trigger();
    }

    this.bodyStore.cleanupCompositeBodies();
  }

  applyForceConstraints(dt: number): void {
    for (const thruster of this.thrusters) {
      thruster.apply(dt);
    }
    for (const spring of this.springs) {
      spring.apply(dt);
    }
    for (const actuator of this.actuators) {
      actuator.apply(dt);
    }
  }

  applyImpulseConstraints(
    dt: number,
    bodiesWithPositionCorrections: Set<Body>
  ): void {
    for (const tether of this.centroidTethers) {
      const correctedBodies = tether.apply();
      for (const body of correctedBodies) {
        bodiesWithPositionCorrections.add(body);
      }
    }
    for (const pin of this.pins) {
      const correctedBodies = pin.apply();
      for (const body of correctedBodies) {
        bodiesWithPositionCorrections.add(body);
      }
    }
    for (const tether of this.tethers) {
      const correctedBodies = tether.apply();
      for (const body of correctedBodies) {
        bodiesWithPositionCorrections.add(body);
      }
    }
  }

  updateTimers(now: number): void {
    const currentTime = now;
    for (const timer of this.timers.values()) {
      if (currentTime >= timer.nextTick) {
        timer.callback();
        if (timer.repeating) {
          timer.nextTick = currentTime + timer.interval;
        } else {
          this.timers.delete(timer.id);
        }
      }
    }
  }

  addTimer(
    callback: () => void,
    delay: number,
    repeating: boolean = false
  ): number {
    const id = this.nextTimerId++;
    this.timers.set(id, {
      id,
      callback,
      interval: delay,
      nextTick: performance.now() + delay,
      repeating,
    });
    return id;
  }

  removeTimer(id: number): void {
    this.timers.delete(id);
  }

  getRenderInfo(
    camera: Camera,
    margin: number = 0
  ): {
    mainPlaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
    subplaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
    lightSources: {
      light: LightSource;
      position: Vector2D;
    }[];
    actuators: Actuator[];
  } {
    const { mainPlaneBodies, subplaneBodies } =
      this.bodyStore.getBodiesVisibleToCamera(camera, margin);

    const lightSources: {
      light: LightSource;
      position: Vector2D;
    }[] = [];

    for (const body of mainPlaneBodies) {
      if (body.body.light) {
        lightSources.push({
          light: body.body.light,
          position: new Vector2D(body.mec.x, body.mec.y),
        });
      }
    }

    const actuators: Actuator[] = [];

    for (const actuator of this.actuators) {
      if (
        mainPlaneBodies.some(
          (body) =>
            body.body === actuator.base || body.body === actuator.endEffector
        )
      ) {
        actuators.push(actuator);
      }
    }

    return {
      mainPlaneBodies,
      subplaneBodies,
      lightSources,
      actuators,
    };
  }
}
