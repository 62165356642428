import { calculateGeometricProperties, TWO_PI } from "../math/utils.ts";
import { welzl, type WelzlPoint } from "../geometry/welzl.ts";
import { Vector2D } from "../math/vector2D.ts";
import {
  type Segment,
  type SerializedShape,
  SegmentType,
  type LineIntersection,
  type SerializedSegment,
  type IntersectionPoint,
  type Circle,
  type SegmentInfo,
  type PerimeterPoint,
  type RelativeTransformInfo,
} from "../models.ts";
import { Transform } from "../math/transform.ts";
import { CircleSegment } from "./segments/circleSegment.ts";
import { LineSegment } from "./segments/lineSegment.ts";
import { ArcSegment } from "./segments/arcSegment.ts";
import { findShapeIntersections } from "../geometry/intersection.ts";
import { buildIntersectionShapes } from "../geometry/buildIntersectionShapes.ts";
import { CutShapeBuilder } from "./builders/cutShapeBuilder.ts";
import { buildSegmentTree, SegmentTree } from "./segmentTree.ts";
import { intersectSegmentTrees } from "./intersectSegmentTrees.ts";
import { SurfacePoint } from "../plane/surfacePoint.ts";

export class Shape {
  segments: Segment[];
  area: number;
  mec: {
    center: Vector2D;
    radius: number;
    points: WelzlPoint[];
  };
  centroid: Vector2D;
  secondMomentOfArea: number;
  path: Path2D | null = null;
  storedPoints: Map<string, Vector2D> | null = null;
  private segmentTree: SegmentTree | null = null;
  private perimeterPoints: Vector2D[] | null = null;

  constructor(
    segments: Segment[],
    precomputed?: {
      area: number;
      centroid: Vector2D;
      secondMomentOfArea: number;
      mec?: {
        center: Vector2D;
        radius: number;
        points: WelzlPoint[];
      };
    }
  ) {
    this.segments = segments;

    if (precomputed) {
      this.area = precomputed.area;
      this.centroid = precomputed.centroid;
      this.secondMomentOfArea = precomputed.secondMomentOfArea;
      this.mec = precomputed.mec ?? this.calculateMec();
    } else {
      const props = calculateGeometricProperties(this.segments);
      this.area = props.area;
      this.centroid = props.centroid;
      this.secondMomentOfArea = props.secondMomentOfArea;
      this.mec = this.calculateMec();
    }
  }

  getCentroid(transform: Transform): Vector2D {
    return transform.apply(this.centroid);
  }

  getSegmentTree(): SegmentTree {
    if (this.segmentTree) {
      return this.segmentTree;
    }

    this.segmentTree = buildSegmentTree(this.segments);
    return this.segmentTree;
  }

  queryCircle(circle: Circle, transform: Transform): SegmentInfo[] {
    const segmentTree = this.getSegmentTree();

    // Convert circle center to local coordinates
    const localCenter = transform.applyInverse(
      new Vector2D(circle.x, circle.y)
    );

    // Create local circle (radius doesn't need transformation since we're not scaling)
    const localCircle: Circle = {
      x: localCenter.x,
      y: localCenter.y,
      radius: circle.radius,
    };

    return segmentTree.queryCircle(localCircle);
  }

  containsPoint(point: Vector2D, transform: Transform): boolean {
    const segmentTree = this.getSegmentTree();

    // Convert point to local coordinates
    const localPoint = transform.applyInverse(point);

    const found = segmentTree.queryHorizontalRay(localPoint);

    let count = 0;

    for (const segmentInfo of found) {
      count += segmentInfo.segment.rayIntersectionCount(localPoint);
    }

    return count % 2 === 1;
  }

  getClosestPerimeterPoint(
    queryCenter: Vector2D,
    queryRadius: number
  ): {
    distance: number;
    perimeterPoint: PerimeterPoint;
  } | null {
    // Step 2: Define the search circle in local coordinates
    const searchCircle: Circle = {
      x: queryCenter.x,
      y: queryCenter.y,
      radius: queryRadius,
    };

    const segmentTree = this.getSegmentTree();

    // Step 3: Query the segment quadtree for segments intersecting the search circle
    const possibleSegments = segmentTree.queryCircle(searchCircle);

    // If no segments are found within the query radius, return null
    if (possibleSegments.length === 0) {
      return null;
    }

    let closestPoint: PerimeterPoint | null = null;
    let minDistanceSquared = queryRadius * queryRadius;

    // Step 4: Iterate through each possible segment to find the closest point
    for (const segmentInfo of possibleSegments) {
      // Find the closest point on the segment to the localPoint
      const candidatePoint = segmentInfo.segment.getClosestPoint(queryCenter);

      if (!candidatePoint) {
        continue;
      }

      const { point, tOrAngle } = candidatePoint;

      // Calculate the squared distance between localPoint and candidatePoint
      const dx = point.x - queryCenter.x;
      const dy = point.y - queryCenter.y;
      const distanceSquared = dx * dx + dy * dy;

      // If this point is closer than the current closest, update closestPoint
      if (distanceSquared < minDistanceSquared) {
        minDistanceSquared = distanceSquared;
        closestPoint = {
          segment: segmentInfo.segment,
          segmentIndex: segmentInfo.index,
          position: point.clone(),
          tOrAngle,
        };

        // Early exit if the closest possible point is found
        if (distanceSquared === 0) {
          break;
        }
      }
    }

    if (closestPoint) {
      const closestDistance = Math.sqrt(minDistanceSquared);
      if (closestDistance <= queryRadius) {
        return {
          distance: closestDistance,
          perimeterPoint: closestPoint,
        };
      }
    }

    // If no valid closest point is found within the query radius, return null
    return null;
  }

  storePoint({
    surfacePoint,
    segment,
    tOrAngle,
  }: {
    surfacePoint: SurfacePoint;
    segment: Segment;
    tOrAngle: number;
  }): void {
    if (!this.segments.find((s) => s === segment)) {
      throw new Error("Segment not found in shape");
    }

    segment.storePoint(surfacePoint, tOrAngle);
  }

  getGlobalStoredPoint(id: string, transform: Transform): Vector2D | null {
    if (!this.storedPoints) {
      return null;
    }

    const point = this.storedPoints.get(id);
    if (!point) {
      return null;
    }

    return transform.apply(point);
  }

  getPointOnPerimeter(transform: Transform): Vector2D {
    if (this.segments[0].type !== SegmentType.CIRCLE) {
      return transform.apply((this.segments[0] as LineSegment).start);
    }
    return transform.apply(
      new Vector2D(
        (this.segments[0] as CircleSegment).center.x +
          (this.segments[0] as CircleSegment).radius,
        (this.segments[0] as CircleSegment).center.y
      )
    );
  }

  recenter(): void {
    this.mec.center = this.mec.center.subtract(this.centroid);
    for (const point of this.mec.points) {
      point.x -= this.centroid.x;
      point.y -= this.centroid.y;
    }
    for (const segment of this.segments) {
      segment.translate(-this.centroid.x, -this.centroid.y);
    }
    this.centroid = new Vector2D(0, 0);
  }

  calculateMec(): {
    center: Vector2D;
    radius: number;
    points: WelzlPoint[];
  } {
    const points: WelzlPoint[] = [];
    for (const segment of this.segments) {
      if (segment.type === SegmentType.LINE) {
        const line = segment as LineSegment;
        points.push({
          x: line.start.x,
          y: line.start.y,
          segment: line,
          t: 0,
        });
      } else if (segment.type === SegmentType.ARC) {
        const arc = segment as ArcSegment;
        points.push({
          x: arc.start.x,
          y: arc.start.y,
          segment: arc,
          t: 0,
        });
        // Only add the midpoint if the arc is convex
        if (!arc.clockwise) {
          points.push(...arc.getMecPoints());
        }
      } else if (segment.type === SegmentType.CIRCLE) {
        const circle = segment as CircleSegment;
        return {
          center: circle.center,
          radius: circle.radius,
          points: [],
        };
      }
    }
    const mec = welzl(points);
    if (mec === null) {
      throw new Error("Minimum enclosing circle not found");
    }

    return {
      center: new Vector2D(mec.center.x, mec.center.y),
      radius: mec.radius,
      points: mec.boundaryPoints,
    };
  }

  getLineIntersections(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    transform?: Transform
  ): LineIntersection[] {
    const intersections: LineIntersection[] = [];

    for (const segment of this.segments) {
      if (segment.type === SegmentType.LINE) {
        const intersection = (segment as LineSegment).getLineIntersection(
          lineStart,
          lineEnd,
          transform
        );
        if (intersection) {
          intersections.push(...intersection);
        }
      } else {
        const segmentIntersections = (
          segment as ArcSegment | CircleSegment
        ).getLineIntersection(lineStart, lineEnd, transform);
        intersections.push(...segmentIntersections);
      }
    }

    // Sort intersections by t value
    intersections.sort((a, b) => a.t - b.t);

    return intersections;
  }

  serialize(): SerializedShape {
    return {
      segments: this.segments.map((segment) => segment.serialize()),
    };
  }

  clone(): Shape {
    const clonedSegments = this.segments.map((segment) => segment.clone(false));

    return new Shape(clonedSegments, {
      area: this.area,
      centroid: this.centroid,
      secondMomentOfArea: this.secondMomentOfArea,
    });
  }

  inverse(): Shape {
    const clonedSegments = this.segments
      .map((segment) => segment.clone(true))
      .reverse();

    const newShape = new Shape(clonedSegments, {
      area: -this.area,
      centroid: this.centroid,
      secondMomentOfArea: -this.secondMomentOfArea,
    });

    newShape.mec = this.mec;

    return newShape;
  }

  subtract(
    other: Shape,
    transformInfo: RelativeTransformInfo
  ):
    | {
        shape: Shape;
        surfacePoints: SurfacePoint[];
      }[]
    | null {
    const otherInverse = other.inverse();

    const segmentPairs = intersectSegmentTrees(
      otherInverse.getSegmentTree(),
      this.getSegmentTree(),
      transformInfo.guestToHost,
      transformInfo.hostToGuest
    );

    let intersections: {
      intersections: [IntersectionPoint[], IntersectionPoint[]] | null;
      segmentCheckCount: number;
      segmentIntersectionCount: number;
    } | null = null;

    try {
      intersections = findShapeIntersections(
        segmentPairs,
        transformInfo.guestToHost
      );
    } catch (e) {
      return null;
    }

    if (intersections.intersections === null) {
      return [];
    }

    const builder = new CutShapeBuilder(transformInfo, this);

    const ok = buildIntersectionShapes({
      segments: [otherInverse.segments, this.segments],
      intersections: intersections.intersections,
      shapeBuilder: builder,
      union: false,
    });

    if (!ok) {
      return null;
    }

    return builder.getFinalResult();
  }

  getPath(): Path2D {
    if (this.path) {
      return this.path;
    }

    const path = new Path2D();
    for (const [index, segment] of this.segments.entries()) {
      if (index === 0) {
        if (
          segment.type === SegmentType.LINE ||
          segment.type === SegmentType.ARC
        ) {
          const lineSegment = segment as LineSegment;
          path.moveTo(lineSegment.start.x, lineSegment.start.y);
        } else if (segment.type === SegmentType.CIRCLE) {
          const circleSegment = segment as CircleSegment;
          path.moveTo(
            circleSegment.center.x + circleSegment.radius,
            circleSegment.center.y
          );
        }
      }

      switch (segment.type) {
        case SegmentType.LINE:
          const lineSegment = segment as LineSegment;
          path.lineTo(lineSegment.end.x, lineSegment.end.y);
          break;
        case SegmentType.CIRCLE:
          const circleSegment = segment as CircleSegment;
          path.arc(
            circleSegment.center.x,
            circleSegment.center.y,
            circleSegment.radius,
            0,
            TWO_PI,
            circleSegment.clockwise
          );
          break;
        case SegmentType.ARC:
          const arcSegment = segment as ArcSegment;
          path.arc(
            arcSegment.center.x,
            arcSegment.center.y,
            arcSegment.radius,
            arcSegment.startAngle,
            arcSegment.endAngle,
            arcSegment.clockwise
          );
          break;
      }
    }
    path.closePath();
    this.path = path;
    return path;
  }

  static fromSerialized(serialized: SerializedShape): Shape {
    const shape = new Shape(
      serialized.segments.map((segment) => segmentFromSerialized(segment))
    );
    return shape;
  }

  getPerimeterPoints(distance: number): Vector2D[] {
    if (this.perimeterPoints) {
      return this.perimeterPoints;
    }

    if (distance <= 0) {
      throw new Error("Distance must be positive");
    }

    const points: Vector2D[] = [];
    let leftoverDistance = 0;

    for (const segment of this.segments) {
      let multiplier = 0;

      while (true) {
        const queryDistance = leftoverDistance + distance * multiplier;
        const result = segment.getPointAtDistance(queryDistance);

        // If we got a point, validate it
        if (result instanceof Vector2D) {
          if (isNaN(result.x) || isNaN(result.y)) {
            throw new Error(
              `Invalid point generated: ${JSON.stringify(
                {
                  segmentType: segment.type,
                  queryDistance,
                  point: result,
                  segment,
                },
                null,
                2
              )}`
            );
          }
        }

        if (typeof result === "number") {
          leftoverDistance = result;
          break;
        }

        points.push(result);
        multiplier++;
      }
    }

    this.perimeterPoints = points;
    return points;
  }
}

export function segmentFromSerialized(serialized: SerializedSegment): Segment {
  switch (serialized.type) {
    case SegmentType.LINE:
      return LineSegment.fromSerialized(serialized);
    case SegmentType.ARC:
      return ArcSegment.fromSerialized(serialized);
    case SegmentType.CIRCLE:
      return CircleSegment.fromSerialized(serialized);
  }
}
