import { Actuator } from "../plane/actuator.ts";
import {
  type BodyState,
  type SerializedBody,
  type SerializedPlayer,
} from "../models.ts";
import { Plane } from "../plane/plane.ts";
import { Player } from "../plane/player.ts";
import {
  generateVariety,
  type VarietyGenerationParams,
} from "./planeInitUtil.ts";
import { Body } from "../plane/body.ts";
import type { Camera } from "../plane/camera.ts";

interface PerformanceMetric {
  name: string;
  lastTime: number;
  samples: number[];
  maxSamples: number;
  type: "time" | "numeric";
}

const METRIC_SAMPLE_SIZE = 60; // Store last 60 frames for averaging

const METRIC_NAMES = [
  "actualFrameTime",
  "frameTime",
  "planeUpdate",
  "quadtreeConstruction",
  "intersectMECs",
  "updateGlobalPositions",
  "findSegmentPairs",
  "intersectSegments",
  "findEnclosure",
  "buildIntersectionShapes",
  "resolve",
  "callBeforeCollisionTriggers",
  "callAfterCollisionTriggers",
  "applyPositionCorrections",
  "positionUpdate",
  "serialize",
  "getVisibleBodies",
  "render",
] as const;

const NUMERIC_METRIC_NAMES = [
  "mecCheckCount",
  "mecIntersectionCount",
  "unfilteredSegmentCheckCount",
  "segmentCheckCount",
  "percentSegmentsFiltered",
  "segmentIntersectionCount",
  "numCollisions",
  "segmentPairs",
] as const;

export abstract class PlanarBase {
  id: string;
  plane: Plane;
  neverUnloadBodyIds: Set<string> = new Set();

  bodies: Map<string, Body> = new Map();
  actuators: Map<string, Actuator> = new Map();
  players: Map<string, Player> = new Map();

  bodyIdToIndex: Map<string, number> = new Map();
  actuatorIdToIndex: Map<string, number> = new Map();
  playerIdToIndex: Map<string, number> = new Map();

  nextBodyId = 0;
  nextActuatorId = 0;
  nextPlayerId = 0;

  metrics: Map<string, PerformanceMetric> = new Map();

  constructor({ id, maxBodies }: { id: string; maxBodies: number }) {
    this.id = id;
    this.plane = new Plane({
      maxBodies,
      startMetric: this.startMetric,
      endMetric: this.endMetric,
      recordNumericMetric: this.recordNumericMetric,
      addBody: this.addBody,
      removeBody: this.removeBody,
    });
    this.initializeMetrics([...METRIC_NAMES]);
    this.initializeMetrics([...NUMERIC_METRIC_NAMES], "numeric");
  }

  private initializeMetrics(
    metricNames: string[],
    type: "time" | "numeric" = "time"
  ): void {
    metricNames.forEach((name) => {
      this.metrics.set(name, {
        name,
        lastTime: 0,
        samples: [],
        maxSamples: METRIC_SAMPLE_SIZE,
        type,
      });
    });
  }

  startMetric = (name: string) => {
    const metric = this.metrics.get(name);
    if (metric) {
      metric.lastTime = performance.now();
    }
  };

  endMetric = (name: string, startTime?: number): void => {
    const metric = this.metrics.get(name);
    if (metric) {
      const duration = performance.now() - (startTime ?? metric.lastTime);
      metric.samples.push(duration);
      if (metric.samples.length > metric.maxSamples) {
        metric.samples.shift();
      }
    }
  };

  recordNumericMetric = (name: string, value: number): void => {
    const metric = this.metrics.get(name);
    if (metric && metric.type === "numeric") {
      metric.samples.push(value);
      if (metric.samples.length > metric.maxSamples) {
        metric.samples.shift();
      }
    }
  };

  getMetricsReport(camera: Camera): string {
    let report = "";

    // Add FPS at the top of the report
    const fps = this.calculateFPS();
    report += `FPS: ${fps.toFixed(1)}\n`;

    // Add camera position
    report += `Camera: (${camera.x.toFixed(1)}, ${camera.y.toFixed(1)})\n`;
    report += `Zoom: ${camera.zoom.toFixed(2)}x\n`;

    // Add shape counts
    report += `Number of Bodies: ${this.plane.bodyStore.count}\n`;

    // Add existing metrics
    this.metrics.forEach((metric) => {
      if (metric.samples.length > 0) {
        const avg =
          metric.samples.reduce((a, b) => a + b, 0) / metric.samples.length;
        report += `${metric.name}: ${avg.toFixed(2)}${
          metric.type === "time" ? "ms" : ""
        }\n`;
      }
    });
    return report;
  }

  private calculateFPS(): number {
    const frameTimeMetric = this.metrics.get("frameTime");
    if (!frameTimeMetric || frameTimeMetric.samples.length === 0) {
      return 0;
    }

    // Calculate average frame time from recent samples
    const avgFrameTime =
      frameTimeMetric.samples.reduce((a, b) => a + b, 0) /
      frameTimeMetric.samples.length;
    return 1000 / avgFrameTime; // Convert ms/frame to frames/second
  }

  getNextBodyId(): string {
    return `${this.id}-${this.nextBodyId++}`;
  }

  getNextActuatorId(): string {
    return `${this.id}-${this.nextActuatorId++}`;
  }

  getNextPlayerId(): string {
    return `${this.id}-${this.nextPlayerId++}`;
  }

  addBodyFromSerialized(serializedBody: SerializedBody): void {
    const body = Body.fromSerialized(serializedBody);
    this.addBody(body);
  }

  unloadBody(id: string): void {
    if (this.neverUnloadBodyIds.has(id)) {
      return;
    }

    this.plane.bodyStore.unloadBody(this.bodyIdToIndex.get(id)!);
  }

  loadBody({ body, state }: { body: Body; state: BodyState }): void {
    const currentIndex = this.bodyIdToIndex.get(body.id);
    if (currentIndex !== undefined) {
      console.error("Body already loaded", body.id);
      return;
    }

    this.plane.bodyStore.loadBody({ body, state });
    return;
  }

  addBody = (body: Body) => {
    this.bodies.set(body.id, body);
    this.afterAddBody(body);
  };

  removeBody = (id: string) => {
    this.bodies.delete(id);
    this.bodyIdToIndex.delete(id);
  };

  addActuator(actuator: Actuator): void {
    this.actuators.set(actuator.id, actuator);
  }

  loadActuator(id: string): number {
    const actuator = this.actuators.get(id);
    if (actuator === undefined) {
      throw new Error("Actuator not found");
    }

    if (!actuator.base.isLoaded() || !actuator.endEffector.isLoaded()) {
      throw new Error("Actuator bodies not loaded");
    }

    const actuatorIndex = this.plane.loadActuator(actuator);
    this.actuatorIdToIndex.set(actuator.id, actuatorIndex);

    return actuatorIndex;
  }

  addAndLoadActuator(actuator: Actuator): number {
    this.actuators.set(actuator.id, actuator);
    return this.loadActuator(actuator.id);
  }

  addPlayerFromSerialized(serializedPlayer: SerializedPlayer): void {
    throw new Error("Not implemented");
    // const actuator = this.actuators.get(serializedPlayer.actuatorId);
    // if (actuator === undefined) {
    //   throw new Error("Actuator not found");
    // }

    // const player = Player.fromSerialized(
    //   serializedPlayer,
    //   this.plane,
    //   actuator
    // );

    // this.players.set(player.id, player);

    // this.addBody(actuator.body1);
    // this.addBody(actuator.body2);

    // this.loadBody({
    //   body: actuator.body1,
    //   state: {
    //     position: [0, 0, 0],
    //     velocity: [0, 0, 0],
    //   },
    // });
    // this.neverUnloadBodyIds.add(actuator.body1.id);

    // this.loadBody({
    //   body: actuator.body2,
    //   state: {
    //     position: [0, 0, 0],
    //     velocity: [0, 0, 0],
    //   },
    // });
    // this.neverUnloadBodyIds.add(actuator.body2.id);

    // this.loadActuator(actuator.id);
  }

  addNewPlayer(playerId: string): Player {
    const player = new Player(playerId, this.plane);

    this.players.set(player.id, player);

    this.addBody(player.abdomen);
    this.addBody(player.limb);
    this.loadBody({
      body: player.abdomen,
      state: {
        position: [0, 0, 0],
        velocity: [10, 10, 0.001],
      },
    });
    this.loadBody({
      body: player.limb,
      state: {
        position: [0, 0, 0],
        velocity: [10, 10, 0.001],
      },
    });

    this.addAndLoadActuator(player.actuator);
    this.plane.centroidTethers.push(player.tether);
    this.plane.thrusters.push(player.thruster);

    return player;
  }

  generateVariety(params: VarietyGenerationParams): void {
    generateVariety(this, params);
  }

  abstract afterAddBody(body: Body): void;
}
